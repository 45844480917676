import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom"

import { useDispatch, useSelector } from 'react-redux';
import ModalMobileNumberAlreadyRegistered from '../../Modals/ModalMobileNumberAlreadyRegistered';
import ModalMobileNumberVerified from '../../Modals/ModalMobileNumberVerified';
import { AlreadyRegisteredModalOpen, ModalClose, OtpSentModalClose } from '../../../redux/actions/Modal';
import { OtpSentModalOpen } from '../../../redux/actions/Modal';
import { AlreadyExistDataAction, changeEmailInputAction, changeStep1InputAction, currentStageAction } from '../../../redux/actions/kyc/userlogin.action';
import ResendButton from '../../Utils/ResendButton';
import { commonPostApi, commonPostApiWithoutLoader } from '../../../ApiServices/common';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';
import { setState } from '../../../redux/actions/kyc/step2.action';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { resetStateAction } from '../../../redux/actions/reset.action';
import { setIsKraApprovedAction } from '../../../redux/actions/kyc/step3.action';
import { changeStep1PanInputAction, getPanCardDetailAction } from '../../../redux/actions/kyc/step1.action';
// import DelayedFunction from '../delayedFunction';


function MobileVerification() {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [isMobileVerified, setIsMobileVerified] = useState(0);
  const [isEmailVerified, setIsEmailVerified] = useState(0);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [otpFocus, setOtpFocus] = useState(true);
  const userLogin = useSelector(state => state?.userLogin || {});
  // const mobileInput = userLogin.mobileInput || '';
  const currentStage = userLogin?.currentStage;
  const utmGetData = window.location.href;
  const url = new URL(window.location.href);
  const utmType = url?.searchParams.get('utm_type');
  const mobileno = url?.searchParams.get('mobile');

  const urlParams = new URLSearchParams(window.location.search);
  const isMobile = urlParams.has('mobile');
  console.log(userLogin.mobileInput, mobileno, 'check');

  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
    // navigate(-1); 
    // This will go back to the previous page
  };

  const EmptyOtp = () => {
    setOtpValue('');
    setErrorMsg('');
    setOtpFocus(true);
  }
  const ResumeJourney = (isMobileVerified, isEmailVerified, currentStage) => {
    if (isMobileVerified == 0 && isEmailVerified == 0) {
      navigate(-1);
    }
    else if (isMobileVerified == 0 && isEmailVerified == 1) {
      navigate(-1);
    }
    else if (isMobileVerified == 1 && isEmailVerified == 0) {
      navigate('/email-input');
    }
    else if (isMobileVerified == 1 && isEmailVerified == 1) {
      switch (String(currentStage)) {
        case '1':
          navigate(-1);
          break;
        case '2':
          navigate('/email-input');
          break;
        case '3':
          navigate('/email-input');
          break;
        case '4':
          navigate('/pan-verification');
          break;
        case '5':
          navigate('/pan-verification');
          break;
        case '6':
          navigate('/personal-details-1');
          break;
        case '7':
          navigate('/occupation-details');
          break;
        case '8':
          navigate('/find-bank-details');
          break;
        case '9':
          navigate('/segment-details');
          break;
        case '10':
          navigate('/bank-proof');
          break;
        case '11':
          navigate('/income-proof');
          break;
        case '12':
          navigate('/selfie');
          break;
        case '13':
          navigate('/draw-sign');
          break;
        case '14':
          navigate('/e-sign');
          break;
        case '15':
          navigate('/welcome-page');
          break;
        case '16':
          navigate('/thanks-page');
          break;
        default:
          navigate('/');
      }
    }
  }

  useEffect(() => {
    if (otpValue?.length < 6) {
      setErrorMsg('');
    }
  }, [otpValue])

  useEffect(() => {
    if (apiCallSuccess) {
      let timer = setTimeout(() => {
        dispatch(OtpSentModalClose(false));
        ResumeJourney(isMobileVerified, isEmailVerified, currentStage);
      }, 2000);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [isEmailVerified, isMobileVerified, currentStage, apiCallSuccess])

  useEffect(() => {
    if (isMobile) {
      sessionStorage.setItem('utmGetData',utmGetData);
      console.log(utmType);
      sessionStorage.setItem('utm_type', utmType);
      dispatch(resetStateAction());
      setErrorMsg('');
      const regex = /^(?=[6-9])\d{10}$/;
      const ValidNo = regex.test(mobileno);
      if (ValidNo === true) {
        dispatch(changeStep1InputAction(mobileno));
        const data = {
          mobile: mobileno,
          mx_Source_URL: utmGetData,
          utm_get_data: utmGetData?.split("?")[1] ? utmGetData?.split("?")[1] : '',
          client_type: utmType == 'partner' ? 'partner' : 'client'
        }
        commonPostApiWithoutLoader('/login-signup-web', data, null)
          .then((data) => {
            if (data.status === 200) {
              sessionStorage.removeItem('utmGetData');
              sessionStorage.removeItem('utm_type');
              navigate('/mobile-verification', { replace: true });
            } else {
              if (data.hasOwnProperty('type')) {
                if (data.type == "channel_check") {
                  alert(data.msg);
                  navigate('/');
                }
              }
              if(data.status === 500 && data.type === "newekyc"){
                window.location.href = data.redirectUrl;
                return;
              }
              setErrorMsg(data?.msg ? data?.msg : "Some Error has Occured , Try again");
              navigate('/');
            }
          })
          .catch((error) => {
            setErrorMsg(error);
            navigate('/');
          })
      } else {
        setTimeout(() => {
          setErrorMsg('please Enter a Valid mobile number');
        }, 2000)
        navigate('/');
      }
    }
  }, [mobileno])


  useEffect(() => {
    if (userLogin?.mobileInput == ''
      && mobileno == null) {
      navigate('/')
    }
  }, [])


  const handleContinueBtn = () => {
    setOtpFocus(false);
    setErrorMsg('');
    const requestData = {
      // "mobile" : mobileno,
      "mobile": userLogin?.mobileInput || '',
      motp: otpValue || ''
    };
    commonPostApi('/verify-otp-web', requestData, null)
      .then((response) => {
        if (response.status === 200) {
          if (response?.msg == 'Client already registered.') {
            dispatch(AlreadyExistDataAction(response?.user_data));
            dispatch(AlreadyRegisteredModalOpen(true))
            setTimeout(() => {
              dispatch(ModalClose(false));
              navigate('/Client-exist');
            }, 3000);
            return;
          }
          else if (response?.msg == 'Partner already registered.') {
            navigate('/thanks-page');
            return;
          }
          setIsEmailVerified(response?.user_data?.verify_email_status);
          setIsMobileVerified(response?.user_data?.verify_mobile_status);
          // setState(response?.user_data?.stage)
          dispatch(currentStageAction(response?.user_data?.stage));
          if(response?.user_data?.email !== null && response?.user_data?.email !== '' && response?.user_data?.email !== undefined)
          {
            console.log(response?.user_data?.email);
          dispatch(changeEmailInputAction(response?.user_data?.email));
          } 
          if (response?.user_data?.pan !== undefined && response?.user_data?.pan !== '' && response?.user_data?.pan !== null) {
            dispatch(changeStep1PanInputAction(response?.user_data?.pan));
          }
          if (response?.user_data?.kra_status !== null && response?.user_data?.kra_status === '1') {
            dispatch(setIsKraApprovedAction(true));
          }
          // dispatch(setIsKraApprovedAction(!Boolean(response?.user_data?.json_data)));
          setApiCallSuccess(true);
          dispatch(OtpSentModalOpen(true));
          //full name
          if (response?.user_data?.full_name !== null) {
            const fullNameDetails = {
              fullName: response?.user_data?.full_name,
              seeding: ''
            };
            dispatch(getPanCardDetailAction(fullNameDetails));
          }

        }
        else {
          setErrorMsg(response?.msg ? response?.msg : 'Error has Occured while fetching your otp');
          return;
        }
      })
      .catch((error) => {
        store.dispatch(stopLoaderAction());
        setErrorMsg(error);
      })
  }
  return (<>
    <section className="main-wraper">
      <header className="site-header">
        <div className="site-header-wrap">
          <a>
            <img src="images/bigul-logo-fav.svg" alt="image" />
          </a>
          {/* <p> Mobile Verification</p> */}
        </div>
      </header>
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/msg-otp.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap mw-468">
            <div className="">
              <h1 className="title-1 mb-42"> Mobile Verification </h1>
            </div>
            <div className="">
              <p className="para-1 font-18 lh-27">
                Enter the 6 digit OTP sent to <br />
                {/* <strong> +91 {mobileno} </strong> */}
                <strong> +91 {userLogin?.mobileInput} </strong>
              </p>
              <p className="para-1 pb-3 mb-3">
                <button className="color-blue font-14 btn btn-link" onClick={goBack}>
                  Edit Mobile Number
                </button>
              </p>
            </div>
            <div className="input-box enter-otp-box d-flex justify-content-center">

              <OtpInput
                // value={otpValue.replace(/\D/g, '')}
                value={otpValue}
                inputType="tel"
                onChange={setOtpValue}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus={otpFocus}

              />
            </div>
            {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}

            <ResendButton
              url={"/resend-otp-web"}
              count={60}
              EmptyOtp={EmptyOtp}
              payload={{ mobile: userLogin?.mobileInput, type: "mobile" }}
            />
          </div>
          <div className="btn-action-box">
            <button className={`btn btn-${!(otpValue.length === 6) ? 'secondary' : 'primary'} btn-full mb-2`}
              onClick={handleContinueBtn}
              disabled={!(otpValue.length === 6)}>
              Verify Mobile Number
            </button>
          </div>
        </div>
      </div>
    </section>
    <ModalMobileNumberAlreadyRegistered />
    <ModalMobileNumberVerified />
  </>)
}
export default MobileVerification;