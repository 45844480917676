import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom"
import { commonPostApi, dkycApi, esignApi, getKycJourney } from '../../../ApiServices/common';
import { useSelector } from 'react-redux';
import StepsHeader from '../../Utils/StepsHeader';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import Dropdown from '../../Utils/Dropdown';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';


function UploadBankProof() {
  // store.dispatch(stopLoaderAction());
  const userDetails = useSelector(state => state?.userLogin || {});
  const mobile = userDetails.mobileInput;
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [enableButton, setEnableButton] = useState(false);
  const [disableUploadProof, setDisableUploadProof] = useState(true);
  const [nextButtonDesc, setNextButtonDesc] = useState('Continue to Next Step');
  const [navigateDesc, setNavigateDesc] = useState('selfie');
  const [fileId, setFileId] = useState(null);
  const [IsKraApproved, setIsKraApproved] = useState(true);
  const [PanNo, setPanNo] = useState('');
  const [jsonData, setJsonData] = useState('');
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  //new code for opening and closing the dd 
  const [isOpen, setIsOpen ] = useState(false);
   const toggleDropdown=()=>{
    setIsOpen(!isOpen)
   }

  //allowed formats 
  const allowedFormats = ['jpeg', 'jpg', 'png'];

  //resume journey
  const initalStageforBankDetails = '10';
  // useResumeJourneyPerStage(initalStageforBankDetails);
  useCheckRequiredJourneyData(initalStageforBankDetails);

  //navigation change 
  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
       navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
       navigate('/');
    }
  }
  const bankProofArr = [
    { 'id': "Bank Statement", "name": "Bank Statement" },
    { "id": "1st page of Passbook", "name": "1st page of Passbook" },
    { "id": 'Cancelled Cheque Leaf', "name": "Cancelled Cheque Leaf" }]

  const handleDropdownChange = (value) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setSelectedValue(value);
    setSelectedFile(null);
    setDisableUploadProof(false);
  };
  const handleFileChange = (event) => {
    //validations pending
    if (selectedValue) {
      const selectedFile = event.target.files[0];
      setErrorMsg(" ");
      if (selectedFile?.size > 2 * 1024 * 1024) {
        setErrorMsg("Your file is over 2MB, please select a smaller file (under 2MB) to upload.");
        setSelectedFile(null);
        setEnableButton(false);
        event.target.value = "";
      } else {
        const fileExtension = selectedFile?.name.split('.').pop().toLowerCase();
        if(selectedFile != null){
        if (allowedFormats?.includes(fileExtension)) {
          setErrorMsg('');
          setSelectedFile(selectedFile);
          setEnableButton(true);
        } else {
          event.target.value = "";
          setErrorMsg('Invalid file format. Allowed formats: jpeg, jpg, png');
          setSelectedFile(null);
          setEnableButton(false);
        }}else{
          event.target.value = "";
          setSelectedFile(null);
          setEnableButton(false);
        }
      }
    }
    else {
      setSelectedFile(null);
      setErrorMsg("Please select the type of document first");
      setEnableButton(false);
    }
  };
  const onChangeHandlerNext = async () => {
    setErrorMsg('');
    const data = {
      mobile: mobile,
      bank_proof: selectedFile,
      document_type: selectedValue,
      stage: '11'
    }
    try {
      const response = await commonPostApi('/bank-proof-web', data, { headers: { 'Content-Type': 'multipart/form-data' } });
      if (response.status == 200) {
        navigationStage(response?.user_data?.stage);
      }
      else {
        setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
      }
    }
    catch (err) {
      setErrorMsg(err);
    }
  }

  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader step={4} />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/find-bank.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1">Upload Bank Proof </h1>
            </div>
            <div className="mw-400 block-center">
              <p className="para-1">
                We weren’t able to verify your bank account before. Please select a
                type of proof and upload a PDF file to continue.
              </p>
            </div>
            <div className="input-box mt-4">
              <div className="custom-select mb-2">
                <Dropdown optionsArray={bankProofArr}
                  dropdownName="Select the Bank Proof"
                  onSelect={handleDropdownChange}
                  isOpen={isOpen}
                  toggleDropdown={toggleDropdown}
                />
                {/* <select className="input-form text-center mb-3" value={selectedValue} onChange={handleDropdownChange}>
                  <option value="" disabled>
                    Please Select the  BankProof
                  </option>
                  <option className="color-black">Canceled Cheque Leaf</option>
                  <option className="color-black">Last 6 Months Salary Slip</option>
                </select> */}
              </div>
              <input
                type="text"
                className="input-form text-center mb-3"
                placeholder="Enter Document Password, if any "
              />
              <div className="position-relative">
                <button className={`btn btn-primary btn-full ${disableUploadProof ? 'disable' : ''}`}>
                  <img
                    src="images/file-upload.svg"
                    alt="icon"
                    className={`mr-2`}
                  />
                  Upload Image
                </button>
                <input type="file"
                  className="upload-input w-100"
                  onChange={handleFileChange}
                  accept=".jpeg, .jpg, .png"
                  disabled={disableUploadProof}
                  ref={fileInputRef} />
              </div>
            </div>
          </div>

          <div className="btn-action-box">
            <button className={`btn btn-${selectedFile ? 'primary' : 'secondary'} btn-full mb-2`} onClick={onChangeHandlerNext} disabled={!selectedFile}>
              {nextButtonDesc}
            </button>
            {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          </div>
        </div>
      </div>
    </section>

  </>)

   
}
export default UploadBankProof;