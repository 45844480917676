import React, { useState , useRef, useEffect } from 'react';
import { commonPostApi, dkycApiHavingLoader } from '../../../ApiServices/common';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';






function DigioPennyDrop() {

    const kycStep1 = useSelector(state => state?.ekycStep1 || {});
    const kycStep3 = useSelector(state => state?.ekycStep3 || {});
    const [pan, setPan] = useState(kycStep1.panInput.value);
    const [errorMsg, setErrorMsg] = useState('');
    const kycStep5 = useSelector(state => state?.ekycStep5);
    const userLogin = useSelector(state => state?.userLogin || {});
    const userName = kycStep1?.userData?.fullName;
    const mobile = userLogin?.mobileInput;

    //for penny drop status update
    const [penny_Verified, setPennyVerified] = useState('false');
    let pennyVerified = false;

    const [pennyId, setPennyId] = useState('--');
    const [verificationTime, setPennyVerificationTime] = useState('--');
    const [pennyHolderName, setPennyHolderName] = useState('--');
    const [panHolderFullName, setPanHolderFullName] = useState('');

    //for geo location 
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const divRef = useRef(null);

    console.log('gdhhdjgd'); 
    useEffect(() => {
      console.log('imgData'); 
        const fetchData = () => {
            const mobile = userLogin?.mobileInput;
            const payload = {
                mobile: mobile
            };

            dkycApiHavingLoader('/getKycJourney', payload, 'multipart/form-data').then((response) => {
                setLatitude( response?.data[0]?.latitude);
                setLongitude( response?.data[0]?.longitude)
                const pennydropData = response?.data[0]?.pennydrop_data;
                //if penny drop is not null 
                const jsonObject = JSON.parse(pennydropData);
                console.log(jsonObject, 'pddatadd');
                if (jsonObject)
                  setPennyHolderName(jsonObject?.beneficiary_name_with_bank);
                setPennyVerificationTime(jsonObject?.verified_at);
                setPennyId(jsonObject?.id);
                setPennyVerified(jsonObject?.verified ? 'True' : "False");

            }).catch((err) => { console.log("Error while fetching your data. Please try again later."); })
        }
        fetchData();
    }, []);

    const uploadImage = (bankData) => {
        if (divRef.current) {
          html2canvas(divRef.current).then((canvas) => {
            // Convert the captured canvas to a data URL
            const imgData = canvas.toDataURL('image/png');
            console.log(imgData); 
            // Now you can upload imgData to your server or do something else with it
            canvas.toBlob((blob) => {
              console.log(blob, 'bb');
              if (blob) {
                // Create a FormData object to prepare for uploading
                const data = new FormData();
                // Append other fields to the FormData
                data.append('Document', 'Other');
                data.append('DocumentType', 'NA');
                data.append('CreatedBy', '2261');
                data.append('PanNo', pan);
                data.append('step', '16');
                data.append('DocumentImages[]', blob, 'image.jpg');
                // You can change the filename here
                // Example: Upload the image using the fetch API
                dkycApiHavingLoader('/uploadfiles', data, 'multipart/form-data'  )
                  .then((response) => {
                    if (response?.success === true) {
                    alert('done');
                    }
                    else {
                      setErrorMsg(response?.msg ? response?.msg : 'something went wrong!');
                      return;
                    }
                  }).catch((err) => {
                    console.log('penny details are not uploaded for the customer');
                    setErrorMsg(err);
                  })
              }
            }, 'image/png' ,0.7); // Specify the desired image format ('image/jpeg' for JPG, 'image/png' for PNG)
          });
        
      }
      };
    return (<>
        <div ref={divRef} style={{
        //   opacity: '1',
        //   position: 'absolute', 
        //   left: '-9999px',
          width: '300px',
          fontSize: '10px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }} className=''>
          <table >
            <tbody>
              <tr>
                <td width="49%">
                  <div style={{}} id="userImageData" >
                    <span>
                      {/* <img style={{ width: "100%" }} src={fileName} /> */}
                      {/* <img style={{ width: '100%' }} src={imageData} /> */}
                      {/* <img style={{ width: "100%" }} src={imageDigioFile} /> */}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="100%" style={{ padding: 15 }}>
                  <div
                    style={{
                      display: "flex",
                      margin: "5px 0px",
                      justifyContent: "space-between",
                      borderBottom: "2px solid blue",
                      paddingBottom: 5
                    }}
                  >
                    <span>
                      <h6>Geo Location Info</h6>
                      {/* <p>
                        ID Type - {idType}
                      </p> */}
                      {/* <p>
                        Face Match -
                        <span style={{ color: "green" }}>
                          {matched}{faceMatch}
                        </span>
                      </p> */}
                    </span>
                    <span />
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >

                    <span>Address -</span>
                    <span style={{marginLeft: 10, wordWrap: 'break-word', wordBreak: 'break-all' }} >
                      {address}
                    </span>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Latitude -</span>
                    <span>
                      <b>
                        {latitude}
                      </b>
                    </span>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Accuracy -</span>
                    <span>
                      {accuracy}
                    </span>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Longitude -</span>
                    <span>
                      <b>
                        {longitude}
                      </b>
                    </span>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Status -</span>
                    <span style={{ color: "green" }}>
                      {GeoStatus}
                    </span>
                  </div> */}
                  {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Date Time -</span>
                    <span style={{ color: "green" }}>
                      {dateTime}
                    </span>
                  </div> */}
                </td>
              </tr>
              <tr>
                <td width="100%" style={{ padding: 15 }}>
                  <div
                    style={{
                      display: "flex",
                      margin: "5px 0px",
                      justifyContent: "space-between",
                      borderBottom: "2px solid blue",
                      paddingBottom: 5
                    }}
                  >
                    <span>
                      <h6>Penny Drop Info</h6>
                    </span>
                    <span />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Id -</span>
                    <span>
                      {pennyId}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Verified Status -</span>
                    <span> 
                      <p>{penny_Verified}</p>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Date &amp; Time -</span>
                    <span>
                      {verificationTime}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Beneficiary Name With Bank -</span>
                    <span>
                      <b style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                        {pennyHolderName}
                      </b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Pan-Card Full Name -</span>
                    <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                      {userName ? userName : panHolderFullName}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
        className="btn btn-primary btn-full mb-2"
        data-bs-toggle="modal"
        data-bs-target="#Modal-4" onClick={uploadImage}
      >
       upload
      </button>
        </>
    )
}
export default DigioPennyDrop;