import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import StepsHeader from '../../Utils/StepsHeader';
import SignatureCanvas from 'react-signature-canvas'
import { commonPostApi } from '../../../ApiServices/common';
import { useSelector } from 'react-redux';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import imageCompression from 'browser-image-compression';
import Webcam from 'react-webcam';
import axios from 'axios';
import { saveAs } from 'file-saver'
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';

function DrawYourSignature() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [signatureInputMode, setSignatureInputMode] = useState("DRAW");
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);
  const [isSignatureUploaded, setIsSignatureUploaded] = useState(false);
  const [disableButton, setDisablebutton] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [CapturedImage, setCapturedImage] = useState('');
  const [key, setKey] = useState(1);
  const userData = useSelector(state => state?.userLogin || {});
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);



  useEffect(() => {

  }, [isSignatureDrawn, selectedFile, uploadedImage])


  let image = null;
  let signCanva = null;
  // let signatureRef = React.createRef();


  let getWebcamTimer;

  //resume journey
  const initalStageforSignature = '13';
  useCheckRequiredJourneyData(initalStageforSignature);

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }

  //choose signature type
  const getSignatureImage = () => {
    try {
      switch (signatureInputMode) {
        case "DRAW":
          setSelectedFile(null);
          if (signCanva.isEmpty())
            return false;
          const dataString = signCanva.toDataURL("image/jpg");
          return dataURLtoFile(dataString, "signatureImage");
        case "CAPTURE":
          captureImage();
        case "UPLOAD":
          if (!selectedFile) return false;
          return selectedFile;
        default:
          return false;
      }
    } catch (error) {
      return false;
    }
  }
  const handleSignatureInputType = (e) => {
    // setDisablebutton(false);
    setErrorMsg('');
    switch (e.target.value) {
      case "DRAW":
        setSignatureInputMode("DRAW");
        setUploadedImage(null);
        setSelectedFile(null);
        break;
      case "CAPTURE":
        setSignatureInputMode("CAPTURE");
        setIsSignatureDrawn(false);
        setUploadedImage(null);
        setSelectedFile(null);
        break;
      case "UPLOAD":
        setSignatureInputMode("UPLOAD");
        setIsSignatureDrawn(false);
        break;
      default:
        setSignatureInputMode("DRAW")
    }
  }

  //capture image only for mobile 
  const captureImage = () => {
    setSelectedFile(null);
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    // Handle the captured image, e.g., save it to state or send it to a server.
    return imageSrc;
  };
  const videoConstraints = {
    facingMode: "environment", // This should switch to the back camera
  };
  const refreshCamera = (() => {
    setKey(prevKey => prevKey + 1);
  }, []);

  const cameraErrorHandler = (err) => {
    alert("Camera Device not found or Improper permission");
    if (!getWebcamTimer) {
      getWebcamTimer = setTimeout(() => {
        refreshCamera();
      }, 3000);
    }
  }
  const onUserMediaHandler = () => {
    clearTimeout(getWebcamTimer)
  }

  //draw signature
  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const clearDrawingBox = (e) => {
    e.preventDefault();
    signCanva.clear();
    // signatureRef.current.clear();
    setIsSignatureDrawn(false);
  }
  const handleDrawComplete = () => {
    // Check if drawn signature data exists
    // const canvasData = signatureRef.current.toDataURL();
    setIsSignatureDrawn(true);
  };
  const handleDrawStart = () => {
    // When drawing starts, set the flag to true
    setIsSignatureDrawn(true);
  };

  //upload image
  const handleDeleteUploadedImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setUploadedImage(null);
    setSelectedFile(null);
  };
  const convertToUrl = (base64String) => {
    const url = `data:image/jpg;base64,${base64String}`;
    setUploadedImage(url);
  };
  const options = {
    maxSizeMB: 0.013,
    maxWidthOrHeight: 250
  };
  const MAX_SIZE = 5 * 1024 * 1024; // 5MB in bytes
  const signatureUploadHandler = async (e) => {
    setErrorMsg('');
    const allowedFormats = ['image/jpeg', 'image/jpg'];
    image = e.target.files[0];
    const formDataa = new FormData();
    formDataa.append("image", image);
    formDataa.append('source','EKYC');
    if (image) {
      if (!allowedFormats.includes(image.type)) {
        setErrorMsg('Only JPG/JPEG formats are allowed');
        setUploadedImage(null);
        setSelectedFile(null);
        return;
      }
      if (image.size > MAX_SIZE) {
        setErrorMsg('File size should not exceed 5MB');
        setUploadedImage(null);
        setSelectedFile(null);
        return;
      }
      commonPostApi('/processImage', formDataa, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          if (response.status === true) {
            const base64String = response.data[0];
            // convertToUrl(base64String);
            // Decode base64 to binary data
            const binaryData = atob(base64String);
            // Create a Blob from the binary data
            const blob = new Blob([new Uint8Array(Array.from(binaryData).map(char => char.charCodeAt(0)))], { type: 'image/jpg' });
            // Create a File from the Blob
            const file = new File([blob], 'image.jpg', { type: 'image/jpg' });
            if (file.size > 15 * 1024) {
              imageCompression(file, options)
                .then((res) => {
                  const imageUrl = URL.createObjectURL(res);
                  setSelectedFile(res);
                  setUploadedImage(imageUrl);

                })
                .catch((err) => {
                  setErrorMsg(err);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                  setUploadedImage(null);
                  setSelectedFile(null);
                })
            }
            else {
              setSelectedFile(blob);
              const imageUrl = URL.createObjectURL(blob);
              setUploadedImage(imageUrl);
            }
          }
          else {
            setErrorMsg(response?.msg);
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            setUploadedImage(null);
            setSelectedFile(null);

          }
        })
        .catch((err) => {
          setErrorMsg(err);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          setUploadedImage(null);
          setSelectedFile(null);

        })
    }
  }

  //submit signature
  const submitSignatureFileHandler = async () => {
    setErrorMsg('');
    image = getSignatureImage()
    if (image) {
      const formData = new FormData();
      formData.append("mobile", userData.mobileInput);
      formData.append("client_signature", image);
      formData.append("stage", '14');

      try {
        const response = await commonPostApi('/client-signature-web', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response?.status === 200) {
          navigationStage(response?.user_data?.stage);
        } else {
          setErrorMsg(response?.msg ? response?.msg : 'something went wrong!!');
        }
      }
      catch (err) {
        setErrorMsg(err);
      }
    }
    else {
      setErrorMsg("Please Select a File First");
      return;
    }
  }

  return (<>
    <section className="main-wraper draw-signature-module">
      <StepsHeader step={7} />
      <AsideWallpaper
        color='green'
        descriptionHeader=""
        backGroundImageUrl="images/draw-signature.png"
        backGroundImageUrlAltText="SOME IMAGE"
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <div className="signature-box block-center mb-2">
                {signatureInputMode === "DRAW" &&
                  <SignatureCanvas penColor='black'
                    backgroundColor="rgba(100,100,100,0)"
                    canvasProps={{ width: 400, height: 140, className: 'sigCanvas' }}
                    onEnd={handleDrawComplete}
                    onBegin={handleDrawStart}
                    ref={(ref) => { signCanva = ref }}
                  />
                  // :
                  // <img
                  //   src="./images/gray-rectangle.svg"
                  //   alt=""
                  //   className="mw-100"
                  // />
                }
                {signatureInputMode === "CAPTURE" &&
                  <Webcam
                    audio={false}
                    videoConstraints={videoConstraints}
                    mirrored={true}
                    ref={webcamRef}
                    onUserMediaError={cameraErrorHandler}
                    onUserMedia={onUserMediaHandler}
                    screenshotFormat="image/jpeg"
                    width={300}
                    //height={auto}
                    key={key}
                  />}
                {/* <button onClick={CapturedImage}></button> */}
                {signatureInputMode === "UPLOAD" && <img src={uploadedImage ? uploadedImage : "./images/gray-rectangle.svg"} alt="" className="mw-100 sign-view-img" />}
              </div>
              <label className="radio-container  selfie-guide-text">
                <input type="radio" name="radio" value="DRAW" onChange={handleSignatureInputType} checked={signatureInputMode === "DRAW"} />
                <span className="radiomark" />
                <span className="radiomark-text title-1 mb-1">
                  <span className="font-20 font-700">Draw your Signature</span>
                </span>
              </label>
            </div>
            <div className="block-center mb-1">
              {signatureInputMode === "DRAW" &&
                <div className="pl-0 mw-250 block-center">
                  <p className="para-13 mb-1 selfie-guide-text">
                    Use your mouse to sign within the box
                  </p>
                  <p className="para-13 mb-1 selfie-guide-text">
                    Avoid signing with your initials
                  </p>
                  <p className="para-1 mb-0 pb-2 pb-h740-2 font-400">
                    <a onClick={clearDrawingBox} className="btn btn-link">
                      Erase &amp; Try Again
                    </a>
                  </p>
                </div>}
              <div className="selfie-or-box mb-h740-2 py-sm-2" >
                {/* <button className={`btn btn-${!isSignatureDrawn && !selectedFile ? 'secondary' : 'primary'} btn-full mb-2`} onClick={submitSignatureFileHandler} disabled={!isSignatureDrawn && !selectedFile}> */}
              </div>
              <label className="radio-container pl-md-60 d-sm-none d-none">
                <input type="radio" name="radio" value="CAPTURE" onChange={handleSignatureInputType} checked={signatureInputMode === "CAPTURE"} />
                <span className="radiomark" />
                <span className="radiomark-text d-flex justify-content-between pt-1">
                  Capture Sign{" "}
                  <span className="radio-actions">
                    <img src="./images/red-delete.svg" alt="delete icon" />
                  </span>
                </span>
              </label>
              <label className="radio-container pl-md-60">
                <input type="radio" name="radio" value="UPLOAD" onChange={handleSignatureInputType} checked={signatureInputMode === "UPLOAD"} />
                <span className="radiomark" />
                <span className="radiomark-text d-flex justify-content-between pt-1">
                  Upload a photo of the signature
                  <span className="radio-actions">
                    <img src="./images/red-delete.svg" alt="delete icon" onClick={handleDeleteUploadedImage} />
                  </span>
                </span>
              </label>
              {
                signatureInputMode === "UPLOAD" &&
                /** New code 25 july **/
                <div className="upload-sign-box pl-md-60">
                  <a className="btn btn-sm btn-primary">
                    Upload Photo</a>
                  <input type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={signatureUploadHandler} />
                </div>
                /** End New code 25 july **/
              }
            </div>
          </div>
          <div className="btn-action-box">
            <button className={`btn btn-${!isSignatureDrawn && !selectedFile ? 'secondary' : 'primary'} btn-full mb-2`} onClick={submitSignatureFileHandler} disabled={!isSignatureDrawn && !selectedFile}>
              Continue to Last Step
            </button>
            {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default DrawYourSignature;