// Dropdown.js
import React, { useEffect, useState } from 'react';

const Dropdown = ({ optionsArray, dropdownName, onSelect, dropdownId, error_message, value, disabled ,isOpen,toggleDropdown}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedName, setSelectedName] = useState(dropdownName);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const handleSelect = (value, name) => {
    setSelectedName(name);
    setSelectedValue(value);
    onSelect(value, dropdownId);
    toggleDropdown(dropdownId);

    // setIsOpen(false);
     // Close the dropdown after selecting a value
  };


  // useEffect(()=>{
  //   onSelect(selectedValue ,dropdownId);
  // },[selectedValue,selectedName])

  return (
    <div>
      <div className="dropdown custom-select">
        <button className="input-form text-center mb-3 select-styled active" 
        onClick={() => toggleDropdown(dropdownId)} disabled={disabled} 
        >
          {selectedName ? selectedName : dropdownName}
        </button>
        {isOpen && (
          <ul className="select-options">
            {optionsArray.map((option) => (
              <li className="color-black"
               key={option.id}
                onClick={() => handleSelect(option.id, option.name)}>
                {option.name}
              </li>
            ))}
          </ul>
        )}
        <div className='input-error-msg text-center'>
          {error_message}
        </div>

      </div>

    </div>


  );
};

export default Dropdown;
