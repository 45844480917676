import { useSelector } from 'react-redux';
import loader from '../../assets/gif/Circle Loader/Circle Loader.gif';
export const SmallLoader = ({isLoading}) => {
    return (
        isLoading
            ? (
                <img src={loader} alt="" className='' style={{width: "20x"}} />
            )
            : null
    );
};