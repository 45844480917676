import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import StepsHeader from '../../Utils/StepsHeader';
import Webcam from "react-webcam";
import { useDispatch, useSelector } from 'react-redux';
import { SelfieTimeStampAction, setSelfieImageUrlAction } from '../../../redux/actions/kyc/step6.action';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import { store } from '../../../redux/store';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { commonPostApi, dkycApi, getKycJourney } from '../../../ApiServices/common';
import axios from 'axios';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';
import Modal from 'antd/es/modal/Modal';
// import goagle from '../../../../public/images/goagle.svg';
// import one from '../../../../public/images/one.svg'
// import perfect from '../../../../public/images/perfect.svg'
// import inner from '../../../../public/images/inner.svg'

function TakeASelfie() {
  const dispatch = useDispatch();
  store.dispatch(stopLoaderAction());
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const [key, setKey] = useState(1);
  const [errorMsg, setErrorMsg] = useState('');
  const userDetails = useSelector(state => state?.userLogin || {});
  const mobile = userDetails?.mobileInput;
  const [fileId, setFileId] = useState(null);
  const [IsKraApproved, setIsKraApproved] = useState(true);
  // const [initalStageforSelfie , setInitialStageforSelfie] = useState('9'); 

  let getWebcamTimer;

  //resume journey
  let initalStageforSelfie = '12';
  // useResumeJourneyPerStage(initalStageforSelfie);
  useCheckRequiredJourneyData(initalStageforSelfie);

  //navigate

  //capture selfie
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log(imageSrc, 'imageSrc');
    const currentTime = new Date().toLocaleString(); // Get current time
    console.log("Selfie captured at:", currentTime);
    dispatch(SelfieTimeStampAction(currentTime));
    dispatch(setSelfieImageUrlAction(imageSrc))
    navigate('/your-picture');
  }, []);
  const refreshCamera = useCallback(() => {
    setKey(prevKey => prevKey + 1);
  }, []);

  const cameraErrorHandler = (err) => {
    alert("Camera device not found or Improper permission");
    if (!getWebcamTimer) {
      getWebcamTimer = setTimeout(() => {
        refreshCamera();
      }, 3000);
    }
  }

  const onUserMediaHandler = () => {
    clearTimeout(getWebcamTimer)
  }
  const [check1, setCheck1] = useState(false);
  const modalToggle = () => {
    setCheck1(!check1);
  }
  const pop = (img, title) => {
    return (
      <div style={{ width: '100%', maxWidth: '678px', margin: '0 auto', padding: '5px' }}>

        <ul style={{ listStyleType: 'none', padding: '0' }}>
          <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
            <img alt="application_icon" src={img} style={{ marginRight: '10px' }} />
            <span style={{ color: 'black', fontWeight: '500' }}>{title}</span>
          </li>
        </ul>
      </div>
    );
  };
  return (<>
    <Modal
      title=""
      className="w-full p-[33px]"
      width={600}
      open={check1}
      centered={true}
      onCancel={() => modalToggle()}
      footer={null}
    >
      <div className="mb-1.5 flex flex-col" >
        <h2 style={{
          fontWeight: 'bold', fontSize: '24px', marginBottom: '20px', display: "flex",
          justifyContent: "center"
        }} >Photo Verification</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '20px', flexWrap: 'wrap' }}>
          <img alt="application_icon" src='../../../../images/goagle.svg' />
          <img alt="application_icon" src='../../../../images/1.svg' />
          <img alt="application_icon" src='../../../../images/inner.svg' />
          <img alt="application_icon" src='../../../../images/perfect.svg' />
        </div>
        {pop('../../../../images/wrong.svg', 'Avoid selfie in glasses, hats, or masks.')}
        {pop('../../../../images/wrong.svg', 'Do not take selfies in innerwear.')}
        {pop('../../../../images/wrong.svg', 'Do not take selfies without clothes.')}
        {pop('../../../../images/righ.svg', 'Ensure your face is clearly visible with a plain background and proper lighting.')}
        <button onClick={modalToggle} style={{ marginTop: '15px', padding: '10px 20px', backgroundColor: 'black', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer',display: "flex",
    justifyContent: "center" }} >Yes, I Understand</button>
      </div>
    </Modal>
    <section className="main-wraper selfie-page">
      <StepsHeader
        step={6}
      />
      <AsideWallpaper
        color={'green'}
        descriptionHeader=""
        backGroundImageUrl="./images/selfie-img.svg"
        backGroundImageUrlAltText="some image"
      />

      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <div className="selfie-box block-center mb-3">
                <Webcam
                  audio={false}
                  mirrored={true}
                  ref={webcamRef}
                  onUserMediaError={cameraErrorHandler}
                  onUserMedia={onUserMediaHandler}
                  screenshotFormat="image/jpeg"
                  width={300}
                  minScreenshotWidth={300}
                  //height={auto}
                  key={key}

                />
              </div>
              <h1 className="title-1 mb-1">Take a selfie! </h1>
            </div>
            <div className="block-center mb-3 mx-md-2">
              <p className="para-13">
                Take a clear picture of yourself by placing your face within the
                circle as shown. Open the camera when ready!
              </p>
            </div>
          </div>
          <div className="btn-action-box">
            <button href="" className="btn btn-primary btn-full mb-2" onClick={capture}>
              <img
                src="./images/selfie-camera-icon-2.svg"
                alt="icon"
                className="mr-2"
              />
              Take a Picture
            </button>
            {errorMsg}
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default TakeASelfie;