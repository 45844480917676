import { changeEmailInput, changeStep1Input, current_stage, setMobileTimer, alreadyExistData } from "../../actions/kyc/userlogin.action";

const initialState = {
    mobileInput: '',
    emailInput: '',
    currentStage: '',
    mobileTimer: 60
};
export const userLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case changeStep1Input:
            return {
                ...state,
                mobileInput: action.payload,
            };
        case changeEmailInput:
            return {
                ...state,
                emailInput: action.payload,
            }
        case current_stage:
            return {
                ...state,
                currentStage: action.payload,
            }
        case setMobileTimer:
            return {
                ...state,
                mobileTimer: action.payload
            }
        case alreadyExistData:
            return {
                ...state ,
                clientUserData : action.payload
            }
        default:
            return state;

    }

}