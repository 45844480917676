import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import StepsHeader from '../../Utils/StepsHeader';
import { useSelector } from 'react-redux';
import { commonPostApi, dkycApi, esignApi, getKycJourney } from '../../../ApiServices/common';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import Dropdown from '../../Utils/Dropdown';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';

function UploadIncomeProof() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const userDetails = useSelector(state => state?.userLogin || {});
  const mobile = userDetails.mobileInput;
  const [errorMsg, setErrorMsg] = useState('');
  const [disableUploadProof, setDisableUploadProof] = useState(true);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const kycStep6 = useSelector(state => state?.ekycStep6 || {});
  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const [stage, setStage] = useState();
  const [pan, setPan] = useState(kycStep1.panInput.value);

  //resume journey

  let initalStageforIncome = '11';
  // useResumeJourneyPerStage(initalStageforIncome);
  useCheckRequiredJourneyData(initalStageforIncome);

  //new code for opening and closing the dd 
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }


  useEffect(() => {
    const mobile = userDetails?.mobileInput;
    const payload = {
      mobile: mobile
    };
    dkycApi('/getKycJourney', payload, 'multipart/form-data')
      .then((response) => {
        if (response?.success == true) {
          setStage(response?.data[0]?.digio_image_status);
          setPan(response?.data[0]?.pan);
        } else { setErrorMsg(response?.message ? response?.message : "Something went wrong!!") }
      }).catch((err) => {
       setErrorMsg(err);
      })

  }, [])

  //allowed formats 
  const allowedFormats = ['pdf', 'xls', 'jpg', 'png', 'doc'];

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
       navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
       navigate('/');
    }
  }
  const formData = new FormData();

  const onChangeHandlerNext = async () => {
    setErrorMsg('');

    const isFileEncrypted = await checkEncryption(selectedFiles);
    if (isFileEncrypted) {
      setErrorMsg('This file is encrypted. Please open it, save as a new PDF, and re-upload it here.');
      setSelectedFiles([]); // Clear selected files
      return;
    }

    if (selectedValue == 'Last 3 Months Salary Slip') {
      if (selectedFiles?.length < 3) {
        setErrorMsg('Please upload Last 3 Months Salary Slips');
        return;
      }
    }
    formData.append("mobile", mobile);
    formData.append('income_proof', selectedFiles);
    formData.append("stage", '12');
    try {
      const data = {
        'Document': 'Income Proof',
        'DocumentType': selectedValue,
        'DocumentImages[]': selectedFiles,
        'CreatedBy': '2261',
        'PanNo': pan,
        'step': stage == 1 ? '13' : '12'
      }
      const response = await dkycApi('/uploadfiles', data, 'multipart/form-data');
      if (response?.success === true) {
        navigationStage(response?.data?.stage);
      }
      else {
        setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
      }
    }
    catch (err) {
      setErrorMsg(err);
    }
  }
  const incomeProofArr = [
    {
      "id": "ITR",
      "name": "ITR"
    },
    {
      "id": "Holding",
      "name": "Holding"
    },
    {
      "id": "Last 3 Months Salary Slip",
      "name": 'Last 3 Months Salary Slip'
    },
    {
      "id": '6 Months Bank Statement',
      "name": '6 Months Bank Statement'
    },
    {
      "id": 'Audited Balance Sheet',
      "name": 'Audited Balance Sheet'
    },
    {
      "id": 'Networth Certificate',
      "name": 'Networth Certificate',
    },
    {
      "id": 'Demat Holding Statement',
      "name": 'Demat Holding Statement',
    },
    {
      "id": 'Other Investment Proof',
      "name": 'Other Investment Proof',
    }
  ];
  const checkEncryption = async (files) => {
    const mobile = userDetails.mobileInput;
    for (const file of files) {
      const encryptionFormData = new FormData();
      encryptionFormData.append('mobile', mobile);
      encryptionFormData.append('doc_file', file);
  
      try {
        const response = await commonPostApi('/check-encryption', encryptionFormData, 'multipart/form-data');
        if (response.status === 404) {
          return true; // File is encrypted
        }
      } catch (err) {
        console.error(err);
      }
    }
    return false; // No encrypted file found
  };




  // Function to handle file selection
  const handleFileSelect = (e) => {
    setErrorMsg('');
    const file = e.target.files[0];

    if (selectedValue == 'Last 3 Months Salary Slip') {
      if (selectedFiles?.length >= 3) {
        // Display an alert or handle the case where the maximum number of files is reached
        setErrorMsg("You can only select up to 3 files.");
        e.target.value = null; // Clear the file input
      }
      else if (file && file.size > 2 * 1024 * 1024) {
        // Display an alert or handle the oversized file as needed
        setErrorMsg("Your file is over 2MB, please select a smaller file (under 2MB) to upload.");
        e.target.value = null; // Clear the file input
      } else {
        if(file != null )
       { setSelectedFiles([...selectedFiles, file]);}
      }
    } else {
      if (selectedFiles.length >= 1) {
        // Display an alert or handle the case where the maximum number of files is reached
        setErrorMsg("You can only select up to 1 file.");
        e.target.value = null; // Clear the file input
      }
      else if (file && file.size > 2 * 1024 * 1024) {
        // Display an alert or handle the oversized file as needed
        setErrorMsg("Your file is over 2MB, please select a smaller file (under 2MB) to upload.");
        e.target.value = null; // Clear the file input
      } else {
        setSelectedFiles([...selectedFiles, file]);
      }
    }
  };

  // Function to handle file removal
  const handleFileRemove = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader step={5} />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/trading-and-investment.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1">Upload Income Proof</h1>
            </div>
            <div className="mw-400 block-center mb-3">
              <p className="para-1">Segment Details - 2/2</p>
            </div>
            <div>
            </div>
            <div className="input-box">
              <div className="">
                <Dropdown
                  optionsArray={incomeProofArr}
                  dropdownName="Select the Income Proof"
                  onSelect={(value) => {
                    setSelectedValue(value);
                    setDisableUploadProof(false);
                    setSelectedFiles([]);
                    setErrorMsg('');
                    // setSelectedFiles(null);
                    // setSelectedFile(null);
                  }}
                  isOpen={isOpen}
                  toggleDropdown={toggleDropdown}

                />
                {/* <select className="input-form text-center mb-3" value={selectedOption} onChange={handleChange}>
                  <option value="" disabled>
                    Select the IncomeProof
                  </option > */}
                {/* {occupationList.map((option, index) => (
                    <option className="color-black" key={index} value={option}>
                      {option}
                    </option>
                  ))} */}
                {/* </select> */}
              </div>
              <input
                type="text"
                className="input-form text-center mb-3"
                placeholder="Enter Document Password, if any "
              />
              <div className="position-relative pb-2">
                <button className={`btn btn-primary btn-full ${disableUploadProof ? 'disable' : ''}`}>
                  <img
                    src="images/file-upload.svg"
                    alt="icon"
                    className="mr-2"
                  />
                  Upload Document
                </button>
                <input type="file"
                  multiple
                  className="upload-input w-100 multiple-income-upload"
                  accept=".jpeg, .jpg, .png, .pdf"
                  // onChange={handleFileChange} 
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  disabled={disableUploadProof} />
              </div>
            </div>
            {selectedFiles?.length > 0 && (
              <div className='income-proof-files'>
                <ul>
                  {selectedFiles?.map((file, index) => (
                    <li key={index}>
                      {file?.name}{' '}
                      <button type="button" onClick={() => handleFileRemove(index)} className="btn-close"></button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="btn-action-box">
            <button className={`btn btn-${selectedFiles?.length == 0 ? 'secondary' : 'primary'} btn-full mb-2`} onClick={onChangeHandlerNext} disabled={selectedFiles?.length == 0}>
              Continue to Next Step
            </button>
            {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default UploadIncomeProof;