const kraAppStatusValidCodes = ['007', '107', '207', '307', '407'];

const kraUpdateStatusValidCodes = ['002', '005', '007', '107', '207', '307', '407'];

const KraCodes = [
    { '002': 'CVLKRA' },
    { '007': 'CVLKRA' },
    { '102': 'NDML' },
    { '107': 'NDML' },
    { '202': 'DOTEX' },
    { '207': 'DOTEX' },
    { '302': 'CAMS' },
    { '307': 'CAMS' },
    { '402': 'KARVY' },
    { '407': 'KARVY' },
    { '502': 'BSE' },
    { '507': 'BSE' }
  ];
  const posCode = 1200000235;

  const validAddressProofs = ['01', '02', '06', '31'];

  const errorCodes = [
    "WEBERR-001",
    "WEBERR-002",
    "WEBERR-003",
    "WEBERR-004",
    "WEBERR-005",
    "WEBERR-006",
    "WEBERR-007",
    "WEBERR-008",
    "WEBERR-009",
    "WEBERR-010",
    "WEBERR-011",
    "WEBERR-012",
    "WEBERR-013",
    "WEBERR-014",
    "WEBERR-015",
  ]; 
  const stateCodes = [
    { code: '001', description: 'Jammu and Kashmir' },
    { code: '002', description: 'Himachal Pradesh' },
    { code: '003', description: 'Punjab' },
    { code: '004', description: 'Chandigarh' },
    { code: '005', description: 'Uttarakhand' },
    { code: '006', description: 'Haryana' },
    { code: '007', description: 'Delhi' },
    { code: '008', description: 'Rajasthan' },
    { code: '009', description: 'Uttar Pradesh' },
    { code: '010', description: 'Bihar' },
    { code: '011', description: 'Sikkim' },
    { code: '012', description: 'Arunachal Pradesh' },
    { code: '013', description: 'Assam' },
    { code: '014', description: 'Manipur' },
    { code: '015', description: 'Mizoram' },
    { code: '016', description: 'Tripura' },
    { code: '017', description: 'Meghalaya' },
    { code: '018', description: 'Nagaland' },
    { code: '019', description: 'West Bengal' },
    { code: '020', description: 'Jharkhand' },
    { code: '021', description: 'Orissa' },
    { code: '022', description: 'Chhattisgarh' },
    { code: '023', description: 'Madhya Pradesh' },
    { code: '024', description: 'Gujarat' },
    { code: '025', description: 'Daman and Diu' },
    { code: '026', description: 'Dadra and Nagar Haveli' },
    { code: '027', description: 'Maharashtra' },
    { code: '028', description: 'Andhra Pradesh' },
    { code: '029', description: 'Karnataka' },
    { code: '030', description: 'Goa' },
    { code: '031', description: 'Lakshadweep' },
    { code: '032', description: 'Kerala' },
    { code: '033', description: 'Tamil Nadu' },
    { code: '034', description: 'Puducherry' },
    { code: '035', description: 'Andaman and Nicobar Islands' },
    { code: '036', description: 'APO' },
    { code: '037', description: 'Telangana' },
    { code: '099', description: 'Others Applicable for cKYC' },
  ];
  const addressCodes = [
    { code: '001', description: 'Utility Bill' },
    { code: '002', description: 'Bank Statement' },
    { code: '003', description: 'Rental Agreement' },
    { code: '004', description: 'Passport' },
    { code: '005', description: 'Driver\'s License' },
    { code: '006', description: 'Aadhar Card' },
    { code: '007', description: 'Voter ID' },
    { code: '008', description: 'Electricity Bill' },
    { code: '009', description: 'Gas Bill' },
    { code: '010', description: 'Water Bill' },
    { code: '011', description: 'Telephone Bill' },
    { code: '012', description: 'Property Tax Receipt' },
    { code: '013', description: 'Rent Receipt' },
    { code: '014', description: 'Income Tax Return' },
    { code: '015', description: 'PAN Card' },
    { code: '016', description: 'Social Security Card' },
    { code: '017', description: 'Municipal Corporation Receipt' },
    { code: '018', description: 'Employment ID Card' },
    { code: '019', description: 'Student ID Card' },
    { code: '020', description: 'Driving License' },
    { code: '021', description: 'Health Insurance Card' },
    { code: '022', description: 'Passport of Spouse' },
    { code: '023', description: 'Letter from Employer' },
    { code: '024', description: 'Lease Agreement' },
    { code: '025', description: 'Self-Declaration' },
    { code: '026', description: 'Other' },
    { code: '027', description: 'Not Applicable' },
  ];

export {kraAppStatusValidCodes , kraUpdateStatusValidCodes,KraCodes , posCode ,validAddressProofs , errorCodes , stateCodes ,addressCodes};