import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { changeEmailInputAction } from '../../../redux/actions/kyc/userlogin.action';
import { EmailOtpSentModalOpen, EmailOtpSentModalClose } from '../../../redux/actions/Modal';
import ModalEmailVerification from '../../Modals/ModalEmailVerification';
import { EmailGetAPi, commonPostApi } from '../../../ApiServices/common';
import { store } from '../../../redux/store';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';
import axios from 'axios';
import Encripted from '../../Utils/Encrypt';

function EmailInput() {
  const [errorMsg, setErrorMsg] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const kycStep1 = useSelector(state => state?.userLogin || {});
  const inputRef = useRef(null);

  const initalStageforPan = '2';
  // useResumeJourneyPerStage(initalStageforPan);
  useCheckRequiredJourneyData(initalStageforPan);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/i;
    return emailRegex.test(email);
  };

  const onChangeHandlerNext = async () => {
    setErrorMsg('');
    EmailGetAPi(`validateEmail/`,kycStep1?.emailInput)
    .then((response)=>{
      if(response.status === true){
        setErrorMsg('This email already exists. Please enter a different email address.');
      }else{
        commonPostApi('/send-verification-otp-web', {
          "mobile": kycStep1?.mobileInput,
          "email": kycStep1?.emailInput
        }, null,)
          .then((response) => {
            if (response.status == 200) {
              navigate('/email-verification');
              // Dispatch(EmailOtpSentModalOpen(true))
              // setTimeout(() => {
              //   Dispatch(EmailOtpSentModalClose(false));
              //   navigate('/email-verification');
              // }, 2000);
            }
            else { setErrorMsg(response?.message ? response?.message : "Some Error Has Occured while sending OTP, Please try again"); }
    
          })
          .catch((error) => {
            setErrorMsg(error);
          })
      }
    })
    .catch((err)=>{
      setErrorMsg(err);
    });
   
   


  }
  const inputEmailHandler = (e) => {
    if (validateEmail(e.target.value) || e.target.value.length == 0) {
      setErrorMsg('');
    } else {
      setErrorMsg('Please Enter a valid Email address');
    }
    setIsValidEmail(validateEmail(e.target.value));
    Dispatch(changeEmailInputAction(e.target.value));
  }
  useEffect(() => {
    if (validateEmail(kycStep1.emailInput)) {
      setIsValidEmail(true);
      setErrorMsg('');
    } else {
      if (kycStep1.emailInput.length == 0 || kycStep1.emailInput == null) {
        setErrorMsg('');
      } else { setErrorMsg('Please Enter a valid Email address'); }
      setIsValidEmail(false);

    }
  }, [kycStep1.emailInput])

  
  const handleEnterFunctionality = (event) => {
    if (event.key === 'Enter' && isValidEmail) {
      onChangeHandlerNext();
    }

  }

  return (<>
    <section className="main-wraper">
      <header className="site-header">
        <div className="site-header-wrap">
          <a>
            <img src="images/bigul-logo-fav.svg" alt="image" />
          </a>
          {/* <p> Email Verification</p> */}
        </div>
      </header>
      <AsideWallpaper
        color={'blue'}
        descriptionHeader={''}
        backGroundImageUrl={"images/email-verify.svg"}
      />
      <div className="body-content-box">
        <div className={`login-box ${inputRef.current ? 'xcyz' : 'vvv'}`}>
          <div className="login-box-wrap mw-468">
            <div className="">
              <h1 className="title-1 mb-42">Email Verification </h1>
            </div>
            <div className="block-center mw-400">
              <p className="para-1">
                Enter your Email. We will send you an OTP on  your Email address to verify!
              </p>
            </div>
            <div className="input-box mt-5">
              <input
                type="email"
                autoFocus
                className="input-form text-center"
                placeholder="Enter Email Address"
                value={kycStep1?.emailInput}
                onChange={inputEmailHandler}
                ref={inputRef}
                onKeyDown={handleEnterFunctionality}
              />
            </div>
            {errorMsg && <div className='input-error-msg text-center' style ={{fontSize : '14px',marginTop : '5px'}}>{errorMsg}</div>}
          </div>
          <div className="btn-action-box"> 
            <button className={`btn btn-${isValidEmail ? 'primary' : 'secondary'} btn-full mb-2`} onClick={onChangeHandlerNext} disabled={!isValidEmail}>
              Send OTP
            </button>

          </div>
        </div>
      </div>
    </section>
    <ModalEmailVerification />
  </>)
}
export default EmailInput;