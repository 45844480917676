import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import { clientCode } from '../../../redux/actions/kyc/step8.action';
import { useSelector } from 'react-redux';
import { dkycApi, getKycJourney } from '../../../ApiServices/common';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import './Card.css';

function ThankYouForSigning() {
  const navigate = useNavigate();
  const [errrorMsg, setErrorMsg] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [partnerCode, setPartnerCode] = useState('');
  const userDetails = useSelector(state => state?.userLogin || {});



  //resume journey
  const initalStageForWelcomePage = '16';
  useResumeJourneyPerStage(initalStageForWelcomePage);


  useEffect(() => {
    const fetchData = async () => {
      const mobile = userDetails?.mobileInput;
      const payload = {
        mobile: mobile
      };
      try {
        const response = await dkycApi('/getKycJourney', payload, 'multipart/form-data');
        if (response?.success === true) {
          setclientCode(response?.data[0]?.LDCode);
          setPartnerCode(response?.data[0]?.PartnerCode);
        }
        else {
          setErrorMsg("Something went wrong!!");
        }
      } catch (err) {
        setErrorMsg(err);
      }
    }
    fetchData();
  }, []);

  return (<>
    <section className="main-wraper">
      <header className="site-header">
        <div className="site-header-wrap justify-content-evenly">
          <a>
            <img src="images/bigul-logo-fav.svg" alt="image" />
          </a>
          <p></p>
        </div>
      </header>
      <AsideWallpaper
        color={'blue'}
        descriptionHeader={''}
        backGroundImageUrl={"./images/our-team.svg"}
      />
      <div className="body-content-box">
        <div className="login-box pt-80">
          <div className="login-box-wrap">
            <div className="">
              <img
                src="./images/email-verification-sent.svg"
                className="mw-100 mb-4 mt-0"
                alt="img"
              />
              <p className="title-1 mb-2 mt-2">

              </p>
              
              <p class="pb-2 font-size16 poppins font-weight400 grey60">Thank you for creating a Super Trading Account with Bigul (Bonanza Portfolio Limited).
                Our team is verifying your details and you will be able to access your new account in next 24-48 hours.</p>
              {/* <div className="mt-4 mb-4 internal-btn-box mw-250 block-center">
                {partnerCode !== '' && partnerCode !== null && partnerCode !== undefined ? <h5 className="" > BA Code  : {partnerCode} </h5> : <h5 className="" > Client Code  : {clientCode} </h5>}
               
              </div> */}
              <div class="card">
                <div class="card-item">
                  <span class="label">Username</span>
                  <span class="value">GUEST01</span>
                </div>
                <div class="card-item">
                  <span class="label">Password</span>
                  <span class="value">Bigul@12345</span>
                </div>
                <div class="card-item">
                  <span class="label">PIN</span>
                  <span class="value">111111</span>
                </div>
                <div class="card-item">
                  {partnerCode !== '' && partnerCode !== null && partnerCode !== undefined ?
                    <span className="label" > BA Code  : {partnerCode} </span> : <span className="value" > Client Code   : {clientCode} </span>}
                </div>
              </div>
              {partnerCode !== '' && partnerCode !== null && partnerCode !== undefined ?
                <a href='https://bigul.co/partners/thank-you-ba?esign_status=1' className="btn btn-primary btn-full" >Go to Partners Page</a>
                :
                <a href='https://bigul.page.link/fJc4' className="btn btn-primary btn-full" >Experience Our App now</a>

              }
              {/* <a href='https://bigul.co/' className="btn btn-primary btn-full" >Go to Home Page </a> */}

              <p className="para-1 font-14 mb-0">
                If you have any questions or concerns regarding this. Do not hesitate to contact us at  &nbsp;
                <p className="color-blue font-600 mb-0">
                  support@bigul.co
                </p>
              </p>
              <p className="text-center mb-1">
                <img src="./images/happy-trading.svg" className="mw-100" />
              </p>
              <p className="text-center color-blue mb-0 font-20 font-600">
                Happy Trading!
              </p>
              <p className="text-center font-14">- Team Bigul</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default ThankYouForSigning;