export const setAlreadyRegisteredModalOpen = 'AlREADY_REGISTERED_MODAL_OPEN';
export const setModalClose = "MODAL_CLOSE";
export const setOtpSentModalOpen = 'OTP_SENT_MODAL_OPEN';
export const setOtpSentModalClose = 'OTP_SENT_MODAL_CLOSE';
export const setEmailOtpSentModalOpen = 'Email_OTP_SENT_MODAL_OPEN';
export const setEmailOtpSentModalClose = 'Email_OTP_SENT_MODAL_CLOSE';
export const setEmailVerifyModalOpen = 'EMAIL_VERIFY_MODAL_OPEN';
export const setEmailVerifyModalClose = 'EMAIL_VERIFY_MODAL_CLOSE';
export const setDocumentFetchModalOpen = 'DOCUMENT_FETCH_OPEN';
export const setDocumentFetchModalClose = 'DOCUMENT_FETCH_CLOSE';
export const setPennyDropSuccessModalOpen = "PENNY_SUCCESS_OPEN";
export const setPennyDropSuccessModalClose = "PENNY_SUCCESS_CLOSE";
export const setBankVerifiedModalOpen = "BANK_VERIFIED_OPEN";
export const setBankVerifiedModalClose = "BANK_VERIFIED_CLOSE";
export const setBankNotVerifiedModalOpen = "BANK_COULD_NOT_VERIFY_OPEN";
export const setBankNotVerifiedModalClose = "BANK_COULD_NOT_VERIFY_CLOSE";

export const AlreadyRegisteredModalOpen = (payload) => {
    return {
        type: setAlreadyRegisteredModalOpen,
        payload
    };
};
export const ModalClose = (payload) => {
    return {
        type: setModalClose,
        payload
    }
};
export const OtpSentModalOpen = (payload) => {
    return {
        type: setOtpSentModalOpen,
        payload
    };
};
export const OtpSentModalClose = (payload) => {
    return {
        type: setOtpSentModalClose,
        payload
    };
};
export const EmailOtpSentModalOpen = (payload) => {
    return {
        type: setEmailOtpSentModalOpen,
        payload
    };
};
export const EmailOtpSentModalClose = (payload) => {
    return {
        type: setEmailOtpSentModalClose,
        payload
    };
};
export const EmailVerifyModalOpen = (payload) => {
    return {
        type: setEmailVerifyModalOpen,
        payload
    };
};
export const EmailVerifyModalClose = (payload) => {
    return {
        type: setEmailVerifyModalClose,
        payload
    };
};
export const DocumentFetchModalOpen = (payload) =>{
    return {
        type : setDocumentFetchModalOpen,
        payload
    }
}
export const DocumentFetchModalClose = (payload) =>{
    return {
        type : setDocumentFetchModalClose,
        payload
    }
}
export const PennyDropSuccessModalOpen = (payload) => {
    return {
        type: setPennyDropSuccessModalOpen,
        payload
    };
};
export const PennyDropSuccessModalClose = (payload) => {
    return {
        type: setPennyDropSuccessModalClose,
        payload
    };
};
export const BankVerifiedModalOpen=(payload)=>{
    return {
        type : setBankVerifiedModalOpen,
        payload
    };
};
export const BankVerifiedModalClose=(payload)=>{
    return {
        type : setBankVerifiedModalClose,
        payload
    };
};
export const BankNotVerifiedModalOpen=(payload)=>{
    return {
        type : setBankNotVerifiedModalOpen,
        payload
    };
};
export const BankNotVerifiedModalClose=(payload)=>{
    return {
        type : setBankNotVerifiedModalClose,
        payload
    };
};
export const ContinueOnMobileOpen = (payload)=>{
    return {
        type : setAlreadyRegisteredModalOpen,
        payload
    }
}