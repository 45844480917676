import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { changeStep1DobInputAction, changeStep1PanInputAction, getPanCardDetailAction, setDobErrorAction, setPanErrorAction } from '../../../redux/actions/kyc/step1.action';
import { commonPostApi, commonPostApiWithoutLoader, kraGetAPi } from '../../../ApiServices/common';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';
import StepsHeader from '../../Utils/StepsHeader';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { AlreadyExistDataAction } from '../../../redux/actions/kyc/userlogin.action';
import { AlreadyRegisteredModalOpen, ModalClose } from '../../../redux/actions/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendar } from 'react-icons/fa';
import { useRef } from 'react';
import dayjs from 'dayjs';
import { setCorAddressProofAction, setCorAddressProofRefAction, setPerAddressProofAction, setPerAddressProofRefAction, setBirthPlaceAction, setCorAddressPincodeAction, setCorCityAction, setCorStateAction, setCurrentAddress1Action, setCurrentAddress2Action, setCurrentAddress3Action, setFatherSpouseAction, setGenderAction, setIpvStatusAction, setIsKraApprovedAction, setMaritalStatusAction, setNationalityAction, setPerAddress1Action, setPerAddress2Action, setPerAddress3Action, setPerAddressPincodeAction, setPerCity, setPerCityAction, setPerStateAction, setPerState, setKRACodeErrorAction, setKRAErrorDescAction, setAppStatusAction, setAppUpdateStatus, setAppUpdateStatusAction, setUniqueKeyAction } from '../../../redux/actions/kyc/step3.action';
import { setIncomeAction, setOccupationAction } from '../../../redux/actions/kyc/step4.action';
import ReactInputMask from 'react-input-mask';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';
import axios from 'axios';
import { KraCodes, errorCodes, kraAppStatusValidCodes, kraUpdateStatusValidCodes, posCode, validAddressProofs } from '../../../common/data/kraData';
import Encripted from '../../Utils/Encrypt';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)


function VerifyPanDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState('');


  const initalStageforPan = '5';
  useCheckRequiredJourneyData(initalStageforPan);

  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const userLogin = useSelector(state => state?.userLogin || {});
  const mobile = userLogin.mobileInput;
  const [nameOnPan, setNameOnPan] = useState('');
  const [kraError, setKraError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [isValidPan, setIsValidPan] = useState(false);
  const [validDob, setIsValidDob] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(null);
  const [panError, setPanError] = useState('');
  const [consentKra, setConsentKra] = useState(true);
  const [panVerified, setPanVerified] = useState(false);


  const ConsentKraHandler = () => {
    setConsentKra(!consentKra);
  }

  const dob = kycStep1?.dobInput?.value;
  const date = new Date(dob);
  const formattedDOB =
    date.toLocaleDateString('en-GB');
  const [errorMsg, setErrorMsg] = useState('');

  //calendar open on click of a icon
  const [calendarOpen, setCalendarOpen] = useState(false);
  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  const currentDate = new Date();
  const hundreadYearsAgo = new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());
  currentDate.setFullYear(currentDate.getFullYear() - 18);

  useEffect(() => {
    const pan = kycStep1?.panInput?.value;
    const formattedPan = pan.toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (validatePan(formattedPan)) {
      setIsValidPan(true);
    }
    if (selectedDate) {
      setIsValidDob(true);
    } else { setIsValidDob(false); }
  }, [isValidPan, validDob, selectedDate])

  const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const nameInputHandler = (e) => { setNameOnPan(e.target.value) }

  const panInputHandler = (e) => {
    setErrorMessage('');
    setPanError('');
    const panNumber = e.target.value;
    const formattedPan = panNumber.toUpperCase().replace(/[^A-Z0-9]/g, '');
    let validPan = validatePan(formattedPan);
    if (validPan) {
      setPanError('');
      setIsValidPan(true);
    }
    else {
      if (panNumber?.length == 0) { setPanError('') } else { setPanError('Please enter a valid PAN Number.'); }
      setIsValidPan(false);
    }
    dispatch(changeStep1PanInputAction(formattedPan));
  };
  const validatePan = (code) => {
    return panCardRegex.test(code);
  }
  const validateAge = (date) => {
    const todayDate = new Date();
    const birthDate = new Date(date);
    const ageDiff = todayDate - birthDate;
    const age = Math.floor(ageDiff / (1000 * 60 * 60 * 24 * 365.25));
    return age >= 18 && age < 100;
  };
  const dobInputHandler = (value) => {
    setErrorMessage('');
    setKraError('');
    setIsValidDob(true);
    dispatch(changeStep1DobInputAction(value));
    dispatch(setKRAErrorDescAction(''));
    setSelectedDate(value);
    setCalendarOpen(false);
  };
  //confirm it's you page functions
  const verifyKRAErrorCodeExist = (erroCode) => {
    return errorCodes.includes(erroCode) ? true : false;
  }
  const extractAppStatus = (xmlResponse) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

    const appStatusElement = xmlDoc.querySelector('APP_STATUS');
    if (appStatusElement) {
      const appStatusCode = appStatusElement.textContent;
      return appStatusCode.toString();
    } else {
      return '';
    }
  };
  const extractUPDTStatus = (xmlResponse) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

    const appStatusElement = xmlDoc.querySelector('APP_UPDT_STATUS');
    if (appStatusElement) {
      const appStatusCode = appStatusElement.textContent;
      return appStatusCode.toString();
    } else {
      return '';
    }
  };

  const findKRADescription = (kraCode) => {
    const response = KraCodes.find(item => Object.keys(item)[0] === kraCode);
    return response ? response[kraCode] : null;
  }

  const validateAppStatus = (appStatus) => {
    return kraAppStatusValidCodes.includes(appStatus) ? true : false;
  };
  const validateUPDTStatus = (appStatus) => {
    return kraUpdateStatusValidCodes.includes(appStatus) ? true : false;
  };
  const getTagsFromXmlResponse = (xmlResponse, tags) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
    const ans = {};
    for (let tag of tags) {
      let value = xmlDoc.querySelector(tag);
      value = value ? (value.textContent).toString() : "";
      ans[tag] = value;
    }
    return ans;
  }
  //has minimum 3 characters in add line 1,2
  const hasMinThreeAlphanumeric = (str) => {
    const alphanumericCount = str.replace(/[^a-zA-Z0-9]/g, '').length;
    return alphanumericCount >= 2;
  };

  const onChangeHandlerNext = async () => {
    setKraError();
    setErrorMessage('');
    const pan = kycStep1.panInput.value;
    const dob = kycStep1?.dobInput?.value
    const stage = userLogin?.currentStage;
    if (validateAge(dob) !== true) {
      const year = dob.getFullYear();
      if (year < 1940) {
        setErrorMessage('Please select a valid year');
      } else {
        setErrorMessage('Minimum age requirement is 18 years');
      }
      setIsValidDob(false);
      return;
    }
    function formatDate(dateString) {
      // Create a new Date object from the provided dateString
      const date = new Date(dateString);

      // Extract day, month, and year
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based, so we add 1
      const year = date.getFullYear();

      // Format day and month to have leading zeros if necessary
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      // Construct the formatted date string in the desired format
      const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

      return formattedDate;
    }

    const panApiData = {
      "mobile": mobile,
      "pan": pan,
      "dob": formatDate(dob),
      "current_stage": stage,
      "name": nameOnPan
    }
    dispatch(getPanCardDetailAction(panApiData));
    try {
      const response = await axios.get(`https://ekycapi.bigul.co/validatePanMyetrade?panNo=${pan}&dob=${formatDate(dob)}&name=${nameOnPan}`);
      if (response.data.status === true) {
        setPanVerified(true);
        try {
          const response = await commonPostApi('/pan-verification-web', panApiData, null)
          // .then((response) => {
          if (response?.msg == 'Your account with Bonanza already exists.') {
            dispatch(AlreadyExistDataAction(response?.user_data));
            dispatch(AlreadyRegisteredModalOpen(true))
            setTimeout(() => {
              dispatch(ModalClose(false));
              navigate('/Client-exist');
            }, 1000);
            return;
          }
          if (response?.status == 200) {
            if (consentKra === false) {
              // dispatch(setIsKraApprovedAction(false));
              // navigate('/digiLocker-verification');
            }
            else {
              const userData = {
                fullName: `${response.user_data.firstName} ${response?.user_data?.middleName} ${response?.user_data?.lastName}`,
                seeding: response.user_data.seeding
              };
              //before kra apis
              try {
                const xmlResponse = await kraGetAPi('/kyraapi?panNo=', pan)
                const status = extractAppStatus(xmlResponse);
                const updatedStatus = extractUPDTStatus(xmlResponse);
                const KRA_APPROVED = validateAppStatus(status);
                const KRA_UPDATED = validateUPDTStatus(updatedStatus);
                if (KRA_APPROVED) {
                  if (KRA_UPDATED) {
                    // dispatch(setIsKraApprovedAction(true)); 
                    const description = findKRADescription(status);

                    const InputXML =
                      `<APP_REQ_ROOT>
         <APP_PAN_INQ>
         <APP_PAN_NO>${pan}</APP_PAN_NO>
         <APP_DOB_INCORP>${formattedDOB}</APP_DOB_INCORP>
         <APP_POS_CODE>${posCode}</APP_POS_CODE>
         <APP_RTA_CODE>${posCode}</APP_RTA_CODE>
         <APP_KRA_CODE>${description}</APP_KRA_CODE>
         <FETCH_TYPE>I</FETCH_TYPE>
         </APP_PAN_INQ>
         </APP_REQ_ROOT>`;
                    var postdata = {
                      InputXML: InputXML
                    };
                    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
                    const response2 = await commonPostApi('/kyraapisecond', postdata, { headers: headers });
                    const saveKraDataPayload = {
                      mobile: userLogin?.mobileInput,
                      xml_data: response2,
                      app_no: status
                    }
                    const SuccessTags = ["APP_NATIONALITY", "APP_COR_ADD3", "APP_COR_ADD2", "APP_COR_ADD1", "APP_OCC", "APP_INCOME", "APP_IPV_FLAG", "APP_PER_CITY", "APP_GEN", "APP_MAR_STATUS", "APP_F_NAME", "APP_PER_ADD1", "APP_PER_ADD2", "APP_PER_ADD3", "APP_PER_PINCD", "APP_COR_PINCD", "APP_COR_CITY", "APP_PER_CITY", "APP_COR_STATE", "APP_PER_STATE", 'APP_COR_ADD_PROOF', 'APP_PER_ADD_PROOF', 'APP_COR_ADD_REF', 'APP_PER_ADD_REF', 'APP_OTHKRA_BATCH'];
                    const ErroTags = ["APP_ERROR_CODE", "APP_ERROR_DESC"];
                    const errorResponse = getTagsFromXmlResponse(response2, ErroTags);
                    const appErrorCode = errorResponse['APP_ERROR_CODE'];
                    const appErrorDesc = errorResponse['APP_ERROR_DESC'];
                    if (verifyKRAErrorCodeExist(appErrorCode)) {
                      dispatch(setIsKraApprovedAction(false));
                      dispatch(getPanCardDetailAction(userData));
                      setKraError(appErrorDesc && 'Invalid DOB, please enter the correct Date of Birth.');
                      // navigate('/pan-verification');
                      return false;
                    }
                    else {
                      const finalResponse = getTagsFromXmlResponse(response2, SuccessTags);
                      console.log(finalResponse, 'finalResponse');
                      const addressProperties = [
                        finalResponse?.APP_PER_ADD1,
                        finalResponse?.APP_PER_ADD2,
                        finalResponse?.APP_COR_ADD1,
                        finalResponse?.APP_COR_ADD2,
                      ];
                      if (addressProperties.every((address) => hasMinThreeAlphanumeric(address))) {
                        //eligible to go
                      } else {
                        dispatch(getPanCardDetailAction(userData));
                        dispatch(setIsKraApprovedAction(false));
                        navigate('/name-confirm');
                        return;
                        // navigate('/digiLocker-verification');
                      }
                      if (validAddressProofs.includes(finalResponse?.APP_PER_ADD_PROOF)) {
                        // eligible to go 
                      } else {
                        console.log('not eligible address proof');
                        dispatch(getPanCardDetailAction(userData));
                        dispatch(setIsKraApprovedAction(false));
                        navigate('/name-confirm');
                        // navigate('/digiLocker-verification');
                      }
                      dispatch(setIsKraApprovedAction(true));
                      dispatch(setGenderAction(finalResponse['APP_GEN']));
                      dispatch(setNationalityAction(finalResponse['APP_NATIONALITY']));
                      dispatch(setMaritalStatusAction(finalResponse['APP_MAR_STATUS']));
                      dispatch(setFatherSpouseAction(finalResponse['APP_F_NAME']));
                      dispatch(setBirthPlaceAction(finalResponse['APP_PER_CITY']));
                      dispatch(setPerAddressPincodeAction(finalResponse['']))
                      dispatch(setCurrentAddress1Action(finalResponse['APP_COR_ADD1']));
                      dispatch(setCurrentAddress2Action(finalResponse['APP_COR_ADD2']));
                      dispatch(setCurrentAddress3Action(finalResponse['APP_COR_ADD3']));
                      dispatch(setPerAddress1Action(finalResponse['APP_PER_ADD1']));
                      dispatch(setPerAddress2Action(finalResponse['APP_PER_ADD2']));
                      dispatch(setPerAddress3Action(finalResponse['APP_PER_ADD3']));
                      dispatch(setPerAddressPincodeAction(finalResponse['APP_PER_PINCD']));
                      dispatch(setCorAddressPincodeAction(finalResponse['APP_COR_PINCD']));
                      dispatch(setPerCityAction(finalResponse['APP_PER_CITY']));
                      dispatch(setCorCityAction(finalResponse['APP_COR_CITY']));
                      dispatch(setPerStateAction(finalResponse['APP_PER_STATE']));
                      dispatch(setCorStateAction(finalResponse['APP_COR_STATE']));
                      dispatch(setIpvStatusAction(finalResponse["APP_IPV_FLAG"]));
                      dispatch(setOccupationAction(finalResponse["APP_OCC"]));
                      dispatch(setIncomeAction(finalResponse["APP_INCOME"]));

                      dispatch(setCorAddressProofAction(finalResponse["APP_COR_ADD_REF"] === 'NA' || finalResponse["APP_COR_ADD_REF"] === '' || Object.keys(finalResponse["APP_COR_ADD_REF"]).length === 0 ? 'NA' : ` ${finalResponse["APP_COR_ADD_REF"]}`));
                      dispatch(setPerAddressProofAction(finalResponse["APP_PER_ADD_REF"] === 'NA' || finalResponse["APP_PER_ADD_REF"] === '' || Object.keys(finalResponse["APP_PER_ADD_REF"]).length === 0 ? 'NA' : ` ${finalResponse["APP_PER_ADD_REF"]}`));
                      dispatch(setCorAddressProofRefAction(Object.keys(finalResponse["APP_COR_ADD_PROOF"]).length === 0 ? 'NA' : finalResponse["APP_COR_ADD_PROOF"]));
                      dispatch(setPerAddressProofRefAction(Object.keys(finalResponse["APP_PER_ADD_PROOF"]).length === 0 ? 'NA' : finalResponse["APP_PER_ADD_PROOF"]));
                      dispatch(setAppStatusAction(status));
                      dispatch(setAppUpdateStatusAction(updatedStatus));
                      dispatch(setUniqueKeyAction(finalResponse["APP_OTHKRA_BATCH"]));
                      dispatch(setIsKraApprovedAction(true));
                      commonPostApi('/save-kra-data', saveKraDataPayload, null)
                        .then((res) => { console.log(res); })
                        .catch((err) => { console.log(err, 'err'); setErrorMsg(err); return; })
                      navigate('/personal-details-1');
                    }
                  } else {
                    dispatch(setIsKraApprovedAction(false));
                    navigate('/digiLocker-verification');
                  }
                }
                else {
                  // store.dispatch(stopLoaderAction());
                  dispatch(setIsKraApprovedAction(false));
                  navigate('/digiLocker-verification');
                }
              } catch (error) {
                store.dispatch(stopLoaderAction());
                setErrorMsg("Some Error has occured, please try again!ddd");
              }
              //after kra apis
              dispatch(getPanCardDetailAction(userData));
              // navigate('/name-confirm');
            }
          } else {
            setErrorMessage(response?.msg ? response?.msg : "something went wrong while verifying your pan card, kindly check details and try again");
            return;
          }
        }
        catch (error) {
          store.dispatch(stopLoaderAction());
          setErrorMessage(error);
        }
      }
      else {
        setPanVerified(false);
        setErrorMessage(response.data.message);
        return;
      }
    } catch {
      setErrorMessage('Some Error Has Occured Please try again later');
      return;
    }


  }
  const inputRef = useRef(null);

  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader
        step={1}
      />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/pan-card-details.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-2 mb-36">Verify PAN Details </h1>
            </div>
            <div className="block-center">
              <p className="para-1">
                Enter your Name, PAN number and Date of Birth. <br /> We will verify the
                details for you.
              </p>
            </div>
            <div className="input-box mt-5">
              <input
                type="text"
                className="input-form text-center mb-3 input-pan-card"
                placeholder="Name as per PAN"
                onChange={nameInputHandler}
                value={nameOnPan}
              />

              {panError && <div className='input-error-msg text-center'>{panError}</div>}
              <ReactInputMask
                mask="aaaaa9999a" maskChar={null}
                type="text"
                autoFocus
                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                // maxLength={10}
                className="input-form text-center mb-3 input-pan-card"
                placeholder="Enter PAN Number"
                onChange={panInputHandler}
                value={kycStep1?.panInput?.value}
              />

              <div className='input-date-box'>
                <DatePicker
                  customInput={<ReactInputMask
                    type="text"
                    mask="99/99/9999"
                  />}
                  selected={selectedDate}
                  onChange={dobInputHandler}
                  dateFormat="dd/MM/yyyy"
                  placeholderText='DD/MM/YYYY'
                  showYearDropdown
                  yearDropdownItemNumber={80}
                  scrollableYearDropdown
                  ref={inputRef}
                  openToDate={currentDate}
                  open={calendarOpen}
                />
                <FaCalendar className="calendar-icon" onClick={toggleCalendar} />
                {errorMessage && <p className='input-error-msg text-center'>{errorMessage}</p>}
                {kraError && <div className='input-error-msg text-center' style={{ fontSize: '14px', marginTop: '2px' }}>{kraError}</div>}
              </div>
            </div>
          </div>
          <div className="btn-action-box" >
            <label className="text-left d-flex " style={{ marginBottom: "5px", marginLeft: "10px" }} >
              <input type="checkbox" checked={consentKra} onChange={ConsentKraHandler} />
              <span className="para-12 ml-1 d-inline-block mr-10" >
                I hereby provide consent for downloading my updated  KYC details from KRA and CKYCRR, to open my Bigul account
              </span>
            </label>
            <button
              type='button'
              className={`btn btn-${!(isValidPan && validDob && nameOnPan) ? 'secondary' : 'primary'} btn-full mb-2`}
              data-bs-toggle="modal"
              data-bs-target="#Modal-3" onClick={onChangeHandlerNext} disabled={!(isValidPan && validDob && nameOnPan)}>
              Verify PAN
            </button>

            {
              kraError &&
              <div className='text-center mt-2'>
                <p style={{ backgroundColor: '#EFEFEF', borderRadius: '50%', height: '40px', width: '40px', lineHeight: '40px', display: 'inline-block' }}>OR</p>
                <p className="para-1 mb-0 pb-4 font-400">
                  <a href="/digiLocker-verification'" className="color-blue" style={{ textDecoration: 'underline' }} >
                    Try Another Way
                  </a>
                </p>
              </div>

            }
          </div>
        </div>
      </div>
    </section>

  </>)

}
export default VerifyPanDetails;