import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonPostApi, dkycApi, dkycApiWithLoader, getKycJourney } from '../../ApiServices/common';
import { useNavigate } from 'react-router-dom'; // Import the 'useNavigate' hook from your routing library
import { changeStep1PanInputAction } from '../../redux/actions/kyc/step1.action';
import { digioImageStatusAction } from '../../redux/actions/kyc/step6.action';
import { resetStateAction } from '../../redux/actions/reset.action';

export const
   useResumeJourneyPerStage = (staticState) => {

    const dispatch = useDispatch()
    const staticS = staticState;
    const userLogin = useSelector((state) => state?.userLogin || {});
    const kycStep3 = useSelector(state => state?.ekycStep3 || {});
    const [errorMsg, setErrorMsg] = useState('');
    const isKraApproved = kycStep3?.isKraApproved; 
    const navigate = useNavigate();

    const ResumeJourney = (stage, isMobileVerified, isEmailVerified, pan, digioImageStatus, ) => {
    
      if (isMobileVerified == 0 && isEmailVerified == 0) {
        navigate('/');
      }
      else if (isMobileVerified == 0 && isEmailVerified == 1) {
        navigate('/');
      }
      else if (isMobileVerified == 1 && isEmailVerified == 0) {
        navigate('/email-input');
      }
      else if (isMobileVerified == 1 && isEmailVerified == 1) {
        switch (stage) {
          case '1':
            navigate('/');
            break;
          case '2':
            navigate('/email-input');
            break;
          case '3':
            navigate('/email-input');
            break;
          case '4':
            navigate('/pan-verification');
            break;
          case '5':
            navigate('/pan-verification');
            break;
          case '6':
            navigate('/personal-details-1');
            break;
          case '7':
            navigate('/occupation-details');
            break;
          case '8':
            navigate('/find-bank-details');
            break;
          case '9':
            navigate('/segment-details');
            break;
          case '10':
            navigate('/bank-proof');
            break;
          case '11':
            dispatch(digioImageStatusAction(digioImageStatus))
            dispatch(changeStep1PanInputAction(pan));
            navigate('/income-proof');
            break;
          case '12':
            navigate('/selfie');
            break;
          case '13':
            navigate('/draw-sign');
            break;
          case '14':
            navigate('/e-sign');
            break;
          case '15':
            navigate('/welcome-page')
            break;
          case '16':
            navigate('/thanks-page');
            break;
          default:
            console.log('default navigation on not finding stage');
            dispatch(resetStateAction());
            navigate('/');
        }
      }
    };
    useEffect(() => {
      const fetchData = async () => {
        const mobile = userLogin.mobileInput;
        const payload = {
          mobile: mobile
        };
        try {
          const response = await dkycApiWithLoader('/getKycJourney', payload, 'multipart/form-data');
          if (response.success === true) {
            if (response?.data != null) {
              const dynamicStage = response?.data[0]?.step;
              const isMobileVerified = response?.data[0]?.verify_mobile_status;
              const isEmailVerified = response?.data[0]?.verify_email_status;
              const penny_drop_status = response?.data[0]?.penny_drop_status;
              const pan = response?.data[0]?.pan;
              const digioImageStatus = response?.data[0]?.digio_image_status;
              if (dynamicStage != staticS) {
                if (penny_drop_status == 2 && dynamicStage == 8) {
                }
                // else if(isKraApproved == true && dynamicStage == 4) {
                // }
                else if (isKraApproved == true && dynamicStage == 5) {
                }
                else {
                  ResumeJourney(dynamicStage, isMobileVerified, isEmailVerified, pan, digioImageStatus);
                }
              } else {
                if (dynamicStage == '11') {
                  dispatch(digioImageStatusAction(digioImageStatus))
                  dispatch(changeStep1PanInputAction(pan));
                  navigate('/income-proof');
                }
              }
            } else {
              // alert("Error while fetching your data. Please try again later.");
              dispatch(resetStateAction());
              navigate('/');
            }
          } else {
            // alert("Error while fetching your data. Please try again later.");
            dispatch(resetStateAction());
            navigate('/');
          }
        } catch (err) {
          // alert("Error while fetching your data. Please try again later.");
          dispatch(resetStateAction());
          navigate('/');
          // window.location.href = "https://bigul.co/";
        }
      };
      fetchData();
    }, [staticS, userLogin.mobileInput]);


    return ResumeJourney;
  };
