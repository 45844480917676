export const setOccupation = "SET_OCCUPATION";
export const setOccupationDescription = "SET_OCCUPATION_DESCRIPTION";
export const setEducation = "SET_EDUCATION";
export const setIncome = "SET_INCOME";
export const setIncomeDescription = "SET_INCON_DESCRIPTION";
export const setExperience = "SET_EXPERIENCE";

export const setOccupationAction = (occupation) => ({
    type: setOccupation,
    payload: {
      occupation: occupation,
    },
  });
  export const setOccupationDescriptionAction = (OccupationDesc) => ({
    type: setOccupationDescription,
    payload: {
      OccupationDesc: OccupationDesc,
    },
  });
  export const setEducationAction = (education) => ({
    type: setEducation,
    payload: {
      education: education,
    },
  });
  export const setIncomeAction = (income) => ({
    type: setIncome,
    payload: {
      income: income,
    },
  });
  export const setIncomeDescriptionAction = (incomeDesc) => ({
    type: setIncomeDescription,
    payload: {
      incomeDesc: incomeDesc,
    },
  });
  
  export const setExperienceAction = (experience) => ({
    type: setExperience,
    payload: {
      experience: experience,
    },
  });

