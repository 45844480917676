export const changeStep1Input = 'CHANGE_STEP1_INPUT';
export const changeStep1Status = 'CHANGE_STEP1_STATUS';
export const changeStep1Error = 'CHANGE_STEP1_ERROR';
export const sendMobileOtp = 'SEND_MOBILE_OTP';
export const verifyMobileOtp = 'VERIFY_MOBILE_OTP';
export const changeEmailInput = 'CHANGE_EMAIL_INPUT';
export const current_stage = 'CURRENT_STAGE';
export const setMobileTimer = 'SET_MOBILE_TIMER';
export const alreadyExistData = "ALREADY_EXIST_DATA";

export const AlreadyExistDataAction = (payload) => ({
	type: alreadyExistData,
	payload
});
export const changeStep1InputAction = (payload) => ({
	type: changeStep1Input,
	payload
});

export const changeStep1StatusAction = (payload) => ({
	type: changeStep1Status,
	payload
});
export const changeEmailInputAction = (payload) =>({
	type : changeEmailInput,
	payload
});
export const currentStageAction = (payload) =>({
	type : current_stage,
	payload
})
export const setMobileTimerAction = (payload) =>({
	type : setMobileTimer,
	payload
})