import React, { useEffect, useState } from 'react';
import './roadMap.css'
import { dkycApi } from '../../ApiServices/common';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import Roadmapcelebration from './Roadmapcelebration';

export const RoadMap = ({ response }) => {
  console.log(response, 'dgsdgsgsg');
  const [loading, setLoading] = useState(true);
  const [AadhaarESignStatus, setAadharEsignStatus] = useState(0);
  const [verficationStatus, setVerificationStatus] = useState(0);
  const [rejectionStatus, setRejectionStatus] = useState(0);
  const [sendToLdStatus, setSendToLdStatus] = useState(0);
  const [readyTOTradeStatus, setReadyToTradeStatus] = useState(0);
  const [name, setName] = useState('');
  const [pan, setPan] = useState('');
  const [mobile, setMobile] = useState('');
  const [clientCode, setClientCode] = useState('');
  const [currentStep, setCurrentStep] = useState('');
  const [channel, setChannel] = useState('');
  const [esignDate, setEsignDate] = useState('');
  const [journeyData, setJourneyData] = useState('');
  let verificationClass;
  if (verficationStatus) {
    verificationClass = 'completed';
  } else if (rejectionStatus) {
    verificationClass = 'rejected';
  }
  else {
    verificationClass = 'pending';
  }
  let verificationIconClass;
  if (verficationStatus) {
    verificationIconClass = 's-verification';
  } else if (rejectionStatus) {
    verificationIconClass = 'cross';
  }
  else {
    verificationIconClass = 'grey-verification';
  }

  let lastStep;
  if (readyTOTradeStatus) {
    lastStep = 'readytotrade';
  } else if (sendToLdStatus) {
    lastStep = 'sendtold';
  } else if (verficationStatus == '1') {
    lastStep = 'verification';
  } else if (rejectionStatus == '1') {
    lastStep = 'rejection';
  }
  else if (AadhaarESignStatus) {
    lastStep = 'esign';
  } else {
    lastStep = 'begin'
  }

  useEffect(() => {
    if (response === null) {
      // Response is actually null
      setLoading(false); // Update loading state
    } else {
      console.log(response, 'response');
      setJourneyData(response?.data[0] ? '1' : null)
      setClientCode(response?.data[0]?.LDCode);
      setMobile(response?.data[0]?.MobileNumber);
      setName(response?.data[0]?.FName);
      setAadharEsignStatus(response?.data[0]?.IsCompleted);
      setVerificationStatus(response?.data[0]?.final_verify);
      setRejectionStatus(response?.data[0]?.is_reject);
      setSendToLdStatus(response?.data[0]?.IsSendToLD);
      setReadyToTradeStatus(response?.data[0]?.ready_to_trade);
      setPan(response?.data[0]?.PanNo);
      setChannel((response?.data[0]) ? (response?.data[0]?.IsETrade == "90" ? "Bigul" : "Bonanza") : "");
      setEsignDate(response?.data[0]?.esign_date);
      setLoading(false);
    }
  }, [response])
  return (
    response == null ? (
      <></>
    ) :
      <>
        <div className="kyc-journey">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 order-md-last">
                <div className="road-map">
                  <Roadmapcelebration readyTOTradeStatus={readyTOTradeStatus} />
                  <div className="top-text">
                    <div className="left">
                      <p>
                        Name : <span>{name}</span>
                      </p>
                      <p>
                        Mobile No. : <span>{mobile}</span>
                      </p>
                    </div>
                    <div className="right">

                      <p>
                        Client Code : <span>{clientCode}</span>
                      </p>
                      {/* <p>
                      Current Step : <span>{currentStep}</span>
                    </p> */}
                      <p>
                        Channel : <span>{channel}</span>
                      </p>
                    </div>
                  </div>
                  <div className="road">
                    <div className={`item top-heading ${journeyData ? 'completed' : 'pending'}`}>
                      {" "}
                      {/* Add completed class here*/}
                      <div className="heading">
                        <p>KYC Journey</p>
                      </div>
                    </div>
                    <div className={`item first ${AadhaarESignStatus ? 'completed' : 'pending'}`}>

                      {/* Add completed or pending or reject class here*/}
                      <div className="dots" />
                      <div className="point">
                        <div className="icon">
                          <img src={`img/${AadhaarESignStatus ? "user" : "grey-user"}.svg`} />
                        </div>
                        <div>
                          <p>Aadhar E-sign</p>
                          <span>Completed {esignDate}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`item second  ${verificationClass}`} >
                      <div className="dots" />
                      <div className="point right">
                        <div>
                          <p>Verification</p>
                          <span>{`${verificationClass == 'rejected' ? "Rejected" : "Completed"}`}</span>
                        </div>
                        <div className="icon">
                          <img src={`img/${verificationIconClass}.svg`} />
                        </div>
                      </div>
                    </div>
                    <div className={`item third ${sendToLdStatus ? 'completed' : 'pending'}`} >
                      <div className="dots" />
                      <div className="point">
                        <div className="icon">
                          <img src={`img/${sendToLdStatus ? "send" : "grey-send"}.svg`} />
                        </div>
                        <div>
                          <p>Send to LD</p>
                          <span>Completed</span>
                        </div>
                      </div>
                    </div>
                    <div className={`item btn-item ${readyTOTradeStatus ? 'completed' : 'pending'}`}>
                      {/* when all step will be completed add completed class here also*/}
                      <a href="#" className="ready-btn">
                        {/* <img src="img/send-icon.png"/> */}
                        Ready to Trade
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 left-col">
                <div className="left-part">
                  <div className="image">
                    <img src={`img/${lastStep}.svg`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>

  )
}
