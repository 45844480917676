export const buttonProcessing = 'BUTTON_PROCESSING';
export const buttonContinue = 'BUTTON_CONTINUE';

export const buttonProcessingAction = (payload) => ({
	type: buttonProcessing,
	payload
});
export const buttonContinueAction = (payload) => ({
	type: buttonContinue,
	payload
});