import {
    setEducation,
    setExperience,
    setIncome,
    setIncomeDescription,
    setOccupation,
    setOccupationDescription
} from "./../../actions/kyc/step4.action";

const initial_state = {
    occupation: "",
    occupationDesc: "",
    income: "",
    incomeDesc: '',
    education: "",
    experience: ""
}
export const kycStep4Reducer = (state = initial_state, action) => {
    switch (action.type) {
        case setOccupation:
            return {
                ...state,
                occupation: action.payload.occupation,
            };
        case setOccupationDescription:
            return {
                ...state,
                occupationDesc: action.payload.OccupationDesc
            }
        case setIncome:
            return {
                ...state,
                income: action.payload.income,
            };  
        case setIncomeDescription:
            return {
                ...state,
                incomeDesc: action.payload.incomeDesc
            }
        case setExperience:
            return {
                ...state,
                experience: action.payload.experience,
            };
        case setEducation:
            return {
                ...state,
                education: action.payload.education,
            };
        default:
            return state;
    }
}