export const isSendDigioLockerLinkAction = 'IS_SEND_DIGIOLOCKER_LINK';
export const setDigioLokerData = 'SET_DIGIOLOCKER_DATA';
export const callSendDigilockerLink = 'SET_DIGILOCKER_LINK';
export const setCity = "SET_CITY";
export const setState = "SET_STATE";
export const setAadhar = "SET_AADHAR";
export const setPincode = "SET_PINCODE";



export const callSendDigilockerAction = (payload, callback) => ({
	type: callSendDigilockerLink,
	payload,
	callback
});

export const setIsSendDigioLockerLinkAction = (payload) => ({
	type: isSendDigioLockerLinkAction,
	payload
});

export const setDigioLokerDataAction = (payload) => ({
	type: setDigioLokerData,
	payload
});

export const setCityAction = (payload) => ({
	type: setCity,
	payload
});
export const setStateAction = (payload) => ({
	type: setState,
	payload
});
export const setAadharAction = (payload) => ({
	type: setAadhar,
	payload
});
export const setPincodeAction = (payload) => ({
	type: setPincode,
	payload
});