import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { getBankAccountNumberAction, getBankNameAction } from '../../../redux/actions/kyc/step5.action';
import { fetchBankNameData } from '../../../ApiServices/common';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';
import StepsHeader from '../../Utils/StepsHeader';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import ReactInputMask from 'react-input-mask';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';


function FindBankDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ErrorMsg, setErrorMsg] = useState('');
  const [IfscCode, setIfscCode] = useState('');
  const [isValidIfscCode, setIsValidIfscCode] = useState(false);
  const kycStep5 = useSelector(state => state.ekycStep5);

  //resume journey
  const initalStageforBankDetails = '8';
  // useResumeJourneyPerStage(initalStageforBankDetails);
  useCheckRequiredJourneyData(initalStageforBankDetails);

  const handleInputChange = (e) => {
    setErrorMsg('')
    const ifsc = e.target.value;
    const formattedIfsc = ifsc.toUpperCase().replace(/[^A-Z0-9]/g, '');
    const isValidIfsc = validateIFSC(formattedIfsc);
    if (isValidIfsc) {
      setIsValidIfscCode(true);
      setErrorMsg('');
    }
    else {
      if (ifsc.length == 0) { setErrorMsg('') } else { setErrorMsg('Please Enter a Valid IFSC Code') }
      setIsValidIfscCode(false);
    };
    setIfscCode(formattedIfsc);
  };

  const validateIFSC = (code) => {
    const pattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return pattern.test(code);
  };

  const fetchData = async () => {
    setErrorMsg('');
    dispatch(getBankAccountNumberAction(''));
    try {
      if (IfscCode.startsWith('PYTM')) {
        setErrorMsg('We no longer accept Paytm Payments Bank Ltd (PPBL) accounts. Please provide details from another bank.');
        return;
      } 
      if (isValidIfscCode) {
        const response = await fetchBankNameData(IfscCode);
        dispatch(getBankNameAction(response.data));
        navigate('/verify-bank-details');
      }
    } catch (error) {
      setErrorMsg('IFSC not found. Please Enter Correct IFSC');
    }
  };
  return (<>
    <section className="main-wraper progress-inner">
      {/* <form onSubmit={handleSubmit}> */}
      <StepsHeader step={4} />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/find-bank.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1">Let’s now find your Bank </h1>
            </div>
            <div className="mw-400 block-center">
              <p className="para-1">
                Enter your IFSC and we’ll find your bank branch.
              </p>
            </div>
            <div className="input-box mt-5">
              <ReactInputMask
              //  mask="aaaa9999999" maskChar={null}
                type="text"
                autoFocus
                maxLength="11"
                className="input-form text-center mb-3"
                placeholder="Enter your Bank’s IFSC Code"
                value={IfscCode}
                pattern="[A-Za-z0-9]*"
                onChange={handleInputChange}
              />
            </div>
            <div className='input-error-msg text-center' style={{fontSize : '14px', marginTop : '5px'}}>{ErrorMsg}</div>
          </div>
          <div className="btn-action-box">
            <button type="submit" className={`btn btn-${isValidIfscCode ? 'primary' : 'secondary'} btn-full mb-2`} onClick={fetchData} disabled={!isValidIfscCode}>
              Search for your Bank
            </button>
          </div>
        </div>
      </div>
      {/* </form> */}
    </section>

  </>)


}
export default FindBankDetails;