import { setImageUrl, digioImageStatus,selfieTimeStamp } from '../../actions/kyc/step6.action';

const mutableState = {
	selfieImage: "",
	digioImageStatus: '',
	selfieTimeStamp: ''
};

const initState = {
	...mutableState
};

export const kycStep6Reducer = (state = initState, action) => {

	switch (action.type) {
		case setImageUrl:
			return {
				...state,
				selfieImage: action.payload.imageUrl
			};
		case digioImageStatus:
			return {
				...state,
				digioImageStatus: action.payload.digioImageStatus
			};
		case selfieTimeStamp:
			return {
				...state,
				selfieTimeStamp: action.payload
			}
		default:
			return {
				state
			};
	}
};
