import { userLoginReducer } from './kyc/userlogin.reducer';
import { combineReducers } from 'redux';
import { modalToggleReducer } from './modal.reducer'
import { kycStep1Reducer } from './kyc/step1.reducer';
import { kycStep2Reducer } from './kyc/step2.reducer';
import { kycStep3Reducer } from './kyc/step3.reducer';
import { kycStep4Reducer } from './kyc/step4.reducer';
import { kycStep5Reducer } from './kyc/step5.reducer';
import { kycStep6Reducer } from './kyc/step6.reducer';
import { kycStep8Reducer } from './kyc/step8.reducer';
import { appReducer } from './app.reducer';
import { ErrorReducer } from './error.reducer';
import { RESET_STATE } from '../actions/reset.action'; 
import { buttonDescReducer } from './buttonDesc.reducer';


const webReducer = combineReducers({ 
    app: appReducer,
    userLogin: userLoginReducer,
    ekycStep1: kycStep1Reducer,
    ekycStep2: kycStep2Reducer,
    ekycStep3: kycStep3Reducer,
    ekycStep4: kycStep4Reducer,
    ekycStep5: kycStep5Reducer,
    ekycStep6: kycStep6Reducer,
    // ekycStep8: kycStep8Reducer,
    modalToggle: modalToggleReducer,
    error: ErrorReducer,
    buttonDesc : buttonDescReducer


})
const rootReducer = (state, action) => {
    if (action.type === RESET_STATE) {
        // for all keys defined in your persistConfig(s)
        sessionStorage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')

        return webReducer(undefined, action);
    }
    return webReducer(state, action);
};
export default rootReducer;
 