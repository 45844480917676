import { getBankName, getBankAccountNumber, getConfirmAccountNumber, getaccountHolderName } from "../../actions/kyc/step5.action";

const initState = {
    bankName: '',
    bankBranchName: '',
    ifscCode: '',
    bankAccountNumber: '',
    confirmAccountNumber: '',
    accountHolderName: ''

}

export const kycStep5Reducer = (state = initState, action) => {
    switch (action.type) {
        case getBankAccountNumber:
            return {
                ...state,
                bankAccountNumber: action.payload
            }
        case getBankName:
            return {
                ...state,
                bankBranchName:
                    action.payload.BRANCH,
                bankName: action.payload.BANK,
                ifscCode: action.payload.IFSC
            }
        case getConfirmAccountNumber:
            return {
                ...state,
                confirmAccountNumber: action.payload
            }
        case getaccountHolderName:
            return {
                ...state,
                accountHolderName: action.payload

            }
        default:
            return state;
    }
};