import { DocumentFetchModalClose, DocumentFetchModalOpen, setAlreadyRegisteredModalOpen, setEmailOtpSentModalClose, setEmailOtpSentModalOpen, setEmailVerifyModalClose, setEmailVerifyModalOpen , setOtpSentModalOpen,setOtpSentModalClose,setModalClose, setPennyDropSuccessModalOpen, setPennyDropSuccessModalClose, setBankVerifiedModalOpen, setBankVerifiedModalClose, setBankNotVerifiedModalClose, setBankNotVerifiedModalOpen } from "../actions/Modal";
const initialState = {
    isRegistered: false,
    otpSent: false,
    emailOtpSent: false,
    emailVerify: false,
    documentFeched: false,
    pennyDrop: false,
    bankVerified: false,
    bankNotVerified: false
};
export const modalToggleReducer = (state = initialState, action) => {
    switch (action.type) {
        case setAlreadyRegisteredModalOpen:
            return {
                ...state,
                isRegistered: action.payload,
            }
        case setModalClose:
            return {
                ...state,
                isRegistered: false,
            }
        case setOtpSentModalOpen:
            return {
                ...state,
                otpSent: action.payload,
            }
        case setOtpSentModalClose:
            return {
                ...state,
                otpSent: false,
            }
        case setEmailOtpSentModalOpen:
            return {
                ...state,
                emailOtpSent: true,
            }
        case setEmailOtpSentModalClose:
            return {
                ...state,
                emailOtpSent: false,
            }
        case setEmailVerifyModalOpen:
            return {
                ...state,
                emailVerify: true,
            }
        case setEmailVerifyModalClose:
            return {
                ...state,
                emailVerify: false,
            }
        case setPennyDropSuccessModalOpen:
            return {
                ...state,
                pennyDrop: true,

            }
        case setPennyDropSuccessModalClose:
            return {
                ...state,
                pennyDrop: false,

            }
        case setBankVerifiedModalOpen:
            return {
                ...state,
                bankVerified: true
            }
        case setBankVerifiedModalClose:
            return {
                ...state,
                bankVerified: false
            }
        case setBankNotVerifiedModalOpen:
            return {
                ...state,
                bankNotVerified: true
            }
        case setBankNotVerifiedModalClose:
            return {
                ...state,
                bankNotVerified: false
            }
        case DocumentFetchModalOpen:
            return {
                ...state,
                documentFeched: true
            }
        case DocumentFetchModalClose:
            return {
                ...state,
                documentFeched: false
            }
        default:
            return state;

    }
}