export const setImageUrl = 'SET_SELFIE_IMAGE_URL';
export const  digioImageStatus = 'digio_image_status';
export const selfieTimeStamp = 'selfie_time_stamp';



export const setSelfieImageUrlAction = (url) => ({
	type: setImageUrl,
	payload:{
        imageUrl:url
    },
});
export const digioImageStatusAction=(payload)=>({
    type : digioImageStatus,
    payload 
})
export const SelfieTimeStampAction=(payload)=>({
    type : selfieTimeStamp,
    payload 
})

