import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dkycApiWithLoader } from '../../ApiServices/common';
import { useNavigate } from 'react-router-dom'; // Import the 'useNavigate' hook from your routing library
import { resetStateAction } from '../../redux/actions/reset.action';

export const
  useCheckRequiredJourneyData = (staticState) => {
    const dispatch = useDispatch()
    const staticS = staticState;
    const userLogin = useSelector((state) => state?.userLogin || {});
    const kycStep1 = useSelector(state => state?.ekycStep1 || {});
    const navigate = useNavigate();
    const mobile = userLogin.mobileInput;
    const pan = kycStep1.panInput.value;
    const kycStep3 = useSelector(state => state?.ekycStep3 || {});
    const isKraApproved = kycStep3?.isKraApproved;
    const ResumeJourney = (stage, isMobileVerified, isEmailVerified) => {
      if (isMobileVerified == 0 && isEmailVerified == 0) {
        navigate('/');
      }
      else if (isMobileVerified == 0 && isEmailVerified == 1) {
        navigate('/');
      }
      else if (isMobileVerified == 1 && isEmailVerified == 0) {
        navigate('/email-input');
      }
      else if (isMobileVerified == 1 && isEmailVerified == 1) {
        switch (stage) {
          case '1':
            navigate('/');
            break;
          case '2':
            navigate('/email-input');
            break;
          case '3':
            navigate('/email-input');
            break;
          case '4':
            navigate('/pan-verification');
            break;
          case '5':
            navigate('/pan-verification');
            break;
          case '6':
            navigate('/personal-details-1');
            break;
          case '7':
            navigate('/occupation-details');
            break;
          case '8':
            navigate('/find-bank-details');
            break;
          case '9':
            navigate('/segment-details');
            break;
          case '10':
            navigate('/bank-proof');
            break;
          case '11':
            navigate('/income-proof');
            break;
          case '12':
            navigate('/selfie');
            break;
          case '13':
            navigate('/draw-sign');
            break;
          case '14':
            navigate('/e-sign');
            break;
          case '15':
            navigate('/welcome-page')
            break;
          case '16':
            navigate('/thanks-page');
            break;
          default:
            console.log('default navigation on not finding stage', stage);
            alert(stage);
            navigate('/')
        }
      }
    };
    useEffect(() => {
      const fetchData = async () => {
        const payload = {
          mobile: mobile,
          pan_no: pan
        };
        try {
          const response = await dkycApiWithLoader('/checkRequiredJourneyData', payload, 'multipart/form-data');
          if (response.success === true) {
            if (response?.data != null) {
              const dynamicStage = String(response?.data?.step);
              const isMobileVerified = response?.data?.verify_mobile_status;
              const isEmailVerified = response?.data?.verify_email_status;
              if (dynamicStage != staticS) {
                if (isKraApproved == true && dynamicStage == 5) { }
                else {
                  ResumeJourney(dynamicStage, isMobileVerified, isEmailVerified);
                }
              } else { }

            }
          } else {
            if (response.message.mobile[0] == "The mobile field is required.") {
              dispatch(resetStateAction());
              navigate('/');
            }

          }
        }
        catch (err) {
          // alert("Error while fetching your data. Please try again later.");
          // dispatch(resetStateAction());
          // navigate('/');
          // window.location.href = "https://bigul.co/";
        }
      };
      fetchData();
    }, [staticS, userLogin.mobileInput]);


    return ResumeJourney;
  };
