export const changeStep1PanInput = 'CHANGE_STEP1_PAN_INPUT';
export const changeStep1DobInput = 'CHANGE_STEP1_DOB_INPUT';
//please remove saga
export const getPanCardDetailSagas = 'GET_PAN_CARD_DETAIL_SAGAS';
export const getPanCardDetail = 'GET_PAN_CARD_DETAIL';
export const setPanCardDetail = 'SET_PAN_CARD_DETAIL';
export const setPanError = 'SET_PAN_ERROR';
export const setDobError = 'SET_DOB_ERROR';
export const setKRA = 'SET_KRA';

export const changeStep1PanInputAction = (payload) => ({
	type: changeStep1PanInput,
	payload
});
export const changeStep1DobInputAction = (payload) => ({
	type: changeStep1DobInput,
	payload
});
export const setPanErrorAction = (payload) => ({
	type: setPanError,
	payload
});
export const setDobErrorAction = (payload) => ({
	type: setDobError,
	payload
})
export const getPanCardDetailAction = (payload, callback) => ({
	type: getPanCardDetail,
	payload,
	// callback
});

export const setPanCardDetailAction = (payload) => ({
	type: setPanCardDetail,
	payload
});

export const setKRAAction = (payload) => ({
	type: setKRA,
	payload
});
