import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from "react-router-dom"
import StepsHeader from '../../Utils/StepsHeader';
import { setSelfieImageUrlAction } from '../../../redux/actions/kyc/step6.action';
import { commonPostApi, commonPostApiWithoutLoader, dkycApiHavingLoader } from '../../../ApiServices/common';

import AsideWallpaper from '../../Utils/AsideWallpaper';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

function YourComponent() {
  const [errorMsg, setErrorMsg] = useState('');
  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const [pan, setPan] = useState(kycStep1.panInput.value);
  console.log(pan, 'paaan')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(state => state?.userLogin || {});
  const kycStep6 = useSelector(state => state?.ekycStep6 || {});
  const [selfieImageDisplay, setSelfieImageDisplay] = useState(kycStep6?.selfieImage);
  const [enableButton, setenableButton] = useState(false);
  const [enableLocation, setEnableLocation] = useState(false);


  useEffect(() => {
    commonPostApiWithoutLoader('/get-saved-kra-data', { mobile: userData?.mobileInput }, null)
      .then((response) => {
        if (response.status == 200) {
          setenableButton(true)
          setPan(response?.pan_no);
          setKRAStatus(response?.app_status || '');
          const data = response?.json;
          // const data = JSON.parse(json_data)
          console.log(data, 'data');
          console.log(data.KYC_DATA, 'kycdata');
          setPanFromKra(Object.keys(data?.KYC_DATA?.APP_PAN_NO).length === 0 ? 'NA' : data?.KYC_DATA?.APP_PAN_NO);
          setPerAddressProof(Object.keys(data?.KYC_DATA?.APP_PER_ADD_PROOF).length === 0 ? 'NA' : data?.KYC_DATA?.APP_PER_ADD_PROOF);
          setCorAddressProof(Object.keys(data?.KYC_DATA?.APP_COR_ADD_PROOF).length === 0 ? 'NA' : data?.KYC_DATA?.APP_COR_ADD_PROOF);
          setPerAddressCode1(Object.keys(data?.KYC_DATA?.APP_PER_ADD1).length === 0 ? '' : data?.KYC_DATA?.APP_PER_ADD1);
          setPerAddressCode2(Object.keys(data?.KYC_DATA?.APP_PER_ADD2).length === 0 ? '' : data?.KYC_DATA?.APP_PER_ADD2);
          setCerAddressCode1(Object.keys(data?.KYC_DATA?.APP_COR_ADD1).length === 0 ? '' : data?.KYC_DATA?.APP_COR_ADD1);
          setCerAddressCode2(Object.keys(data?.KYC_DATA?.APP_COR_ADD2).length === 0 ? '' : data?.KYC_DATA?.APP_COR_ADD2);
          setPerAddressType(data?.KYC_DATA?.APP_PER_ADD_REF === 'NA' || data?.KYC_DATA?.APP_PER_ADD_REF === '' || Object.keys(data?.KYC_DATA?.APP_PER_ADD_REF).length === 0 ? 'NA' : ` ${data?.KYC_DATA?.APP_PER_ADD_REF}`);
          setCorAddressType(data?.KYC_DATA?.APP_COR_ADD_REF === 'NA' || data?.KYC_DATA?.APP_COR_ADD_REF === '' || Object.keys(data?.KYC_DATA?.APP_COR_ADD_REF).length === 0 ? 'NA' : ` ${data?.KYC_DATA?.APP_COR_ADD_REF}`);
          setUniqueKey(data?.APP_PAN_SUMM?.APP_OTHKRA_BATCH || 'NA');
          // setPanHolderFullName(data.pennydrop_data.)
          setPennyHolderName(response?.pennydrop_data?.beneficiary_name_with_bank);
          setPennyVerificationTime(response.pennydrop_data?.verified_at);
          setPennyId(response.pennydrop_data?.id);
          setPennyVerified(response.pennydrop_data?.verified && 'True');
          // console.log(uniqueKey?uniqueKey:'uuuuuuu','uniqueKey');
          // data.key1 && Object.keys(data.key1).length === 0
          // setUniqueKey(response?.app_no && Object.keys(response?.app_no.key1).length === 0 ?  '' : response?.app_no );
        }
      }).catch((err) => { setErrorMsg(err); })
  }, [])

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }
  const onChangeHandlerNext = async () => {
    setErrorMsg('');
    try {
      const imageFile = dataURLtoFile(kycStep6.selfieImage, "selfieImage")
      if (!imageFile) {
        onChangeHandlerBack();
      }
      const formData = new FormData();
      formData.append("mobile", userData?.mobileInput);
      formData.append("selfie_data", imageFile);
      formData.append("stage", '13');
      if (divRefKRA.current) {
        htmlToImage.toBlob(divRefKRA.current)
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            if (dataUrl) {
              // Create a FormData object to prepare for uploading
              const data = new FormData();
              // Append other fields to the FormData
              data.append('Document', 'Other');
              data.append('DocumentType', 'NA');
              data.append('CreatedBy', '2261');
              data.append('PanNo', pan);
              data.append('step', '12');
              data.append('DocumentImages[]', dataUrl, 'image.jpg');
              // You can change the filename here
              // Example: Upload the image using the fetch API
              dkycApiHavingLoader('/uploadfiles', data, 'multipart/form-data')
                .then((response) => {
                  if (response?.success === true) {
                  }
                  else {
                    setErrorMsg(response?.msg ? response?.msg : 'Some Error Has Occured');
                    return;
                  }
                }).catch((err) => {
                  console.log('penny details are not uploaded for the customer');
                  setErrorMsg(err);
                })
            }
            console.log(dataUrl ,'img');
            return;
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
        html2canvas(divRefKRA.current).then((canvas) => {
          // Convert the captured canvas to a data URL
          const imgData = canvas.toDataURL('image/png');
          console.log(imgData);
          // Now you can upload imgData to your server or do something else with it
          canvas.toBlob((blob) => {
            console.log(blob, 'bb');
            // if (blob) {
            //   // Create a FormData object to prepare for uploading
            //   const data = new FormData();
            //   // Append other fields to the FormData
            //   data.append('Document', 'Other');
            //   data.append('DocumentType', 'NA');
            //   data.append('CreatedBy', '2261');
            //   data.append('PanNo', pan);
            //   data.append('step', '12');
            //   data.append('DocumentImages[]', blob, 'image.jpg');
            //   // You can change the filename here
            //   // Example: Upload the image using the fetch API
            //   dkycApiHavingLoader('/uploadfiles', data, 'multipart/form-data')
            //     .then((response) => {
            //       if (response?.success === true) {
            //       }
            //       else {
            //         setErrorMsg(response?.msg ? response?.msg : 'Some Error Has Occured');
            //         return;
            //       }
            //     }).catch((err) => {
            //       console.log('penny details are not uploaded for the customer');
            //       setErrorMsg(err);
            //     })
            // }
          }, 'image/png', 0.7); // Specify the desired image format ('image/jpeg' for JPG, 'image/png' for PNG)
        });
      }
      // const response = await commonPostApi('/capture-selfie-web', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      // if (response?.status === 200) {
      //   navigationStage(response?.user_data?.stage);
      // } else {
      //   setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
      // }

    }
    catch (error) {
      setErrorMsg('Something went wrong!!');
    }
  }
  const onChangeHandlerBack = () => {
    dispatch(setSelfieImageUrlAction(""))
    navigate('/selfie');
  }
  const dataURLtoFile = (dataUrl, filename) => {
    try {

      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } catch (error) {
      console.log("something went wrong...please refresh page and try again");
    }
  }
  //for penny drop status update
  const [pennyVerified, setPennyVerified] = useState('false');
  const [pennyId, setPennyId] = useState('--');
  const [verificationTime, setPennyVerificationTime] = useState('--');
  const [pennyHolderName, setPennyHolderName] = useState('--');
  const [panHolderFullName, setPanHolderFullName] = useState(kycStep1?.userData?.fullName);
 

  //for kra page
  const divRefKRA = useRef(null);
  const [kraStatus, setKRAStatus] = useState('');
  const [uniqueKey, setUniqueKey] = useState('');
  const [perAddressProof, setPerAddressProof] = useState('');
  const [corAddressProof, setCorAddressProof] = useState('');
  const [perAddress, setPerAddress] = useState('');
  const [corAddress, setCorAddress] = useState('');
  const [panFromKra, setPanFromKra] = useState('');
  const [perAddressCode1, setPerAddressCode1] = useState('');
  const [perAddressCode2, setPerAddressCode2] = useState('');
  const [corAddressCode1, setCerAddressCode1] = useState('');
  const [corAddressCode2, setCerAddressCode2] = useState('');
  const [perAddressType, setPerAddressType] = useState('');
  const [corAddressType, setCorAddressType] = useState('');
  const [kraLatitude, setKRALatitude] = useState('');
  const [kraLongitude, setKRALongitude] = useState('');

  //geo location tagging
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          const { latitude, longitude } = position.coords;
          setKRALatitude(latitude);
          setKRALongitude(longitude);
          setEnableLocation(true);
        },
        (error) => {
          setEnableLocation(false);
          setErrorMsg('Please allow location access to continue');
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }
  }, []);


  const addressProofList = [
    { code: "01", description: "Passport" },
    { code: "02", description: "Driving License" },
    { code: "03", description: "Latest Bank Passbook" },
    { code: "04", description: "Latest Bank Account Statement" },
    { code: "06", description: "Voter Identity Card" },
    { code: "07", description: "Ration Card" },
    { code: "08", description: "Registered Lease / Sale Agreement of Residence" },
    { code: "09", description: "Latest Land Line Telephone Bill" },
    { code: "10", description: "Latest Electricity Bill" },
    { code: "11", description: "Gas Bill" },
    { code: "13", description: "Flat Maintenance Bill" },
    { code: "14", description: "Insurance copy" },
    { code: "15", description: "Self Declaration by High Court / Supreme Court Judge" },
    { code: "16", description: "Power of Attorney given by FII/sub-account to the Custodians (which are duly notarized and/or apostiled or consularized) giving registered address." },
    { code: "17", description: "Proof of Address issued by Scheduled Commercial Banks / Scheduled Co-operative Banks / Multinational Foreign banks." },
    { code: "18", description: "Proof of Address issued by Elected representatives to the Legislative Assembly" },
    { code: "19", description: "Proof of Address issued by Parliament" },
    { code: "20", description: "Proof of Address issued by any Government / Statutory Authority" },
    { code: "21", description: "Proof of Address issued by Notary Public" },
    { code: "22", description: "Proof of Address issued by Gazetted Officer" },
    { code: "23", description: "ID Card with address issued by Central / State Government" },
    { code: "24", description: "ID Card with address issued by Statutory / Regulatory Authorities" },
    { code: "25", description: "ID Card with address issued by Public Sector Undertakings" },
    { code: "26", description: "ID Card with address issued by Scheduled Commercial Banks" },
    { code: "27", description: "ID Card with address issued by Public Financial Institutions" },
    { code: "28", description: "ID Card with address issued by Colleges affiliated to universities" },
    { code: "29", description: "ID Card issued by Professional Bodies such as ICAI, ICWAI, ICSI, Bar Council, etc. to their Members" },
    { code: "31", description: "AADHAAR" },
    { code: "33", description: "Nrega Job Card" },
    { code: "34", description: "Pension Or Family Pension Payment Orders (PPOs) Issued To Retired Employees By Government Departments Or Public Sector Undertakings, If They Contain The Address." }
  ];
  const findAddressProof = (code) => {
    const addressProof = addressProofList.find((proof) => String(proof.code) === String(code));
    if (addressProof) {
      return addressProof.description;
    }
    return 'Address Proof Not Found';
  };
  const allowLocationHandler = () => {
    window.location.reload();
  }

  return (<>
    <section className="main-wraper selfie-page">

      <div className="body-content-box">
        <div className="login-box">

          {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          {!enableLocation && <a onClick={allowLocationHandler} className="color-blue cursor-pointer">
            Click here to Refresh the page
          </a>}
          <div className="btn-action-box">
            <button className="btn btn-primary btn-full mb-2" onClick={onChangeHandlerNext} disabled={!(enableButton)}>
              <img
                src="./images/selfie-camera-icon-2.svg"
                alt="icon"
                className="mr-2"
              />
              Looks Good!
            </button>
          </div>
          <div ref={divRefKRA} style={{
            // display : 'none',
            // opacity: '1',
            // position: 'absolute',
            // left: '-9999px',
            width: '100%',
            fontSize: '12px',
            // zIndex : '-1'
          }}>
            <table className='w-100'>
              <tbody>
                <tr>
                  <td width="100%" style={{ padding: 15 }}>
                    <div
                      style={{
                        display: "flex",
                        margin: "5px 0px",
                        justifyContent: "space-between",
                        borderBottom: "2px solid blue",
                        paddingBottom: 5
                      }}>
                      <span>
                        <h4>KRA Info</h4>
                      </span>
                      <span />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>KRA-Verification Code -</span>
                      <span>
                        {kraStatus}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>KRA-BATCH Code -</span>
                      <span>
                        {uniqueKey}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>KRA-Permanent Address Proof Type-</span>
                      <span>
                        {findAddressProof(perAddressProof)}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>KRA-Permanent Address Proof -</span>
                      <span>
                        {perAddressType}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>KRA-Correspondence Address Proof Type -</span>
                      <span>
                        {findAddressProof(corAddressProof)}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>KRA-Correspondence Address Proof -</span>
                      <span>
                        {corAddressType}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Permanent Addresss - </span>
                      <span>
                        {perAddressCode1}  <br /> {perAddressCode2}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Correspondence Address - </span>
                      <span>
                        {corAddressCode1}  <br /> {corAddressCode2}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>PAN no -</span>
                      <span>
                        {panFromKra}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Latitude -</span>
                      <span>
                        {kraLatitude}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Longitude</span>
                      <span>
                        {kraLongitude}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="100%" style={{ padding: 15 }}>
                    <div
                      style={{
                        display: "flex",
                        margin: "5px 0px",
                        justifyContent: "space-between",
                        borderBottom: "2px solid blue",
                        paddingBottom: 5
                      }}
                    >
                      <span>
                        <h4>Penny Drop Info</h4>
                      </span>
                      <span />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Id -</span>
                      <span>
                        {pennyId}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Verified Status -</span>
                      <span>
                        <p>{pennyVerified}</p>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Date &amp; Time -</span>
                      <span>
                        {verificationTime}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Beneficiary Name With Bank -</span>
                      <span>
                        <b>
                          {pennyHolderName}
                        </b>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Pan-Card Full Name -</span>
                      <span>
                        {panHolderFullName}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default YourComponent;