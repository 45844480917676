import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from "react-router-dom"
import StepsHeader from '../../Utils/StepsHeader';
import { setSelfieImageUrlAction } from '../../../redux/actions/kyc/step6.action';
import { commonPostApi, commonPostApiWithoutLoader, commonPostAppApi, dkycApi, dkycApiHavingLoader } from '../../../ApiServices/common';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import html2canvas from 'html2canvas';
import { selfieTimeStamp } from './../../../redux/actions/kyc/step6.action';

function YourPicture() {
  const [errorMsg, setErrorMsg] = useState('');
  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const [pan, setPan] = useState(kycStep1.panInput.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(state => state?.userLogin || {});
  const kycStep6 = useSelector(state => state?.ekycStep6 || {});
  const kycStep3 = useSelector(state => state?.ekycStep3 || {});
  const [selfieImageDisplay, setSelfieImageDisplay] = useState(kycStep6?.selfieImage);
  const [selfieTimeStamp, setSelfieTimeStamp] = useState(kycStep6?.selfieTimeStamp);
  const [enableButton, setEnableButton] = useState(false);
  const [enableLocation, setEnableLocation] = useState(false);
  const [enablePennyDrop, setEnablePennyDrop] = useState(true);
  const [enableKRAdata, setEnableKRAdata] = useState(true);
  const [pennydropStatus, setPennyDropStatus] = useState(0);
  const [pennyDropData, setPennydropData] = useState(null);
  const isKraApproved = kycStep3?.isKraApproved;
  const userName = kycStep1?.userData?.fullName;

  //resume journey
  //giving erros because it is checking for stage 7 from ddpi 
  const initalStageforSelfie = '12';
  // useResumeJourneyPerStage(initalStageforSelfie);

  useEffect(() => {
    if (!kycStep6.selfieImage) { navigate('/selfie') }
  }, [])

  useEffect(() => {
    const payload = {
      mobile: userData?.mobileInput
    };
    dkycApi('/getKycJourney', payload, 'multipart/form-data')
      .then((response) => {
        if (response?.success == true) {
          if (response?.data[0]?.full_name == null) {
            setErrorMsg('The name of the PAN card holder is unavailable.');
            setEnableButton(false);
          }
          const kraStatus = response?.data[0]?.kra_status;
          if (kraStatus == "1") {
            setEnableKRAdata(false);
            setKRAStatus(response?.data[0]?.kra_code);
            const kraData = response?.data[0]?.kra_xml;
              commonPostApiWithoutLoader('/get-saved-kra-data', { mobile: userData?.mobileInput }, null)
                .then((response) => {
                  if (response.status == 200) {
                    setPan(response?.pan_no);
                    setKRAStatus(response?.app_status || '');
                    const data = response?.json;
                    setPanFromKra(Object.keys(data?.KYC_DATA?.APP_PAN_NO).length === 0 ? 'NA' : data?.KYC_DATA?.APP_PAN_NO);
                    setPerAddressProof(Object.keys(data?.KYC_DATA?.APP_PER_ADD_PROOF).length === 0 ? 'NA' : data?.KYC_DATA?.APP_PER_ADD_PROOF);
                    setCorAddressProof(Object.keys(data?.KYC_DATA?.APP_COR_ADD_PROOF).length === 0 ? 'NA' : data?.KYC_DATA?.APP_COR_ADD_PROOF);
                    setPerAddressCode1(Object.keys(data?.KYC_DATA?.APP_PER_ADD1).length === 0 ? '' : data?.KYC_DATA?.APP_PER_ADD1);
                    setPerAddressCode2(Object.keys(data?.KYC_DATA?.APP_PER_ADD2).length === 0 ? '' : data?.KYC_DATA?.APP_PER_ADD2);
                    setCerAddressCode1(Object.keys(data?.KYC_DATA?.APP_COR_ADD1).length === 0 ? '' : data?.KYC_DATA?.APP_COR_ADD1);
                    setCerAddressCode2(Object.keys(data?.KYC_DATA?.APP_COR_ADD2).length === 0 ? '' : data?.KYC_DATA?.APP_COR_ADD2);
                    setPerAddressType(data?.KYC_DATA?.APP_PER_ADD_REF === 'NA' || data?.KYC_DATA?.APP_PER_ADD_REF === '' || Object.keys(data?.KYC_DATA?.APP_PER_ADD_REF).length === 0 ? 'NA' : ` ${data?.KYC_DATA?.APP_PER_ADD_REF}`);
                    setCorAddressType(data?.KYC_DATA?.APP_COR_ADD_REF === 'NA' || data?.KYC_DATA?.APP_COR_ADD_REF === '' || Object.keys(data?.KYC_DATA?.APP_COR_ADD_REF).length === 0 ? 'NA' : ` ${data?.KYC_DATA?.APP_COR_ADD_REF}`);
                    setUniqueKey(Object.keys(data?.APP_PAN_SUMM?.APP_OTHKRA_BATCH).length === 0 ? 'NA' : data?.APP_PAN_SUMM?.APP_OTHKRA_BATCH);
                    setEnableButton(true);
                    setEnableKRAdata(true);
                  } else {
                    setErrorMsg('The KRA data is not available.')
                    setEnableButton(false);
                    setEnableKRAdata(false);
                  }
                }).catch((err) => {
                  setErrorMsg(err);
                  setEnableButton(false);
                })
              // setErrorMsg('The KRA data is not available.')
              // setEnableButton(false);
            }
          else if (kraStatus == "2") {
            //customer's json data for digio
            const jsonData = response?.data[0]?.json_data;
            if (jsonData) { setEnableButton(true); }
            else {
              setEnableButton(false);
              setErrorMsg('The Digilocker data is not available.')
            }
          }
          else {
            setErrorMsg('The selfie data is not available.');
            setEnableButton(false);
          }
          setPennyDropStatus(response?.data[0]?.penny_drop_status);
          setPennydropData(response?.data[0]?.pennydrop_data);
          if (response?.data[0]?.penny_drop_status == "1" && response?.data[0]?.pennydrop_data == null) {
            setErrorMsg('Some Error has Occured,Please try again!');
            setEnablePennyDrop(false);
          } else if (response?.data[0]?.penny_drop_status == "2") {
            const pennydropData = response?.data[0]?.pennydrop_data;
            const jsonObject = JSON.parse(pennydropData);
            if (jsonObject) {
              setPennyId(jsonObject?.id);
            }
            setPennyHolderName("");
            setPennyVerificationTime("");
            setPennyVerified("False");
            setEnablePennyDrop(true);
          }
          else {
            const pennydropData = response?.data[0]?.pennydrop_data;
            //if penny drop is not null 
            const jsonObject = JSON.parse(pennydropData);
            if (jsonObject)
              setPennyHolderName(jsonObject?.beneficiary_name_with_bank);
            setPennyVerificationTime(jsonObject?.verified_at);
            setPennyId(jsonObject?.id);
            setPennyVerified(jsonObject?.verified ? 'True' : "False");
            setEnablePennyDrop(true);
          }
        } else { setErrorMsg(response?.message ? response?.message : "Something went wrong!!") }
      }).catch((err) => {
        setErrorMsg(err);
      })

  }, [])

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }
  const onChangeHandlerNext = async () => {
    setErrorMsg('');
    const base64String = kycStep6.selfieImage.split(",")[1];
    const formData = new FormData();
    formData.append('image', base64String);
    formData.append('source', 'EKYC');
    commonPostApi('/processSelfie', formData)
      .then((response) => {
        if (response.status == 'completed') {
          if (response.result.face_detected) {
            if (response.result.is_live && (response.result.confidence > 70)) {
              if (response.result.are_eyes_open) {
                if (response.result.multiple_faces_detected == false) {
                  if (response.result.is_front_facing) {
                    if (response.result.is_face_cropped == false) {
                      if (response.result.face_coverage.status == "optimal") {
                        if (response.result.face_quality.status == "optimal") {
                          if (response.result.is_nsfw == false) {
                            try {
                              const imageFile = dataURLtoFile(kycStep6.selfieImage, "selfieImage")
                              if (!imageFile) {
                                onChangeHandlerBack();
                              }
                              const formData = new FormData();
                              formData.append("mobile", userData?.mobileInput);
                              formData.append("selfie_data", imageFile);
                              formData.append("stage", '13');
                              if (divRefKRA.current) {
                                html2canvas(divRefKRA.current)
                                  .then((canvas) => {
                                    // Convert the captured canvas to a data URL
                                    const imgData = canvas.toDataURL('image/png');
                                    console.log(imgData);
                                    // Now you can upload imgData to your server or do something else with it
                                    canvas.toBlob((blob) => {
                                      console.log(blob, 'bb');
                                      if (blob) {
                                        // Create a FormData object to prepare for uploading
                                        const data = new FormData();
                                        // Append other fields to the FormData
                                        data.append('Document', 'Other');
                                        data.append('DocumentType', 'NA');
                                        data.append('CreatedBy', '2261');
                                        data.append('PanNo', pan);
                                        data.append('step', '12');
                                        data.append('DocumentImages[]', blob, 'image.jpg');
                                        // You can change the filename here
                                        // Example: Upload the image using the fetch API
                                        dkycApiHavingLoader('/uploadfiles', data, 'multipart/form-data')
                                          .then((response) => {
                                            if (response?.success === true) {
                                              commonPostApi('/capture-selfie-web', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                                                .then((response) => {
                                                  if (response?.status === 200) {
                                                    navigationStage(response?.user_data?.stage);
                                                  } else {
                                                    setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
                                                  }
                                                }).catch((err) => { setErrorMsg('Some Error Has Occured') })
                                            }
                                            else {
                                              setErrorMsg(response?.msg ? response?.msg : 'Some Error Has Occured');
                                              return;
                                            }
                                          }).catch((err) => {
                                            console.log('penny details are not uploaded for the customer');
                                            setErrorMsg(err);
                                          })
                                      }
                                    }, 'image/png', 0.7); // Specify the desired image format ('image/jpeg' for JPG, 'image/png' for PNG)
                                  });
                              }
                              // const response = await commonPostApi('/capture-selfie-web', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                              // if (response?.status === 200) {
                              //   navigationStage(response?.user_data?.stage);
                              // } else {
                              //   setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
                              // }

                            }
                            catch (error) {
                              setErrorMsg('Something went wrong!!');
                            }
                          }
                          else {
                            setErrorMsg('The captured selfie is not appropriate for a work environment. Please take another one.');
                            setEnableButton(false);
                            return;
                          }
                        } else {
                          setErrorMsg(response.result.face_quality.message);
                          setEnableButton(false);
                          return;
                        }
                      } else {
                        setErrorMsg(response.result.face_coverage.message);
                        setEnableButton(false);
                        return;
                      }
                    } else {
                      setErrorMsg('Please ensure the full face is visible');
                      setEnableButton(false);
                      return;
                    }
                  } else {
                    setErrorMsg('Please take a selfie with the front-facing camera');
                    setEnableButton(false);
                    return;
                  }
                } else {
                  setErrorMsg('Muliple faces Detected');
                  setEnableButton(false);
                  return;
                }
              } else {
                setErrorMsg('Please Capture a live Selfie with eyes open');
                setEnableButton(false);
                return;
              }
            } else {
              setErrorMsg('Please Capture a live Selfie in a visible Background');
              setEnableButton(false);
              return;
            }
          }
          else {
            setErrorMsg('No face Detected');
            setEnableButton(false);
            return;
          }
        } else if (response.status == 'failed') {
          setErrorMsg(response.message);
          setEnableButton(false);
          return;
        } else {
          setErrorMsg('Please Capture the Selfie Again '); setEnableButton(false);
          return;
        }
      })
      .catch((error) => {
        setErrorMsg(error);
        setEnableButton(false);
      })
  }
  const onChangeHandlerBack = () => {
    dispatch(setSelfieImageUrlAction(""))
    navigate('/selfie');
  }
  const dataURLtoFile = (dataUrl, filename) => {
    try {

      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } catch (error) {
      console.log("something went wrong...please refresh page and try again");
    }
  }
  //for penny drop status update
  const [pennyVerified, setPennyVerified] = useState('false');
  const [pennyId, setPennyId] = useState('--');
  const [verificationTime, setPennyVerificationTime] = useState('--');
  const [pennyHolderName, setPennyHolderName] = useState('--');
  const [panHolderFullName, setPanHolderFullName] = useState(kycStep1?.userData?.fullName);


  //for kra page
  const divRefKRA = useRef(null);
  const [kraStatus, setKRAStatus] = useState('');
  const [uniqueKey, setUniqueKey] = useState('');
  const [perAddressProof, setPerAddressProof] = useState('');
  const [corAddressProof, setCorAddressProof] = useState('');
  const [perAddress, setPerAddress] = useState('');
  const [corAddress, setCorAddress] = useState('');
  const [panFromKra, setPanFromKra] = useState('');
  const [perAddressCode1, setPerAddressCode1] = useState('');
  const [perAddressCode2, setPerAddressCode2] = useState('');
  const [corAddressCode1, setCerAddressCode1] = useState('');
  const [corAddressCode2, setCerAddressCode2] = useState('');
  const [perAddressType, setPerAddressType] = useState('');
  const [corAddressType, setCorAddressType] = useState('');
  const [kraLatitude, setKRALatitude] = useState('');
  const [kraLongitude, setKRALongitude] = useState('');
  const [result, setResult] = useState(null);

  //geo location tagging
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          const { latitude, longitude } = position.coords;
          checkCoordinates(latitude, longitude);
        },
        (error) => {
          setEnableLocation(false);
          setErrorMsg('Please allow location access to continue');
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      setErrorMsg('Please allow location access to continue');
      console.error('Geolocation is not supported by your browser');
    }
  }, []);

  const checkCoordinates = (latitude, longitude) => {
    // Define the boundaries of India
    const indiaBounds = {
      minLatitude: 6.75,
      maxLatitude: 35.5,
      minLongitude: 68.7,
      maxLongitude: 97.25,
    };

    // Parse input coordinates as floats
    const inputLatitude = parseFloat(latitude);
    const inputLongitude = parseFloat(longitude);

    // Check if coordinates are within India's boundaries
    const isInsideIndia =
      inputLatitude >= indiaBounds.minLatitude &&
      inputLatitude <= indiaBounds.maxLatitude &&
      inputLongitude >= indiaBounds.minLongitude &&
      inputLongitude <= indiaBounds.maxLongitude;

    if (isInsideIndia) {
      setKRALatitude(latitude);
      setKRALongitude(longitude);
      setEnableLocation(true);
      const CordinatesPayload = {
        "mobile": userData?.mobileInput,
        "lat": latitude,
        "lng": longitude
      }
      dkycApi('/saveLatLngDetails', CordinatesPayload, 'application/json')
        .then((response) => {
          if (response.success === true) { }
          else {
            setErrorMsg('Some Error Has Occured, Please Try again');
            setEnableLocation(false);

          }
        }).catch((err) => {
          setErrorMsg(err);
          setEnableLocation(false);
        })
    }
    else {
      setEnableLocation(false);
      setErrorMsg('KYC can not be performed for Your Location');
    }
    setResult(isInsideIndia ? 'Inside India' : 'Outside India');
  }

  const addressProofList = [
    { code: "01", description: "Passport" },
    { code: "02", description: "Driving License" },
    { code: "03", description: "Latest Bank Passbook" },
    { code: "04", description: "Latest Bank Account Statement" },
    { code: "06", description: "Voter Identity Card" },
    { code: "07", description: "Ration Card" },
    { code: "08", description: "Registered Lease / Sale Agreement of Residence" },
    { code: "09", description: "Latest Land Line Telephone Bill" },
    { code: "10", description: "Latest Electricity Bill" },
    { code: "11", description: "Gas Bill" },
    { code: "13", description: "Flat Maintenance Bill" },
    { code: "14", description: "Insurance copy" },
    { code: "15", description: "Self Declaration by High Court / Supreme Court Judge" },
    { code: "16", description: "Power of Attorney given by FII/sub-account to the Custodians (which are duly notarized and/or apostiled or consularized) giving registered address." },
    { code: "17", description: "Proof of Address issued by Scheduled Commercial Banks / Scheduled Co-operative Banks / Multinational Foreign banks." },
    { code: "18", description: "Proof of Address issued by Elected representatives to the Legislative Assembly" },
    { code: "19", description: "Proof of Address issued by Parliament" },
    { code: "20", description: "Proof of Address issued by any Government / Statutory Authority" },
    { code: "21", description: "Proof of Address issued by Notary Public" },
    { code: "22", description: "Proof of Address issued by Gazetted Officer" },
    { code: "23", description: "ID Card with address issued by Central / State Government" },
    { code: "24", description: "ID Card with address issued by Statutory / Regulatory Authorities" },
    { code: "25", description: "ID Card with address issued by Public Sector Undertakings" },
    { code: "26", description: "ID Card with address issued by Scheduled Commercial Banks" },
    { code: "27", description: "ID Card with address issued by Public Financial Institutions" },
    { code: "28", description: "ID Card with address issued by Colleges affiliated to universities" },
    { code: "29", description: "ID Card issued by Professional Bodies such as ICAI, ICWAI, ICSI, Bar Council, etc. to their Members" },
    { code: "31", description: "AADHAAR" },
    { code: "33", description: "Nrega Job Card" },
    { code: "34", description: "Pension Or Family Pension Payment Orders (PPOs) Issued To Retired Employees By Government Departments Or Public Sector Undertakings, If They Contain The Address." }
  ];
  const findAddressProof = (code) => {
    const addressProof = addressProofList.find((proof) => String(proof.code) === String(code));
    if (addressProof) {
      return addressProof.description;
    }
    return 'Address Proof Not Found';
  };
  const allowLocationHandler = () => {
    window.location.reload();
  }

  return (<>
    <section className="main-wraper selfie-page">
      <StepsHeader step={6} />

      <AsideWallpaper
        color='green'
        descriptionHeader=""
        backGroundImageUrl="./images/selfie-img.svg"
        backGroundImageUrlAltText="some image"
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <div className="selfie-box block-center mb-3">
                <img
                  src={selfieImageDisplay}
                  alt="your image"
                  className=""
                />
              </div>
              <h1 className="title-1 mb-1">This is your picture! </h1>
            </div>
            <div className="block-center">
              <p className="para-13 mb-1">
                If you are not happy with the picture, you can take it again.
              </p>
              <p className="para-1 mb-0 pb-2 font-400">
                <a onClick={onChangeHandlerBack} className="color-blue cursor-pointer">
                  Take selfie again
                </a>
              </p>
            </div>
          </div>
          {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          {!enableLocation && <a onClick={allowLocationHandler} className="color-blue cursor-pointer">
            Click here to Refresh the page
          </a>}
          <div className="btn-action-box">
            {kraLatitude && <p className='para-13'>Your Current Location is {kraLatitude} {kraLongitude} </p>}
            <button className="btn btn-primary btn-full mb-2" onClick={onChangeHandlerNext} disabled={!(enableButton && enableLocation && enablePennyDrop && enableKRAdata)}>
              <img
                src="./images/selfie-camera-icon-2.svg"
                alt="icon"
                className="mr-2"
              />
              Looks Good!
            </button>
          </div>
          {isKraApproved ?
            <div ref={divRefKRA} className='screenshot'
              style={{
                opacity: '1',
                position: 'absolute',
                left: '-9999px',
                width: '300px',
                fontSize: '10px',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td width="100%" style={{ padding: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px 0px",
                          justifyContent: "space-between",
                          borderBottom: "2px solid blue",
                          paddingBottom: 5
                        }}>
                        <span>
                          <h6>KRA Info</h6>
                        </span>
                        <span />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>KRA-Verification Code -</span>
                        <span>
                          {kraStatus}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>KRA-BATCH Code -</span>
                        <span>
                          {uniqueKey}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>KRA-Permanent Address Proof Type-</span>
                        <span>
                          {findAddressProof(perAddressProof)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>KRA-Permanent Address Proof -</span>
                        <span>
                          {perAddressType}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>KRA-Correspondence Address Proof Type -</span>
                        <span>
                          {findAddressProof(corAddressProof)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>KRA-Correspondence Address Proof -</span>
                        <span>
                          {corAddressType}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Permanent Addresss - </span>
                        <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                          {perAddressCode1}  <br /> {perAddressCode2}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span >Correspondence Address - </span>
                        <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                          {corAddressCode1}  <br /> {corAddressCode2}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>PAN no -</span>
                        <span>
                          {panFromKra}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Latitude -</span>
                        <span>
                          {kraLatitude}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Longitude</span>
                        <span>
                          {kraLongitude}
                        </span>
                      </div>

                    </td>
                  </tr>
                  <tr>
                    <td width="100%" style={{ padding: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px 0px",
                          justifyContent: "space-between",
                          borderBottom: "2px solid blue",
                          paddingBottom: 5
                        }}
                      >
                        <span>
                          <h6>Penny Drop Info</h6>
                        </span>
                        <span />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Id -</span>
                        <span>
                          {pennyId}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Verified Status -</span>
                        <span>
                          <p>{pennyVerified}</p>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Date &amp; Time -</span>
                        <span>
                          {verificationTime}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Beneficiary Name With Bank -</span>
                        <span>
                          <b style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                            {pennyHolderName}
                          </b>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Pan-Card Full Name -</span>
                        <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                          {panHolderFullName}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> :
            <div ref={divRefKRA} style={{
              opacity: '1',
              position: 'absolute',
              left: '-9999px',
              width: '300px',
              fontSize: '10px',
              marginLeft: 'auto',
              marginRight: 'auto'
            }} className=''>
              <table >
                <tbody>
                  <tr>
                    <td width="49%">
                      <div style={{}} id="userImageData" >
                        <span>
                          {/* <img style={{ width: "100%" }} src={fileName} /> */}
                          {/* <img style={{ width: '100%' }} src={imageData} /> */}
                          {/* <img style={{ width: "100%" }} src={imageDigioFile} /> */}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="100%" style={{ padding: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px 0px",
                          justifyContent: "space-between",
                          borderBottom: "2px solid blue",
                          paddingBottom: 5
                        }}
                      >
                        <span>
                          <h6>Geo Location Info</h6>
                          {/* <p>
                        ID Type - {idType}
                      </p> */}
                          {/* <p>
                        Face Match -
                        <span style={{ color: "green" }}>
                          {matched}{faceMatch}
                        </span>
                      </p> */}
                        </span>
                        <span />
                      </div>
                      {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >

                    <span>Address -</span>
                    <span style={{marginLeft: 10, wordWrap: 'break-word', wordBreak: 'break-all' }} >
                      {address}
                    </span>
                  </div> */}
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Latitude -</span>
                        <span>
                          <b>
                            {kraLatitude}
                          </b>
                        </span>
                      </div>
                      {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Accuracy -</span>
                    <span>
                      {accuracy}
                    </span>
                  </div> */}
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Longitude -</span>
                        <span>
                          <b>
                            {kraLongitude}
                          </b>
                        </span>
                      </div>
                      {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Status -</span>
                    <span style={{ color: "green" }}>
                      {GeoStatus}
                    </span>
                  </div> */}
                      {/* <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Date Time -</span>
                    <span style={{ color: "green" }}>
                      {dateTime}
                    </span>
                  </div> */}
                    </td>
                  </tr>
                  <tr>
                    <td width="100%" style={{ padding: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px 0px",
                          justifyContent: "space-between",
                          borderBottom: "2px solid blue",
                          paddingBottom: 5
                        }}
                      >
                        <span>
                          <h6>Penny Drop Info</h6>
                        </span>
                        <span />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Id -</span>
                        <span>
                          {pennyId}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Verified Status -</span>
                        <span>
                          <p>{pennyVerified}</p>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Date &amp; Time -</span>
                        <span>
                          {verificationTime}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Beneficiary Name With Bank -</span>
                        <span>
                          <b style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                            {pennyHolderName}
                          </b>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Pan-Card Full Name -</span>
                        <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                          {userName ? userName : panHolderFullName}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    </section>

  </>)


}
export default YourPicture;