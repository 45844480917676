import React, { useEffect, useRef, useState } from 'react'
import AsideWallpaper from '../Utils/AsideWallpaper'
import ReactInputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import axios from 'axios';

export default function PanScreen() {
    const [errorMessage, setErrorMessage] = useState('');
    const [panError, setPanError] = useState('');
    const [panNumber, setPanNumber] = useState('');
    const [nameOnPan, setNameOnPan] = useState('');
    const [isValidPan, setIsValidPan] = useState(false);
    const [buttonName , setButtonName] = useState(' Verify PAN');
    const [panVerified,setPanVerified] = useState(false);

    const [disableButton, setDisableButton] = useState(true);

    const [validDob, setIsValidDob] = useState(false);
    const currentDate = new Date();
    const inputRef = useRef(null);
    const today = new Date().toISOString().split('T')[0];
    const [selectedDate, setSelectedDate] = useState(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const dobInputHandler = (value) => {
        setErrorMessage('');
        setIsValidDob(true);
        setSelectedDate(value);
    };
    const nameInputHandler = (e) => { setNameOnPan(e.target.value) };

    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const panInputHandler = (e) => {
        setErrorMessage('');
        setPanError('');
        const panNumber = e.target.value;
        const formattedPan = panNumber.toUpperCase().replace(/[^A-Z0-9]/g, '');
        let validPan = validatePan(formattedPan);
        if (validPan) {
            setPanError('');
            setIsValidPan(true);
        }
        else {
            if (panNumber?.length == 0) { setPanError('') } else { setPanError('Please enter a valid PAN Number.'); }
            setIsValidPan(false);
        }
        setPanNumber(formattedPan)
    };
    const validatePan = (code) => {
        return panCardRegex.test(code);
    }
    function formatDate(dateString) {
        // Create a new Date object from the provided dateString
        const date = new Date(dateString);
      
        // Extract day, month, and year
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const year = date.getFullYear();
      
        // Format day and month to have leading zeros if necessary
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
      
        // Construct the formatted date string in the desired format
        const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
      
        return formattedDate;
      }
    const onChangeHandlerNext =()=>{
        setButtonName('Please wait..');
        setErrorMessage('');
        const payload ={
            "PanNo":panNumber,
            "name": nameOnPan,
            "dob": selectedDate.toLocaleDateString('en-GB')
        }
        axios.post('https://ekycapi.bigul.co/directPanApiCheck',payload)
        .then((res)=>{
            if(res.data.status == true){
                setPanVerified(true);
            }
            else{
                setPanVerified(false);
            }
            setButtonName(' Verify PAN');
            setErrorMessage(res.data.message);}
    ).catch( ()=>{
        setButtonName(' Verify PAN');
        setErrorMessage('Some Error Has Occured Please try again later');
    }
    )
    }
    return (
        <section className="main-wraper progress-inner">

            <AsideWallpaper
                color={'green'}
                descriptionHeader={''}
                backGroundImageUrl={"images/pan-card-details.svg"}
            />
            <div className="body-content-box">
                <div className="login-box">
                    <div className="login-box-wrap">
                        <div className="">
                            <h1 className="title-2 mb-36">Verify PAN Details </h1>
                        </div>
                        <div className="block-center">
                            <p className="para-1">
                                Enter your Name, PAN number and Date of Birth. <br /> We will verify the
                                details for you.
                            </p>
                        </div>
                        <div className="input-box mt-5">
                            <input
                                type="text"
                                // maxLength={10}
                                className="input-form text-center mb-3 input-pan-card"
                                placeholder="Name as per PAN"
                                onChange={nameInputHandler}
                                value={nameOnPan}
                            />

                            {panError && <div className='input-error-msg text-center'>{panError}</div>}
                            <ReactInputMask
                                mask="aaaaa9999a" maskChar={null}
                                type="text"
                                autoFocus
                                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                // maxLength={10}
                                className="input-form text-center mb-3 input-pan-card"
                                placeholder="Enter PAN Number"
                                onChange={panInputHandler}
                                value={panNumber}
                            />

                            <div className='input-date-box'>
                                <DatePicker
                                    customInput={<ReactInputMask
                                        type="text"
                                        mask="99/99/9999"
                                    // value={selectedDate || ''}
                                    // onChange={handleInputChange}
                                    />}
                                    selected={selectedDate}
                                    onChange={dobInputHandler}
                                    // onKeyDown={inputChangeHandler}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='DD/MM/YYYY'
                                    // maxDate={currentDate}
                                    // minDate={hundreadYearsAgo}
                                    showYearDropdown
                                    yearDropdownItemNumber={80}
                                    scrollableYearDropdown
                                    ref={inputRef}
                                    openToDate={currentDate}
                                    open={calendarOpen}
                                />
                                {/* <FaCalendar className="calendar-icon" onClick={toggleCalendar} /> */}
                                {errorMessage && <p className='input-error-msg text-center' style={{color : panVerified ? 'green' : 'red'}}>{errorMessage}</p>}
                                {/* {kraError && <div className='input-error-msg text-center' style={{ fontSize: '14px', marginTop: '2px' }}>{kraError}</div>} */}
                            </div>
                        </div>
                    </div>
                    <div className="btn-action-box">
                        <button
                            type='button'
                            className={`btn btn-${!(isValidPan && validDob && nameOnPan) ? 'secondary' : 'primary'} btn-full mb-2`}
                            data-bs-toggle="modal"
                            data-bs-target="#Modal-3" onClick={onChangeHandlerNext} disabled={!(isValidPan && validDob && nameOnPan)}>
                            {buttonName}
                        </button> 
                    </div>
                </div>
            </div>
        </section>
    )
}
