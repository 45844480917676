import { changeStep1DobInput, changeStep1Error, changeStep1PanInput, getPanCardDetail, setDobError, setPanCardDetail, setPanError } from '../../actions/kyc/step1.action';

const mutableState = {
	panInput: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	dobInput: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	userData: {},
	isKRA: false,
	status: ''
};

const initState = {
	...mutableState
};

export const kycStep1Reducer = (state = initState, action) => {

	switch (action.type) {
		// case clearKyc:
		// 	return {
		// 		...mutableState
		// 	};
		case changeStep1PanInput:
			return {
				...state,
				panInput: {
					...state.panInput,
					value: action.payload,
					isError: false,
					errorText: ''
				}
			};
		case changeStep1DobInput:
			return {
				...state,
				dobInput: {
					...state.dobInput,
					value: action.payload,
					isError: false,
					errorText: ''
				}
			}
		case setPanError:
			return {
				...state,
				panInput: {
					...state.panInput,
					isError: true,
					errorText: action.payload
				}
			};
		case setDobError:
			return {
				...state,
				dobInput: {
					...state.dobInput,
					isError: true,
					errorText: action.payload
				}
			};
		case getPanCardDetail: 
		return {
            ...state,
			userData :	action.payload
		}
		//idk setPanCardDetail ,not used anywhere
		case setPanCardDetail:
			return {
				...state,
				panInput: {
					...state.panInput,
					isError: false,
					errorText: ''
				},
				dobInput: {
					...state.dobInput,
					isError: false,
					errorText: ''
				},
				data: action.payload.data,
				isKRA: action.payload?.isKRA || false,
				status: 'success'
			};
		default:
			return state;
	}
};
