import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import {
  persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Choose a storage option (e.g., local storage)
import rootReducer from './reducers/rootReducer'; // Your root reducer
import storageSession from 'redux-persist/lib/storage/session'; 

// import rootSaga from '../redux-saga/rootSaga';

const sagaMiddleware = createSagaMiddleware();

// Create the Redux Persist configuration
const persistConfig = {

  key: 'root', // Root key for storing the state
  storage : storageSession, // Storage option
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
// sagaMiddleware.run(rootSaga);
// Create the persisted store
const persistor = persistStore(store);

export { store, persistor };
