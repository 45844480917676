import React, { useState, useEffect } from 'react';
import { commonPostApi } from '../../ApiServices/common';
import { store } from '../../redux/store';
import { stopLoaderAction } from '../../redux/actions/app.action';



export default function ResendButton(props) {
    const [timer, setTimer] = useState(props.count);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
        if (timer === 0) {
            setIsButtonDisabled(false);
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [timer]);
    const formattedSeconds = timer.toString().padStart(2, '0');
  
    const ResendOtpHandler = async () => {
         //empty otp
         props.EmptyOtp();
        if (isButtonDisabled) {
            return;
        }
        try {
            const response = await commonPostApi(props.url, props.payload,null);

            if (response.status === 200) {
                setIsButtonDisabled(true);
                setTimer(props.count);
            }
        }
        catch (err) {
            //error managenment pending
            store.dispatch(stopLoaderAction());
        }
    }

    return (
        <div className="d-flex pt-4 justify-content-center align-items-center">
            <p className="text-left w-90px mb-0 font-18-14">00 : {formattedSeconds} </p>
            <button className="btn btn-link resend" onClick={ResendOtpHandler} disabled={isButtonDisabled}>
                Resend OTP
            </button>
        </div>
    );
}


