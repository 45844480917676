export const  getBankName = 'GET_BANK_NAME';
export const getBankAccountNumber = 'GET_BANK_ACCOUNT_NUMBER';
export const getConfirmAccountNumber = 'GET_CONFIRM_ACC_NO';
export const getaccountHolderName = 'ACCOUNT_HOLDER_NAME';

export const  getBankNameAction =(payload)=>({
    type :  getBankName,
    payload
});
export const getBankAccountNumberAction=(payload)=>({
   
    type : getBankAccountNumber,
    payload
})
export const getConfirmAccountNumberAction=(payload)=>({
    type : getConfirmAccountNumber,
    payload
})
export const accountHolderNameAction=(payload)=>({
    type : getaccountHolderName,
    payload
})

