import React from 'react'
import celebration from '../../assets/gif/Party Confetti.gif';
export default function Roadmapcelebration({readyTOTradeStatus}) {   
    return (
        readyTOTradeStatus
            ? (
               
                    <div className="celebration">
                        <img src={celebration} alt="" className='site-loader-img' />
                    </div>
            
            )
            : null
    );
}
