import { buttonProcessing, buttonContinue } from '../actions/buttonDesc.action';

const initState = {
    buttonDesc: ''
};

export const buttonDescReducer = (state = initState, action) => {
    switch (action.type) {
        case buttonProcessing:
            return {
                ...state,
                buttonDesc: action.payload
            };
        case buttonContinue:
            return {
                ...state,
                buttonDesc: action.payload
            };
        default:
            return state;
    }
};
