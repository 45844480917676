import axios from 'axios';
import { startLoaderAction, stopLoaderAction } from '../redux/actions/app.action';
import { store } from '../redux/store';
import { buttonProcessingAction } from '../redux/actions/buttonDesc.action';


export const baseUrl = process.env.REACT_APP_BIGUL_BASE_URL;
export const baseAppUrl = 'https://bigul.app/api';
export const dkycUrl = process.env.REACT_APP_BIGUL_BONANZA_BASE_URL;
export const pennyDropUrl = process.env.REACT_APP_BIGUL_PENNY_DROP_URL;
export const razorpayUrl = process.env.REACT_APP_BIGUL_RAZORPAY_URL;
export const apiKey = process.env.REACT_APP_DKYC_BONANZA_API_KEY;
export const digioToken = process.env.REACT_APP_DKYC_BONANZA_DIGIO_API_KEY;
export const emailUrl = process.env.REACT_APP_BONANZA_EMAIL_URL;
export const dkycDirectUrl = 'https://direct.bigul.co/';

const ErrorHandler = (error) => {
	return "Some Error Has Occured, Please Try again";
}

export const digioApi =  (url, payload, errorHandler = ErrorHandler) => {
	return new Promise((resolve, reject) => {
		const headers = {
			headers:
			{
				Authorization: `Bearer ${digioToken}`,
				'Content-Type': 'application/json'
			}
		};
		axios.post(
			`${dkycUrl}${url}`,
			payload,
			 headers 
		).then((response) => {
			const data = response?.data;
			resolve(data);
		}).catch((err) => {
			const error = errorHandler(err?.response?.data);
			reject(error);
		})
	});
}
export const commonPostApi = (url, payload, headers, errorHandler = ErrorHandler) => {
	if (!headers || !headers["skipLoading"]) {
		store.dispatch(startLoaderAction());
		
	}
	return new Promise((resolve, reject) => {
		axios.post(
			`${baseUrl}${url}`,
			payload,
			headers
		)
			.then((response) => {
				store.dispatch(stopLoaderAction());
				const data = response?.data;
				resolve(data);
			})
			.catch((error) => {
				store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err);
			})
	});
};
export const fetchBankNameData = (payload) => {
	// store.dispatch(startLoaderAction());
	return new Promise((resolve, reject) => {
		axios.get(`${razorpayUrl}${payload}`)
			.then((response) => {
				// store.dispatch(stopLoaderAction());
				resolve(response);
			})
			.catch((err) => {
				// store.dispatch(stopLoaderAction());
				reject(err);
			})
	})
};
export const verifyBankAccountApi = async (payload, errorHandler = ErrorHandler) => {
	store.dispatch(startLoaderAction());
	const pennyToken = process.env.REACT_APP_BIGUL_PENNY_DROP_TOKEN;
	return new Promise((resolve, reject) => {
		axios.post(pennyDropUrl, payload, {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Authorization: `Basic ${pennyToken}`
			}
		}).then((response) => {
			store.dispatch(stopLoaderAction());
			const data = response?.data;
			resolve(data);
		}).catch((error) => {
			store.dispatch(stopLoaderAction());
			const err = errorHandler(error?.response?.data);
			reject(err);
		})
	});
};
export const dkycApi = (url, payload, contentType = null, errorHandler = ErrorHandler) => {
	// if (!headers || !headers["skipLoading"]) {

	// 	store.dispatch(startLoaderAction());
	// }
	// store.dispatch(startLoaderAction());
	const headers = {
		headers: {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': contentType,
		}
	}
	return new Promise((resolve, reject) => {
		axios.post(`${dkycUrl}${url}`, payload, headers)
			.then((response) => {
				// store.dispatch(stopLoaderAction());
				resolve(response.data)
			})
			.catch((error) => {
				// store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err)
			})
	})
};
export const dkycApiWithLoader = (url, payload, contentType = null, errorHandler = ErrorHandler) => {
	// if (!headers || !headers["skipLoading"]) {
	// 	store.dispatch(startLoaderAction());
	// }
	const headers = {
		headers: {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': contentType,
		}
	}
	return new Promise((resolve, reject) => {
		axios.post(`${dkycUrl}${url}`, payload, headers)
			.then((response) => {
				// store.dispatch(stopLoaderAction());
				resolve(response.data)
			})
			.catch((error) => {
					// store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err)
			})
	})
};
export const dkycApiHavingLoader = (url, payload, contentType = null, errorHandler = ErrorHandler) => {
	// if (!headers || !headers["skipLoading"]) {
		store.dispatch(startLoaderAction());
	// }
	const headers = {
		headers: {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': contentType,
		}
	}
	return new Promise((resolve, reject) => {
		axios.post(`${dkycUrl}${url}`, payload, headers)
			.then((response) => {
				store.dispatch(stopLoaderAction());
				resolve(response.data)
			})
			.catch((error) => {
				store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err)
			})
	})
};
export const kraGetAPi = (url, payload, errorHandler = ErrorHandler) => {
	store.dispatch(startLoaderAction());
	return new Promise((resolve, reject) => {
		axios.get(`${baseUrl}${url}${payload}`)
			.then((response) => {
				store.dispatch(stopLoaderAction());
				resolve(response.data);
			})
			.catch((error) => {
				store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);

				reject(error?.response?.data)
			})
	})
}
export const EmailGetAPi = (url, payload, errorHandler = ErrorHandler) => {
	store.dispatch(startLoaderAction());
	return new Promise((resolve, reject) => {
		axios.get(`${emailUrl}${url}${payload}`)
			.then((response) => {
				store.dispatch(stopLoaderAction());
				resolve(response.data);
			})
			.catch((error) => {
				store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);

				reject(error?.response?.data)
			})
	})
}
export const commonPostApiWithoutLoader = (url, payload, headers, errorHandler = ErrorHandler) => {
	// if (!headers || !headers["skipLoading"]) {
	// 	store.dispatch(startLoaderAction());
	// }
	return new Promise((resolve, reject) => {
		axios.post(
			`${baseUrl}${url}`,
			payload,
			headers
		)
			.then((response) => {
				// store.dispatch(stopLoaderAction());
				const data = response?.data;
				resolve(data);
			})
			.catch((error) => {
				// store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err);
			})
	});
};
export const dkycGetApi = (url, payload, contentType = null, errorHandler = ErrorHandler) => {
	// if (!headers || !headers["skipLoading"]) {

	// 	store.dispatch(startLoaderAction());
	// }
	// store.dispatch(startLoaderAction());
	const headers = {
		headers: {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': contentType,
		}
	}
	return new Promise((resolve, reject) => {
		axios.get(`${dkycUrl}${url}`, headers)
			.then((response) => {
				// store.dispatch(stopLoaderAction());
				resolve(response.data)
			})
			.catch((error) => {
				// store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err)
			})
	})
};
export const dkycDirectGetApi = (url, payload, contentType = null, errorHandler = ErrorHandler) => {
	// if (!headers || !headers["skipLoading"]) {

	// 	store.dispatch(startLoaderAction());
	// }
	// store.dispatch(startLoaderAction());
	const headers = {
		headers: {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': contentType,
		}
	}
	return new Promise((resolve, reject) => {
		axios.get(`${dkycDirectUrl}${url}`, headers)
			.then((response) => {
				// store.dispatch(stopLoaderAction());
				resolve(response.data)
			})
			.catch((error) => {
				// store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err)
			})
	})
};
export const commonPostAppApi = (url, payload, headers, errorHandler = ErrorHandler) => {
	if (!headers || !headers["skipLoading"]) {
		store.dispatch(startLoaderAction());
		
	}
	return new Promise((resolve, reject) => {
		axios.post(
			`${baseAppUrl}${url}`,
			payload,
			headers
		)
			.then((response) => {
				store.dispatch(stopLoaderAction());
				const data = response?.data;
				resolve(data);
			})
			.catch((error) => {
				store.dispatch(stopLoaderAction());
				const err = errorHandler(error?.response?.data);
				reject(err);
			})
	});
};