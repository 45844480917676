import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { commonPostApi, digioApi } from '../../../ApiServices/common';
import { setAadharAction, setCityAction, setPincodeAction, setStateAction } from '../../../redux/actions/kyc/step2.action';
import { digioPostApi } from '../../../ApiServices/digilockerApi';
import StepsHeader from '../../Utils/StepsHeader';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import { store } from '../../../redux/store';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { setIsKraApprovedAction } from '../../../redux/actions/kyc/step3.action';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';

function DigiLockerVerification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(state => state?.userLogin || {});
  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const pan = kycStep1?.panInput.value;
  const userName = kycStep1?.userData?.fullName;
  const [errorMsg, setErrorMsg] = useState('');
  const [digioMessage, setDigioMessage] = useState('');
  const [digioError, setDigioError] = useState('');
  const [digioButtonDisable, setDigioButtonDisable] = useState(false);
  const [digioButtonDesc, setDigioButtonDesc] = useState('Allow Access');
  const [shouldCloseIframe, setShouldCloseIframe] = useState(true);
  const [digioUrl, setDigioUrl] = useState('');


  const initalStageforPan = '5';
  useResumeJourneyPerStage(initalStageforPan);
  // useCheckRequiredJourneyData(initalStageforPan);

  let intervalId = null;
  const onChangeHandlerDigioLocker = async () => {
    setErrorMsg('');
    try {
      const payload = {
        name: userName,
        mobile: userDetails?.mobileInput
      };
      const response = await digioApi('/send-direct-digilocker-link', payload);
      if (response?.success === true) {
        setShouldCloseIframe(false);
        setDigioButtonDisable(true);
        setDigioButtonDesc('Please wait...');
        const url = response?.link;
        setDigioUrl(url);
        const payload = { mobile: userDetails?.mobileInput };
        setTimeout(() => {
          digioApi('/is-digio-kyc-completed', payload)
          .then((response) => {
            if (response?.success === true) {
              setDigioMessage(response?.success);
              clearInterval(intervalId);
              setShouldCloseIframe(true);
              const crn_number = response?.data?.digio_data?.reference_id;
              const response_id = response?.data?.digio_data?.id;
              const response_action_id = response?.data?.digio_data?.actions[0]?.execution_request_id;
              const json_data = response?.data?.digio_data;
              const aadhaar = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.id_number;
              const city = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.permanent_address_details?.district_or_city;
              const state = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.permanent_address_details?.state;
              const pincode = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.permanent_address_details?.pincode;
              const fileId = response?.data?.digio_data?.actions[1]?.file_id;
             
              dispatch(setCityAction(city));
              dispatch(setStateAction(state));
              dispatch(setAadharAction(aadhaar));
              dispatch(setPincodeAction(pincode));

              const digilockerData = {
                mobile: userDetails?.mobileInput,
                pan: pan,
                crn_number: crn_number,
                response_id: response_id,
                response_action_id: response_action_id,
                json_data: json_data
              }
              commonPostApi('/digilocker-data-web', digilockerData, null)
              .then((data) => {
                if (data.status === 200) {
                  dispatch(setIsKraApprovedAction(false));
                  navigate('/personal-details-1');
                } else {
                  setErrorMsg(data?.msg ? data?.msg : 'something went wrong!!');
                  return;
                }
              }).catch((err) => {
                setErrorMsg(err);
              });
            }
            else if (response?.success === false) {
              //what to enter while waiting for the digio journey 
              // setDigioMessage(response?.message);
            }
            else if (response?.error === true) {
              if (response?.message?.mobile) {
                setDigioError(response?.message?.mobile[0]);
                // clearInterval(intervalId);
              }
            }
            else {
              setDigioError(response?.message);
              // clearInterval(intervalId);
            }
          }
          ).catch((error) => {
            setErrorMsg(error);
            // clearInterval(intervalId);
          })
        }, 30000); 
        intervalId = setInterval(() => {
          digioApi('/is-digio-kyc-completed', payload)
          .then((response) => {
            if (response?.success === true) {
              setDigioMessage(response?.success);
              clearInterval(intervalId);
              setShouldCloseIframe(true);
              const crn_number = response?.data?.digio_data?.reference_id;
              const response_id = response?.data?.digio_data?.id;
              const response_action_id = response?.data?.digio_data?.actions[0]?.execution_request_id;
              const json_data = response?.data?.digio_data;
              const aadhaar = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.id_number;
              const city = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.permanent_address_details?.district_or_city;
              const state = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.permanent_address_details?.state;
              const pincode = response?.data?.digio_data?.actions[0]?.details?.aadhaar?.permanent_address_details?.pincode;
              const fileId = response?.data?.digio_data?.actions[1]?.file_id;
             
              dispatch(setCityAction(city));
              dispatch(setStateAction(state));
              dispatch(setAadharAction(aadhaar));
              dispatch(setPincodeAction(pincode));

              const digilockerData = {
                mobile: userDetails?.mobileInput,
                pan: pan,
                crn_number: crn_number,
                response_id: response_id,
                response_action_id: response_action_id,
                json_data: json_data
              }
              commonPostApi('/digilocker-data-web', digilockerData, null).then((data) => {
                if (data.status === 200) {
                  dispatch(setIsKraApprovedAction(false));
                  navigate('/personal-details-1');
                } else {
                  setErrorMsg(data?.msg ? data?.msg : 'something went wrong!!');
                  return;
                }
              }).catch((err) => {
                setErrorMsg(err);
              });
            }
            else if (response?.success === false) {
              //what to enter while waiting for the digio journey 
              // setDigioMessage(response?.message);
            }
            else if (response?.error === true) {
              if (response?.message?.mobile) {
                setDigioError(response?.message?.mobile[0]);
                clearInterval(intervalId);
              }
            }
            else {
              setDigioError(response?.message);
              clearInterval(intervalId);
            }
          }
          ).catch((error) => {
            setErrorMsg(error);
            setTimeout(()=>{
             clearInterval(intervalId);
            },20000)
          })
        }, 10000);
      }
      else {
        setErrorMsg(response?.message ? response?.message : 'Something went wrong!!');
        return;
      }

    } catch (error) {
      setErrorMsg(error);
    }
  }

  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader step={2} />
      <AsideWallpaper
        color={'blue'}
        descriptionHeader={''}
        backGroundImageUrl={"images/digi-locker.svg"}
      />
      {!shouldCloseIframe && (
        <iframe className='iframe-box'
          src={digioUrl}
        />
      )}
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1">
                We need access to <br /> your Digilocker{" "}
              </h1>
            </div>
            <div className="block-center">
              <p className="para-1">
                Digilocker automatically verifies your documents needed for KYC and
                account opening with Bigul.
              </p>
              <p className="para-1 mb-0 pb-4 pt-4 font-400">
                <a href="" className="text-decoration color-dark-green cursor-auto">
                  <img
                    src="images/digi-locker-icon.svg"
                    className="mr-3"
                    alt="img"
                  />{" "}
                  Don’t worry, your data is 100% safe!
                </a>
              </p>
            </div>
          </div>
          <div className="btn-action-box">
            <p className="para-13 px-3 mw-400 block-center">
              By tapping ‘Allow Access’, you provide consent to allow this
              application to access your DigiLocker.
            </p>
            {digioMessage && <p className='text-center '>{digioMessage}</p>}
            {digioError && <p className='input-error-msg text-center '>{digioError}</p>}
            {errorMsg && <p className='input-error-msg text-center '>{errorMsg}</p>}
            <button
              className="btn btn-primary btn-full mb-2"
              data-bs-toggle="modal"
              data-bs-target="#Modal-3" onClick={onChangeHandlerDigioLocker} disabled={digioButtonDisable}
            >
              {digioButtonDesc}
            </button>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default DigiLockerVerification;