import { setCity, setState, setAadhar, setPincode, setDigioLokerData } from '../../actions/kyc/step2.action';

const mutableState = {
    city: '',
    state: '',
    aadhar: '',
    pincode: '',
    jsonData : ''
};

const initState = {
    ...mutableState
};

export const kycStep2Reducer = (state = initState, action) => {
    switch (action.type) {
        case setCity:
            return {
                ...state,
                city: action.payload
            };
        case setState:
            return {
                ...state,
                State: action.payload
            }
        case setAadhar:
            return {
                ...state,
                aadhar: action.payload
            };
        case setPincode:
            return {
                ...state,
                pincode: action.payload
            }
        case setDigioLokerData:
            return {
                ...state,
                jsonData: action.payload
            }

        default:
            return state;
    };

};
