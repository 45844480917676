import React from 'react';
import CryptoJS from "crypto-js";


// Functional component
const Encripted = (Dynamic_arr) => {

    const client_code = localStorage.getItem("client_id");
    const client_token = localStorage.getItem("client_token");
    var CryptoJSAesJson = {
        stringify: function (cipherParams) {
            var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
            return cipherParams;
        }
    }
    var key = '475681656b2d6ed5bcb51d6dd772fa4f';

    console.log(Dynamic_arr, 'dynamic array');
    if (Dynamic_arr.length > 0) {
        var enc_data = '';
        Dynamic_arr.forEach((item, index) => {
            for (const [key, value] of Object.entries(item)) {
                enc_data += `&${key}=${value}`;
            }
        });
    } else {
        var enc_data = Dynamic_arr;
    }
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(enc_data), key, { format: CryptoJSAesJson }).toString();
    var client_code_new = btoa(encrypted);

    return client_code_new

}

export default Encripted;