import React from 'react';
import ReactDOM from 'react-dom';
import { Modal} from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import {BankNotVerifiedModalClose } from '../../redux/actions/Modal';


function BankAccountCouldNotVerified(){
  const dispatch = useDispatch();
  const modalStatus = useSelector( state => state?.modalToggle || {});
  const closeModal =()=>{dispatch(BankNotVerifiedModalClose())}

  return ReactDOM.createPortal(<>
  <Modal show={modalStatus.bankNotVerified} >
  <div className="" id="BankAccountCouldNotVerified">
  <div className="modal-dialog mw-445 modal-dialog-centered">
    <div className="modal-content p-3">
      <div className="modal-header bb-none">
        <h4 className="modal-title-4  block-center">
          Oops, couldn’t verify your <br /> Bank Account
        </h4>
        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} />
      </div>
      <div className="modal-body pt-0">
        <p className="para-12 text-center  block-center mw-250">
          No worries, we will ask you to upload your banking document as proof
          in the last step.
        </p>{" "}
        <p className="para-12 text-center mb-5">
          Please wait for a few seconds.
        </p>
        <img
          src="./images/bank-verification-declined.svg"
          className="img-center"
          alt="image"
          
        />
      </div>
    </div>
  </div>
</div>
    </Modal>
    </>,document.getElementById('portal'))
}
export default BankAccountCouldNotVerified;