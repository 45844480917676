import { startLoader, stopLoader,SetGlobalErrorMessage,RESET_STATE } from '../actions/app.action';

const initState = {
	isLoading: false,
	// reset : false
};

export const appReducer = (state = initState, action) => {
	switch (action.type) {
	case startLoader:
		return {
			...state,
			isLoading: true
		};
	case stopLoader:
		return {
			...state,
			isLoading: false
		};
	// case RESET_STATE : 
	// return {
	// 	...state ,
	// 	reset : true
	// }
	default:
		return state;
	}
};
