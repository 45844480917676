import { useSelector } from 'react-redux';
import loader from '../../assets/gif/DarkTheme/Bigul-Logo_Loader-Animation_Cyan.gif';
export const Loader = () => {
    const isLoading = useSelector(state => state?.app?.isLoading || false);
    return (
        isLoading
            ? (
                <div className="opacity-[0.85] fixed inset-0 z-[999] bg-black site-loader-box">
                    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center">
                        <img src={loader} alt="" className='site-loader-img' />
                    </div>
                </div>
            )
            : null
    );
};