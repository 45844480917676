import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import StepsHeader from '../../Utils/StepsHeader';
import { commonPostApi, dkycApi, dkycApiHavingLoader, dkycApiWithLoader, esignApi } from '../../../ApiServices/common';
import { useDispatch, useSelector } from 'react-redux';
import { clientCodeAction } from '../../../redux/actions/kyc/step8.action';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import { clientCode } from './../../../redux/actions/kyc/step8.action';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';

let intervalId = null;
let AmbassadorIntervalId = null;
let urlAccessed = false;
let AmbassadorUrlAccessed = false;

function AadhaarESign() {
  store.dispatch(stopLoaderAction());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEkycCompleted, setIsEkycCompleted] = useState(false);
  const [isClientTypeGenerated, setIsClientTypeGenerated] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [buttonDesc, setButtonDesc] = useState('Proceed to Aadhaar eSign');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [pan, setPan] = useState('');
  const [clientType, setClientType] = useState('client');
  const [refcode, setRefCode] = useState(90);
  const [reftype, setReftype] = useState('BRANCH');
  const [Dob, setDob] = useState('');
  const [Email, setEmail] = useState('');
  const [PartnerCode, setPartnerCode] = useState('');
  const [failureCount, setFailureCount] = useState(0);
  // Create a Date object for today's date
  const currentDate = new Date();

  // Convert the Date object to a string in ISO 8601 format (YYYY-MM-DD)
  const currentDateISO = currentDate.toISOString().split('T')[0];
  let partnerCode = '';

  //resume journey
  const initalStageforAfterEsign = '14';
  // useResumeJourneyPerStage(initalStageforAfterEsign);
  useCheckRequiredJourneyData(initalStageforAfterEsign);

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }
  const userDetails = useSelector(state => state?.userLogin || {});

  useEffect(() => {
    if (isEkycCompleted) {
      const payload = {
        mobile: userDetails?.mobileInput,
        esign_status: 1,
        utm_source: reftype || 'BRANCH',
        Referral_ID: refcode || 90
      }
      commonPostApi('/after-esign-web', payload, null).then((response) => {
        if (response.status === 200) {
          navigationStage(response?.user_data?.stage);
          // navigate('/welcome-page')
        }
        else {
          setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
        }
      }
      ).catch((error) => {
        setErrorMsg(error);
      })
    }
  }, [isEkycCompleted, refcode, reftype])

  const PDFviewerHandler = () => {
    const beforeEsignData = {
      mobile: userDetails.mobileInput,
      utmGetData: []
    }
    commonPostApi('/before-esign-web', beforeEsignData, null)
      .then((response) => {
        if (response.status === 200) {
          const getDetailsPostData = { pan: response?.user_data?.pan };
          dkycApi('/getDetails', getDetailsPostData, 'application/x-www-form-urlencoded')
            .then((response) => {
              if (response.success === true) {
                let clientId = response?.data[0]?.ClientId;
                const getEtradePostData = {
                  ClientId: clientId,
                  IsESign: "No",
                  IsType: "equity",
                  view_html: '1'
                }
                dkycApiHavingLoader('/GetESignMyetrade', getEtradePostData, 'application/x-www-form-urlencoded').then((data)=>{
                  const url = data?.data?.PdfPath;
                  const newTab = window.open(url, '_blank');
                  if (newTab) {
                    newTab.focus();
                    urlAccessed = true;
                  } else {
                    // If the browser blocked the pop-up, provide a message to the user
                    alert('Please allow pop-ups to download the PDF.');

                  }
                })
              }
            })
            .catch((error) => {
              setErrorMsg(error ? error : 'Something went wrong!!');
              store.dispatch(stopLoaderAction());
            })
        }
        else {
          setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
          store.dispatch(stopLoaderAction());
        }
      })
      .catch((error) => {
        setErrorMsg(error);
        store.dispatch(stopLoaderAction());
      })
  }

  const onChangeHandlerNext = async () => {
    setErrorMsg('');
    const beforeEsignData = {
      mobile: userDetails.mobileInput,
      utmGetData: []
    }
    //before e-sign api calling
    // try {
    commonPostApi('/before-esign-web', beforeEsignData, null)
      .then((response) => {
        if (response.status === 200) {
          setButtonDisable(true);
          setButtonDesc("Please wait...");
          //if client type == partner call segmentpartnerInfo
          // on success partner code 
          let clientType = response?.user_data?.client_type;
          setReftype(response?.user_data?.reftype);
          setRefCode(response?.user_data?.refcode);
          let reftype = response?.user_data?.reftype;
          let refcode = response?.user_data?.refcode;
          let fname = response?.user_data?.fname;
          let pan = response?.user_data?.pan;
          if (clientType == 'partner') {
            //default value given for now on 9/11/2023 by shoeib sir
            const PostData = {
              PanNo: pan,
              Fname: fname,
              Reftype: reftype,
              Refcode: refcode,
              channel: 'BIGUL'
            }
            dkycApi('/UpdateSegmentPartnerInfo', PostData, 'application/x-www-form-urlencoded')
              .then((response) => {
                // if(response.success == true){

                // }
                setPartnerCode(response?.data?.PartnerCode);
                partnerCode = response?.data?.PartnerCode;
              })
              .catch((err) => { setErrorMsg(err); return; })
          }
          const getDetailsPostData = { pan: response?.user_data?.pan };
          dkycApi('/getDetails', getDetailsPostData, 'application/x-www-form-urlencoded')
            // esignApi('/getDetails', getDetailsPostData ,'application/x-www-form-urlencoded')
            .then((response) => {
              if (response.success === true) {
                let clientId = response?.data[0]?.ClientId;
                const Email = response?.data[0]?.Email;
                const Dob = response?.data[0]?.BirthDate;
                const address = response?.data[0]?.PRAddr1 + response?.data[0]?.PRAddr2 + response?.data[0]?.PRAddr3;
                const Fname = response?.data[0]?.FName;
                console.log(Fname, 'Fname');
                const BankIFSC = response?.data[0]?.BankIFSC;
                const BankName = response?.data[0]?.BankName;
                const BankAccountNumber = response?.data[0]?.BankAccountNumber;
                const clientCode = response?.data[0]?.LDCode;
                const pan = response?.data[0]?.PanNo;
                const AadhaarNo = response?.data[0]?.AadhaarNo;
                const getEtradePostData = {
                  ClientId: clientId,
                  IsESign: "Yes",
                  IsType: "equity",
                  view_html: '1'
                }
                const EsignInterval = () => {
                  // callEtradeAPI(getEtradePostData);
                  dkycApiHavingLoader('/GetESignMyetrade', getEtradePostData, 'application/x-www-form-urlencoded')
                    // esignApi('/GetESignMyetrade', getEtradePostData)
                    .then((data) => {
                      const url = data?.data?.URLL;
                      const isDone = data?.success;
                      const esignMessage = data?.message;

                      if (isDone === true) {
                        // Keep calling the API every 5 seconds
                        if (!urlAccessed) {
                          const newTab = window.open(url, '_blank');
                          if (newTab) {
                            newTab.focus();
                            urlAccessed = true;
                          } else {
                            // If the browser blocked the pop-up, provide a message to the user
                            alert('Please allow pop-ups to download the PDF.');
                            setButtonDisable(false);
                            clearInterval(intervalId);
                            store.dispatch(stopLoaderAction());
                            setButtonDesc("Proceed to Aadhaar eSign");

                          }
                        }
                        intervalId = setInterval(EsignStatusCheck, 10000);
                      }
                      else if (isDone === false && esignMessage === 'ESign Already Done.') {
                        if (clientType == 'partner') {
                          clearInterval(intervalId);
                          const PostAmbassadorData = {
                            ClientArray: {
                              dsa_name: Fname?.trim(),
                              date_of_enrollment: currentDateISO?.trim(),
                              dsa_address: address,
                              dsa_email: Email?.trim(),
                              dob: Dob?.trim(),
                              mobile: userDetails?.mobileInput,
                              pan: pan?.trim(),
                              adhar_number: AadhaarNo || '',
                              bank_name: BankName || '',
                              account_number: BankAccountNumber || '',
                              ifsc: BankIFSC || '',
                              ba_code: partnerCode,
                              ba_client_code: partnerCode
                            },
                            IsESign: "Yes",
                            IsType: "Equity + eSign"
                          }

                          // Convert PostAmbassadorData to form data format
                          const formData = new FormData();

                          // Loop through ClientArray and append each key-value pair to formData
                          for (const key in PostAmbassadorData.ClientArray) {
                            formData.append(`ClientArray[${key}]`, PostAmbassadorData.ClientArray[key]);
                          }

                          // Append IsESign and IsType to formData
                          formData.append("IsESign", PostAmbassadorData.IsESign);
                          formData.append("IsType", PostAmbassadorData.IsType);

                          const ESignMyetradeAmbassadorInterval = () => {
                            dkycApiWithLoader('/GetESignMyetradeAmbassador', formData, 'multipart/form-data')
                              .then((data) => {
                                const url = data?.data?.URLL;
                                const isAmbassadorDone = data?.success;
                                const esignMessage = data?.message;

                                if (isAmbassadorDone === true) {
                                  // Keep calling the API every 5 seconds
                                  if (!AmbassadorUrlAccessed) {
                                    window.open(url, '_blank');
                                    AmbassadorUrlAccessed = true;
                                  }
                                }
                                else if (isAmbassadorDone === false && esignMessage === 'ESign Already Done.') {
                                  clearInterval(AmbassadorIntervalId);
                                  setIsEkycCompleted(true);
                                  return isAmbassadorDone;
                                }
                                else {
                                  // Stop calling the API
                                  clearInterval(AmbassadorIntervalId);
                                  setIsEkycCompleted(false);
                                  setErrorMsg(esignMessage);
                                  return (isAmbassadorDone)
                                }
                              })
                              .catch((error) => {
                                clearInterval(AmbassadorIntervalId);
                                setErrorMsg(error);
                                store.dispatch(stopLoaderAction());
                                return;

                              })
                          }
                          ESignMyetradeAmbassadorInterval();
                          AmbassadorIntervalId = setInterval(ESignMyetradeAmbassadorInterval, 10000);

                        } else {
                          // Stop calling the API
                          clearInterval(intervalId);
                          setIsEkycCompleted(true);
                          return isDone;
                        }
                      }
                      else {
                        store.dispatch(stopLoaderAction());
                        setButtonDisable(false);
                        setButtonDesc("Proceed to Aadhaar eSign");
                        // Stop calling the API
                        clearInterval(intervalId);
                        setIsEkycCompleted(false);
                        setErrorMsg(esignMessage);
                        return isDone;
                      }
                    })
                    .catch((error) => {
                      clearInterval(intervalId);
                      setErrorMsg(error);
                      store.dispatch(stopLoaderAction());
                      setButtonDisable(false);
                      setButtonDesc("Proceed to Aadhaar eSign");
                    })
                }
                EsignInterval();
                const EsignStatusCheck = () => {
                  const payload = {
                    mobile: userDetails?.mobileInput,
                    esign:1
                  };
                  dkycApiWithLoader('/getKycJourney', payload, 'multipart/form-data')
                    .then((response) => {
                      if (response.success === true) {
                        if (response.data[0].esign_status === '1') {
                          // EsignInterval();
                          if (clientType == 'partner') {
                            clearInterval(intervalId);
                            const PostAmbassadorData = {
                              ClientArray: {
                                dsa_name: Fname?.trim(),
                                date_of_enrollment: currentDateISO?.trim(),
                                dsa_address: address?.trim(),
                                dsa_email: Email?.trim(),
                                dob: Dob?.trim(),
                                mobile: userDetails?.mobileInput,
                                pan: pan?.trim(),
                                adhar_number: AadhaarNo || '',
                                bank_name: BankName || '',
                                account_number: BankAccountNumber || '',
                                ifsc: BankIFSC || '',
                                ba_code: partnerCode,
                                ba_client_code: partnerCode
                              },
                              IsESign: "Yes",
                              IsType: "Equity + eSign"
                            }

                            // Convert PostAmbassadorData to form data format
                            const formData = new FormData();

                            // Loop through ClientArray and append each key-value pair to formData
                            for (const key in PostAmbassadorData.ClientArray) {
                              formData.append(`ClientArray[${key}]`, PostAmbassadorData.ClientArray[key]);
                            }

                            // Append IsESign and IsType to formData
                            formData.append("IsESign", PostAmbassadorData.IsESign);
                            formData.append("IsType", PostAmbassadorData.IsType);

                            const ESignMyetradeAmbassadorInterval = () => {
                              dkycApiWithLoader('/GetESignMyetradeAmbassador', formData, 'multipart/form-data')
                                .then((data) => {
                                  const url = data?.data?.URLL;
                                  const isAmbassadorDone = data?.success;
                                  const esignMessage = data?.message;

                                  if (isAmbassadorDone === true) {
                                    // Keep calling the API every 5 seconds
                                    if (!AmbassadorUrlAccessed) {
                                      window.open(url, '_blank');
                                      AmbassadorUrlAccessed = true;
                                    }
                                  }
                                  else if (isAmbassadorDone === false && esignMessage === 'ESign Already Done.') {
                                    clearInterval(AmbassadorIntervalId);
                                    setIsEkycCompleted(true);
                                    return isAmbassadorDone;
                                  }
                                  else {
                                    // Stop calling the API
                                    clearInterval(AmbassadorIntervalId);
                                    setIsEkycCompleted(false);
                                    setErrorMsg(esignMessage);
                                    return (isAmbassadorDone)
                                  }
                                })
                                .catch((error) => {
                                  clearInterval(AmbassadorIntervalId);
                                  setErrorMsg(error);
                                  store.dispatch(stopLoaderAction());
                                  return;

                                })
                            }
                            ESignMyetradeAmbassadorInterval();
                            AmbassadorIntervalId = setInterval(ESignMyetradeAmbassadorInterval, 10000);

                          } else {
                            // Stop calling the API
                            clearInterval(intervalId);
                            setIsEkycCompleted(true);
                            // return isDone;
                          }
                        }
                      } else {
                      }
                    })
                    .catch((error) => {
                      console.error('API request failed', error);

                      // Increment failure count
                      setFailureCount(prevCount => prevCount + 1);
                      // console.log(prevCount ,'failureCohhunt');
                      console.log(failureCount ,'failureCount') ;
                      // Stop API calls if failure count exceeds 3
                      if (failureCount >= 3) {
                        clearInterval(intervalId);
                        setIsEkycCompleted(false);
                        setErrorMsg(error);
                        console.error('API calls stopped due to repeated failures');
                      }
                    })
                }
                // intervalId = setInterval(EsignInterval, 10000);
                // intervalId = setInterval(EsignStatusCheck, 10000);
              } else {
                setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
                store.dispatch(stopLoaderAction());
                setButtonDisable(false);
                setButtonDesc("Proceed to Aadhaar eSign");
              }
            })
            .catch((error) => {
              setErrorMsg(error);
              store.dispatch(stopLoaderAction());
              setButtonDisable(false);
              setButtonDesc("Proceed to Aadhaar eSign");
            })
        }
        else {
          setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
          store.dispatch(stopLoaderAction());
        }
      })
      .catch((error) => {
        setErrorMsg(error);
        store.dispatch(stopLoaderAction());
      })

  }
  return (<>
    <section className="main-wraper">
      <StepsHeader step={8} />
      <AsideWallpaper
        color={'blue'}
        descriptionHeader={''}
        backGroundImageUrl={"./images/aadhar-esign.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap mw-468">
            <div className="">
              <h1 className="title-1 mb-2">Aadhaar eSign </h1>
              <p className="para-1">
                Aadhaar eSign digitally signs all the documents you submitted for
                KYC and account opening with Bigul.
              </p>
              <p className="para-1">
                Your Aadhaar must be linked to your mobile number to receive and
                confirm OTP.
              </p>
            </div>
            <div className="mt-1 pt-0 pt-md-5 mt-md-5">
              <p className="para-1 mb-0 pb-0 pb-md-4 font-400">
                <a href="#" className="text-decoration color-dark-green">
                  <img
                    src="./images/digi-locker-icon.svg"
                    className="mr-3"
                    alt="img"
                  />{" "}
                  Don’t worry, your data is 100% safe!
                </a>
              </p>
              <a href='#' onClick={PDFviewerHandler}>View KYC form before eSign</a>
            </div>
          </div>
          {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          <div className="btn-action-box">
            <button className="btn btn-primary btn-full mb-2" onClick={onChangeHandlerNext} disabled={buttonDisable}>
              {buttonDesc}
            </button>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default AadhaarESign;