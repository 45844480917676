import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { commonPostApi, dkycApi, dkycApiHavingLoader, dkycApiWithLoader, verifyBankAccountApi } from '../../../ApiServices/common';
import { accountHolderNameAction, getBankAccountNumberAction, getConfirmAccountNumberAction } from '../../../redux/actions/kyc/step5.action';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import BankAccountVerification1Deposit from '../../Modals/BankAccountVerification1Deposit';
import BankAccountVerified from '../../Modals/BankAccountVerified';
import BankAccountCouldNotVerified from '../../Modals/BankAccountCouldNotVerified';
import { BankNotVerifiedModalClose, BankNotVerifiedModalOpen, BankVerifiedModalClose, BankVerifiedModalOpen, PennyDropSuccessModalClose, PennyDropSuccessModalOpen, setBankNotVerifiedModalClose, } from '../../../redux/actions/Modal';
import { store } from '../../../redux/store';
import StepsHeader from '../../Utils/StepsHeader';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import ReactInputMask from 'react-input-mask';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';

// import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';

function VerifyYourBankAccount() {
  store.dispatch(stopLoaderAction());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmBankAcc, setConfirmBankAcc] = useState('');
  const [accountError, setAccountError] = useState(null);
  const [isConfirmBankValid, setIsConfirmBankValid] = useState(false);
  const [confirmBankcolor, setConfirmBankColor] = useState(true);
  const [isBankValid, setIsBankValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);

  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const kycStep3 = useSelector(state => state?.ekycStep3 || {});
  const [pan, setPan] = useState(kycStep1.panInput.value);

  const kycStep5 = useSelector(state => state?.ekycStep5);
  const userLogin = useSelector(state => state?.userLogin || {});
  const userName = kycStep1?.userData?.fullName;
  const mobile = userLogin?.mobileInput;

  //for penny drop status update
  const [penny_Verified, setPennyVerified] = useState('false');
  let pennyVerified = false;

  const [pennyId, setPennyId] = useState('--');
  const [verificationTime, setPennyVerificationTime] = useState('--');
  const [pennyHolderName, setPennyHolderName] = useState('--');
  const [panHolderFullName, setPanHolderFullName] = useState('');

  //for geo location 
  const fileName = 'downloaded_image.jpg';
  const [imageData, setImageData] = useState(null);
  const [imageDataForKRA, setImageDataForKRA] = useState(null);
  const divRef = useRef(null);

  const [imageDigioFile, setImageDigioFile] = useState('');
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [address, setAddress] = useState('');
  const [accuracy, setAccuracy] = useState('');
  const [idType, setIdType] = useState('');
  const [faceMatch, setFaceMatch] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [GeoStatus, setGeoStatus] = useState('');
  const [matched, setMatched] = useState('');
  const [fileId, setFileID] = useState('');



  const ErrorHandler = (error) => {
    return 'Something went wrong!';
  }
  // Function to calculate match percentage using Levenshtein Distance
  const calculateMatchPercentage = (s1, s2) => {
    const longer = s1?.length > s2?.length ? s1 : s2;
    const shorter = s1?.length > s2?.length ? s2 : s1;
    const longerLength = longer?.length;

    if (longerLength === 0) return 100;

    const distance = calculateLevenshteinDistance(longer, shorter);
    return Math.round(((longerLength - distance) / longerLength) * 100);
  };

  // Function to calculate Levenshtein Distance
  const calculateLevenshteinDistance = (s1, s2) => {
    const matrix = [];
    let i, j;

    for (i = 0; i <= s2.length; i++) {
      matrix[i] = [i];
    }

    for (j = 0; j <= s1.length; j++) {
      matrix[0][j] = j;
    }

    for (i = 1; i <= s2.length; i++) {
      for (j = 1; j <= s1.length; j++) {
        if (s2.charAt(i - 1) === s1.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1,
            Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
          );
        }
      }
    }

    return matrix[s2.length][s1.length];
  };

  //ifscCode
  const IFSC = kycStep5.ifscCode;
  const bank_name = kycStep5?.bankName;
  const bank_branch = kycStep5?.bankBranchName;
  const bank_account_number = kycStep5?.bankAccountNumber;
  // const isKraApproved = kycStep3?.isKraApproved;
  // console.log(isKraApproved, 'kraapproved');

  //resume journey
  const initalStageforBankDetails = '8';
  // useResumeJourneyPerStage(initalStageforBankDetails);
  useCheckRequiredJourneyData(initalStageforBankDetails);

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }

  useEffect(() => {
    const bank_account_number = kycStep5?.bankAccountNumber;
    const validBankAcc = validateBankAccount(bank_account_number);
    if (validBankAcc || bank_account_number?.length == 0) {
      setIsBankValid(true);
    }
    else {
      setIsBankValid(false);
    }
  }, [isBankValid])

  const handleAccountNumberChange = (e) => {
    setErrorMsg('');
    setConfirmBankAcc('');
    setIsConfirmBankValid(false);
    const inputValue = e.target.value.replace(/\D/g, '');
    const validBankAcc = validateBankAccount(inputValue);
    if (validBankAcc || inputValue?.length == 0) {
      setIsBankValid(true);
      setAccountError(null);
    }
    else {
      setIsBankValid(false);
      setAccountError('The Bank Account Number should range from 9 to 16 digits.');
    }
    dispatch(getBankAccountNumberAction(e.target.value));

  }
  const validateBankAccount = (str) => {
    const regex = /^\d{9,18}$/;
    return regex.test(str);
  };

  const handleConfirmAccountNumberChange = (e) => {
    const value = e.target.value;
    const confirmBankAcc = value.replace(/\D/g, '').slice(0, 16);
    if (confirmBankAcc === bank_account_number && confirmBankAcc?.length > 0) {
      setIsConfirmBankValid(true);
      setConfirmBankColor(true);
    }
    else {
      setIsConfirmBankValid(false);
      setConfirmBankColor(false);
    }
    setConfirmBankAcc(confirmBankAcc);
  };
  const BankButtonEnable = () => {
    if (isBankValid == true && isConfirmBankValid == true) { return true; }
    else { return false; }
  }
  const PennyDrop = async () => {
    setErrorMsg('');
    let bankData = {
      mobile: mobile || '',
      ifsc: IFSC || '',
      account: bank_account_number || '',
      accountHolderName: '',
      bankName: bank_name || '',
      bankAddress: bank_branch || ''
    }
    try {
      const payload = {
        beneficiary_account_no: bank_account_number,
        beneficiary_ifsc: IFSC
      };
      const response = await verifyBankAccountApi(payload);
      const bankHolderName = response?.beneficiary_name_with_bank;
      const matchPercentage = calculateMatchPercentage(userName.toUpperCase(), bankHolderName.toUpperCase());
      console.log(matchPercentage,'matchPercentage');
      setPennyId(response?.id);
      setPennyVerificationTime(response?.verified_at);
      setPennyHolderName(response?.beneficiary_name_with_bank);
      setPennyVerified(response?.verified ? 'true' : "false");
      pennyVerified = response?.verified;
      const penny_verified = response?.verified && (matchPercentage >= 50);
  
      //send penny drop response to backend
      const pennyDropStatusPayload = {
        mobile: mobile,
        status: penny_verified ? '1' : '2',
        nameMatchPercentage : matchPercentage,
        pennydrop_data: JSON.stringify(response)
      }
      dkycApi('/penny-drop-status', pennyDropStatusPayload, 'application/json')
        .then((response) => {
          if (response?.success == true) {
            // const bankHolderName = response?.beneficiary_name_with_bank; 
            bankData = {
              mobile: mobile,
              ifsc: IFSC,
              account: bank_account_number,
              accountHolderName: bankHolderName || '',
              bankName: bank_name,
              bankAddress: bank_branch
            }
            dispatch(accountHolderNameAction(bankHolderName));
            penny_verified ? dispatch(PennyDropSuccessModalOpen()) : dispatch(BankNotVerifiedModalOpen());
            let timer = setTimeout(() => {
              penny_verified ? dispatch(PennyDropSuccessModalClose(false)) : dispatch(BankNotVerifiedModalClose(false));
              // isKraApproved ? saveKRAData(bankData) : uploadImage(bankData); //it is not working,saveBankDatais working before 
              saveKRAData(bankData) //digio changes
            }, 2000);
            return () => {
              clearTimeout(timer);
            }

          }
          else {
            setErrorMsg('Penny drop status details are not fetched for the customer');
          }
        }
        ).catch((err) => {
          setErrorMsg('Penny drop status details are not fetched for the customer');
        })
    }
    catch (err) {
      const pennyDropStatusPayload = {
        mobile: mobile,
        status: '2',
        pennydrop_data: null
      }
      dkycApiHavingLoader('/penny-drop-status', pennyDropStatusPayload, 'application/json')
        .then((response) => {
          if (response?.success == true) {
            // const bankHolderName = response?.beneficiary_name_with_bank;
            bankData = {
              mobile: mobile,
              ifsc: IFSC,
              account: bank_account_number,
              accountHolderName: '',
              bankName: bank_name,
              bankAddress: bank_branch
            }
            dispatch(BankNotVerifiedModalOpen());
            let timer = setTimeout(() => {
              dispatch(BankNotVerifiedModalClose(false));
              // isKraApproved ? saveKRAData(bankData) : uploadImage(bankData); //it is not working,saveBankDatais working before 
              saveKRAData(bankData) //digio changes
            }, 2000);
            return () => {
              clearTimeout(timer);
            }

          }
          else {
            setErrorMsg('Penny drop status details are not fetched for the customer');
          }
        }
        ).catch((err) => {
          setErrorMsg('Penny drop status details are not fetched for the customer');
        })
    }
  }

  useEffect(() => {
    const fetchData = () => {
      const mobile = userLogin?.mobileInput;
      const payload = {
        mobile: mobile
      };

      dkycApiHavingLoader('/getKycJourney', payload, 'multipart/form-data').then((response) => {
        const jsonData = response?.data[0]?.json_data;
        const json_data = JSON.parse(jsonData);
        const pan = response?.data[0]?.pan;
        const file_id = json_data.actions[1]?.file_id;
        setPan(response?.data[0]?.pan || kycStep1?.panInput?.value);
        setPanHolderFullName(response?.data[0]?.full_name)
        setLatitude(json_data?.actions[1]?.sub_actions[0]?.details?.latitude);
        setLongitude(json_data?.actions[1]?.sub_actions[0]?.details?.longitude);
        setAccuracy(json_data?.actions[1]?.sub_actions[0]?.details?.accuracy);
        setAddress(json_data?.actions[1]?.sub_actions[0]?.details?.address);
        setGeoStatus(json_data?.actions[1]?.face_match_status);
        setDateTime(json_data?.actions[1]?.completed_at);
        setFaceMatch(json_data?.actions[1]?.face_match_result?.confidence);
        setIdType(json_data?.actions[1]?.type);
        setMatched(json_data?.actions[1]?.face_match_result?.match_result);

        const payload = {
          mobile: mobile,
          file_id: file_id,
          pan: pan
        }
        const saveImageToLocal = (imagePath, fileName) => {
          // Fetch the image using the provided path
          fetch(imagePath)
            .then((response) => response.blob())
            .then((blob) => {

              // FileSaver.saveAs(blob, 'downloaded_image.jpg');
              // Use FileSaver to save the blob as a file
              const reader = new FileReader();
              reader.onload = () => {
                setImageData(reader.result);
              };
              reader.readAsDataURL(blob);
            })
            .catch((error) => {
              console.error('Error saving image:', error);
            });
        };

        // commonPostApi('/digio-image-user-path', payload, null)
        //   .then((response) => {
        //     if (response?.status == 200) {
        //       setImageDigioFile(response?.imagePath);
        //       saveImageToLocal(response?.imagePath, fileName);
        //     }
        //   })
        //   .catch((err) => {

        //     //  setErrorMsg("Your image can not be fetched from digio."); return;
        //      })

      }).catch((err) => { console.log("Error while fetching your data. Please try again later."); })
    }
    fetchData();
  }, []);


  // get the screenshot of the geo and penny data
  const uploadImage = (bankData) => {
    if (divRef.current) {
      html2canvas(divRef.current).then((canvas) => {
        // Convert the captured canvas to a data URL
        const imgData = canvas.toDataURL('image/png');
        // Now you can upload imgData to your server or do something else with it
        canvas.toBlob((blob) => {
          if (blob) {
            // Create a FormData object to prepare for uploading
            const data = new FormData();
            // Append other fields to the FormData
            data.append('Document', 'Other');
            data.append('DocumentType', 'NA');
            data.append('CreatedBy', '2261');
            data.append('PanNo', pan);
            data.append('step', '8');
            data.append('DocumentImages[]', blob, 'image.jpg');
            // You can change the filename here
            // Example: Upload the image using the fetch API
            dkycApiHavingLoader('/uploadfiles', data, 'multipart/form-data', ErrorHandler)
              .then((response) => {
                if (response?.success === true) {
                  commonPostApi('/save-bank-details-web', bankData, null)
                    .then((response) => {
                      if (response.status === 200) {
                        if (pennyVerified) {
                          dispatch(BankVerifiedModalOpen());
                          let timer = setTimeout(() => {
                            dispatch(BankVerifiedModalClose(false));
                            navigationStage(response?.user_data?.stage);
                            // navigate('/segment-details');
                          }, 2000);
                          return () => {
                            clearTimeout(timer);
                          }
                        } else {
                          navigationStage(response?.user_data?.stage);
                        }

                      }
                      else {
                        setErrorMsg(response?.msg ? response?.msg : 'Penny drop details are not uploaded for the customer');
                      }

                    }).catch((err) => {
                      setErrorMsg(err);
                    })
                }
                else {
                  setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!');
                  return;
                }
              }).catch((err) => {
                console.log('Penny drop details are not uploaded for the customer');
                setErrorMsg(err);
              })
          }
        }, 'image/png', 0.7); // Specify the desired image format ('image/jpeg' for JPG, 'image/png' for PNG)
      });

    }
  };
  const saveKRAData = (bankData) => {
    commonPostApi('/save-bank-details-web', bankData, null)
      .then((response) => {
        if (response.status === 200) {
          if (pennyVerified) {
            dispatch(BankVerifiedModalOpen());
            let timer = setTimeout(() => {
              dispatch(BankVerifiedModalClose(false));
              navigationStage(response?.user_data?.stage);
              // navigate('/segment-details');
            }, 2000);
            return () => {
              clearTimeout(timer);
            }
          } else {
            navigationStage(response?.user_data?.stage);
          }

        }
        else {
          setErrorMsg(response?.msg ? response?.msg : 'Penny details are not uploaded for the customer');
        }

      }).catch((err) => {
        setErrorMsg(err);
      })

  }



  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader step={4} />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/bank-verified.svg"}
      />
      <div className="body-content-box verify-bank">
        <div className="login-box">

          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1">Verify your Bank Account </h1>
            </div>
            <div className="mw-400 block-center">
              <p className="para-1">
                We found your Bank! Now enter your account number to verify it.
              </p>
              <div className="bank-detail-box">
                <p className="bank-detai-name">
                  {bank_name}
                </p>
                <p className="bank-detail-branch">Branch : {bank_branch} </p>
                <p className="bank-detail-ifsc">IFSC : {IFSC} </p>
              </div>
              <a href="/find-bank-details" className='d-inline-block font-14 blue mt-2'>Not Your Bank ? Re-Enter IFSC</a>
            </div>
            <div className="input-box mt-2">
              {accountError && <div className='input-error-msg text-center font-12'>{accountError}</div>}
              <ReactInputMask
                type={passwordShow ? 'text' : "password"}
                mask="9999999999999999"
                className="input-form text-center mb-3"
                placeholder="Enter your Bank Account Number"
                value={bank_account_number}
                maskChar={null}
                onPaste={(event) => { event.preventDefault() }}
                onChange={handleAccountNumberChange}
              />
              {passwordShow && <i className="ri-eye-line" onClick={() => setPasswordShow(!passwordShow)} > </i>}
              {!passwordShow && <i className="ri-eye-off-line" onClick={() => setPasswordShow(!passwordShow)} ></i>}
              {/* <input
              type="password"
              className="input-form text-center mb-3"
              placeholder="Enter your Bank Account Number"
              value={bank_account_number}
              onChange={handleAccountNumberChange}
              onPaste={(event) => { event.preventDefault() }}
              onKeyDown={(e) => {
                // Allow only numeric keys, backspace, and delete
                if (e.key !== 'Backspace' && e.key !== 'Delete' && isNaN(Number(e.key))) {
                  e.preventDefault();
                }
              }}
              maxLength={16}
            /> */}
              <input
                type="tel"
                className={`input-form text-center mb-3 ${isConfirmBankValid ? "input-verified" : " "} ${confirmBankcolor ? "" : "not-verified"}`}
                onChange={handleConfirmAccountNumberChange}
                placeholder="Confirm your Bank Account Number"
                value={confirmBankAcc}
                onPaste={(event) => { event.preventDefault() }}
                maxlength={16}
              />
            </div>
          </div>
          {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          <div className="btn-action-box">
            <button
              className={`btn btn-${BankButtonEnable() ? 'primary' : 'secondary'} btn-full mb-2`}
              data-bs-toggle="modal"
              data-bs-target="#Modal-4" onClick={PennyDrop} disabled={!BankButtonEnable()}
            >
              Verify your bank account
            </button>
          </div>
        </div>
        <div ref={divRef} style={{
          opacity: '1',
          position: 'absolute',
          left: '-9999px',
          width: '300px',
          fontSize: '10px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }} className=''>
          <table >
            <tbody>
              <tr>
                <td width="49%">
                  <div style={{}} id="userImageData" >
                    <span>
                      {/* <img style={{ width: "100%" }} src={fileName} /> */}
                      <img style={{ width: '100%' }} src={imageData} />
                      {/* <img style={{ width: "100%" }} src={imageDigioFile} /> */}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="100%" style={{ padding: 15 }}>
                  <div
                    style={{
                      display: "flex",
                      margin: "5px 0px",
                      justifyContent: "space-between",
                      borderBottom: "2px solid blue",
                      paddingBottom: 5
                    }}
                  >
                    <span>
                      <h6>Geo Location Info</h6>
                      <p>
                        ID Type - {idType}
                      </p>
                      <p>
                        Face Match -
                        <span style={{ color: "green" }}>
                          {matched}{faceMatch}
                        </span>
                      </p>
                    </span>
                    <span />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >

                    <span>Address -</span>
                    <span style={{ marginLeft: 10, wordWrap: 'break-word', wordBreak: 'break-all' }} >
                      {address}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Latitude -</span>
                    <span>
                      <b>
                        {latitude}
                      </b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Accuracy -</span>
                    <span>
                      {accuracy}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Longitude -</span>
                    <span>
                      <b>
                        {longitude}
                      </b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Status -</span>
                    <span style={{ color: "green" }}>
                      {GeoStatus}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Date Time -</span>
                    <span style={{ color: "green" }}>
                      {dateTime}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="100%" style={{ padding: 15 }}>
                  <div
                    style={{
                      display: "flex",
                      margin: "5px 0px",
                      justifyContent: "space-between",
                      borderBottom: "2px solid blue",
                      paddingBottom: 5
                    }}
                  >
                    <span>
                      <h6>Penny Drop Info</h6>
                    </span>
                    <span />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Id -</span>
                    <span>
                      {pennyId}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Verified Status -</span>
                    <span>
                      <p>{penny_Verified}</p>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Date &amp; Time -</span>
                    <span>
                      {verificationTime}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Beneficiary Name With Bank -</span>
                    <span>
                      <b style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                        {pennyHolderName}
                      </b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between"
                    }}
                  >
                    <span>Pan-Card Full Name -</span>
                    <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} >
                      {userName ? userName : panHolderFullName}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section >
    <BankAccountVerification1Deposit />
    <BankAccountVerified />
    <BankAccountCouldNotVerified />
  </>)

}
export default VerifyYourBankAccount;