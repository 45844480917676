
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import { useEffect, useState } from 'react';
import IndexPage from './Components/Pages/MobileEmailVerify/IndexPage';
import MobileVerification from './Components/Pages/MobileEmailVerify/MobileVerification';
import EmailInput from './Components/Pages/MobileEmailVerify/EmailInput';
import EmailVerification from './Components/Pages/MobileEmailVerify/EmailVerification';
import VerifyPanDetails from './Components/Pages/step1/VerifyPanDetails';
import HelloName from './Components/Pages/step1/HelloName';
import DigiLockerVerification from './Components/Pages/step2/DigiLockerVerification';
import PersonalDetails1 from './Components/Pages/step3/PersonalDetails1';
import OccupationDetails from './Components/Pages/step4/OccupationDetails';
import FindBankDetails from './Components/Pages/step5/FindBankDetails';
import VerifyBankDetails from './Components/Pages/step5/VerifyYourBankAccount';
import UploadBankProof from './Components/Pages/step5/UploadBankProof';
import SegmentDetails from './Components/Pages/step5/TradingAndInvestmentPreferences';
import UploadIncomeProof from './Components/Pages/step5/UploadIncomeProof';
import TakeASelfie from './Components/Pages/step6/TakeASelfie';
import YourPicture from './Components/Pages/step6/YourPicture';
import DrawYourSignature from './Components/Pages/step7/DrawYourSignature';
import AadharESign from './Components/Pages/step8/AadhaarESign';
import ThankYouForSigning from './Components/Pages/WelcomePages/ThankYouForSigning';
import WelcomeToBigulFamily from './Components/Pages/WelcomePages/WelcomeToBigulFamily';
import ClientAlreadyRegistered from './Components/Pages/WelcomePages/ClientAlreadyRegistered';
import YourComponent from './Components/Pages/WelcomePages/datepicker';
import RoadMapLogin from './Components/RoadMap/RoadMapLogin';
import { RoadMap } from './Components/RoadMap/RoadMap';
import { ProtectedRoute } from './Components/routes/ProtectedRoute';
import DigioPennyDrop from './Components/Pages/WelcomePages/digioPennyDrop';
import PanScreen from './Components/Pages/PanScreen';


function App() {
  const location = useLocation();
  const [journeyData, setJourneyData] = useState(null);
  const handleDataReceived = (data) => {
    setJourneyData(data);
    sessionStorage.setItem('journeyData', JSON.stringify(data));
    // Do whatever you want with the data here
  };
  useEffect(() => {
    const storedData = sessionStorage.getItem('journeyData');
    if (storedData) {
      setJourneyData(JSON.parse(storedData)); // Retrieve data from sessionStorage
    }
  }, [location]);
  return (
    <Routes >
      <Route path="/" Component={IndexPage} />

      <Route path="/mobile-verification"
        Component={MobileVerification}
      />
      <Route path='/email-input'
        element={<ProtectedRoute component={<EmailInput />} />}
      />
      <Route path="/email-verification"
        element={<ProtectedRoute component={<EmailVerification />} />}
      />
      <Route path="/pan-verification"
        element={<ProtectedRoute component={<VerifyPanDetails />} />}
      />
      <Route path='/name-confirm'
        element={<ProtectedRoute component={<HelloName />} />}
      />
      <Route path="/digiLocker-verification"
        element={<ProtectedRoute component={<DigiLockerVerification />} />}
      />
      <Route path='/personal-details-1'
        element={<ProtectedRoute component={<PersonalDetails1 />} />}
      />
      <Route path='/occupation-details'
        element={<ProtectedRoute component={<OccupationDetails />} />}
      />
      <Route path="/find-bank-details"
        element={<ProtectedRoute component={<FindBankDetails />} />}
      />
      <Route path='/Verify-bank-details'
        element={<ProtectedRoute component={<VerifyBankDetails />} />}
      />
      <Route path='/bank-proof'
        element={<ProtectedRoute component={<UploadBankProof />} />}
      />
      <Route path='/segment-details'
        element={<ProtectedRoute component={<SegmentDetails />} />}
      />
      <Route path='/income-proof'
        element={<ProtectedRoute component={<UploadIncomeProof />} />}
      />
      <Route path='/selfie'
        element={<ProtectedRoute component={<TakeASelfie />} />}
      />
      <Route path='/your-picture'
        element={<ProtectedRoute component={<YourPicture />} />}
      />
      <Route path='/draw-sign'
        element={<ProtectedRoute component={<DrawYourSignature />} />}
      />
      <Route path='/e-sign'
        element={<ProtectedRoute component={<AadharESign />} />}
      />
      <Route path='/thanks-page'
        element={<ProtectedRoute component={<ThankYouForSigning />} />}
      />
      <Route path='/welcome-page'
        element={<ProtectedRoute component={<WelcomeToBigulFamily />} />}
      />
      <Route path='/Client-exist'
        element={<ProtectedRoute component={<ClientAlreadyRegistered />} />}
      />
      <Route path='/kpd'
        element={<ProtectedRoute component={<YourComponent />} />}
      />
      <Route path='/dpd'
        element={<ProtectedRoute component={<DigioPennyDrop />} />}
      />
      <Route path="/pan-data-check" Component={PanScreen} />
      <Route path='/track-journey-roadmap'
        element={<RoadMap response={journeyData} />} />
      <Route path='/track-journey'
        element={<RoadMapLogin onDataReceived={handleDataReceived} />} />
    </Routes>

  );
}


export default App;