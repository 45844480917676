import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ component }) => {
	const userLogin = useSelector((state) => state?.userLogin || {});
	const mobile = userLogin.mobileInput;
	return mobile
		? component
		: <Navigate to="/" />;
};
