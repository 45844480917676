export default function AsideWallpaper(props){
    return(
        <aside className="sidebar-box">
        <div className={`sidebar-box-wrap bg-${props.color} bg-shape-white-line`}>
          <div className="sidebar-img-text">
            <p className="text-black">
              {props.descriptionHeader}
            </p>
          </div>
          <img
            src={props.backGroundImageUrl}
            alt={props.backGroundImageUrlAltText || "Image"}
            className={"sidebox-main-img"}
          />
          <div className="continue-on-mobile">
            <a>
              {/* <img src="images/continue-on-mobile.svg"/>
              Continue on Mobile{" "} */}
            </a>
          </div>
        </div>
      </aside>
    );
}