import React from 'react';
import ReactDOM from 'react-dom';
import { Modal} from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import {PennyDropSuccessModalClose } from '../../redux/actions/Modal';


function BankAccountVerification1Deposit() {
  const dispatch = useDispatch();
  const modalStatus = useSelector(state => state?.modalToggle || {});
  const closeModal = () => { dispatch(PennyDropSuccessModalClose()) }

  return ReactDOM.createPortal(<>
    <Modal show={modalStatus.pennyDrop} >
      <div className=" " id="BankAccountVerification1Deposit">
        <div className="modal-dialog mw-445 modal-dialog-centered">
          <div
            className="modal-content p-3"
            data-bs-toggle="modal"
            data-bs-target="#Modal-5"
          >
            <div className="modal-header bb-none">
              <h4 className="modal-title-4">Bank Account Verification</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal"   onClick={closeModal}/>
            </div>
            <div className="modal-body pt-0">
              <p className="para-12 text-center mw-250 block-center ">
                We are depositing ₹1 in your bank account to verify it.
              </p>
              <p className="para-12 text-center mb-5">
                {" "}
                Please wait for a few seconds.
              </p>
              <img
                src="./images/bank-account-1-credit.svg"
                className="img-center"
                alt="image"
              
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>, document.getElementById('portal'))
}
export default BankAccountVerification1Deposit;