export const setNationality = "SET_NATIONALITY";
export const setGender = "SET_GENDER";
export const setMaritalStatus = "SET_MARITAL_STATUS";
export const SetFatherName = "SET_FATHER_SPOUSE_NAME";
export const setBirthPlace = "SET_BIRTH_PLACE";
export const getPersonsalDetails1 = "GET_PERSONAL_DETAILS_1";
export const setCurrentAddress1 = "SET_C_ADDRESS_1";
export const setCurrentAddress2 = "SET_C_ADDRESS_2";
export const setCurrentAddress3 = "SET_C_ADDRESS_3";
export const setPerAddress1 = "SET_PER_ADDRESS_1";
export const setPerAddress2 = "SET_PER_ADDRESS_2";
export const setPerAddress3 = "SET_PER_ADDRESS_3";
export const setCorAddressPincode = 'SET_COR_ADDRESS_PINCODE';
export const setPerAddressPincode = "SET_PER_ADDRESS_PINCODE";
export const setCorCity = "SET_COR_CITY";
export const setPerCity = "SET_PER_CITY";
export const setCorState = "SET_COR_STATE";
export const setPerState = "SET_PER_STATE";
export const setIpvStatus = "SET_IPV_STATUS";
export const setIsKraApproved = "SET_IS_KRA_APPROVED";
export const setKRAErrorCode = "SET_KRA_ERROR_CODE";
export const setKraErrorDesc = "SET_KRA_ERROR_DESC";
export const setperAddProof = "setperAddProof";
export const setperAddProofType = 'setperAddProofType';
export const setCorAddProof = "setCorAddProof";
export const setCorAddProofType = "setCorAddProofType";
export const setAppStatus = 'setAppStatus';
export const setAppUpdateStatus = 'setAppUpdateStatus';
export const setUniqueKey = 'setUniqueKey';


export const setAppStatusAction =(appstatus)=>({
  type : setAppStatus,
  payload : {
    appstatus : appstatus
  }
});
export const setAppUpdateStatusAction =(appustatus)=>({
  type : setAppUpdateStatus,
  payload : {
    appustatus : appustatus
  }
});

export const setGenderAction = (gender) => ({
  type: setGender,
  payload: {
    gender: gender,
  },
});
export const setNationalityAction = (nationality) => ({
  type: setNationality,
  payload: {
    isIndian: nationality,
  },
});
export const setMaritalStatusAction = (martialStatus) => ({
  type: setMaritalStatus,
  payload: {
    martialStatus: martialStatus,
  },
});
export const setBirthPlaceAction = (birthplace) => ({
  type: setBirthPlace,
  payload: {
    birthplace: birthplace,
  },
});
export const setFatherSpouseAction = (fName) => ({
  type: SetFatherName,
  payload: {
    fatherOrSpouceName: fName,
  },
});
export const setCurrentAddress1Action = (address) => ({
  type: setCurrentAddress1,
  payload: {
    CRAddr1: address,
  },
});
export const setCurrentAddress2Action = (address) => ({
  type: setCurrentAddress2,
  payload: {
    CRAddr2: address,
  },
});
export const setCurrentAddress3Action = (address) => ({
  type: setCurrentAddress3,
  payload: {
    CRAddr3: address,
  },
});
export const setPerAddress1Action = (address) => ({
  type: setPerAddress1,
  payload: {
    PRAddr1: address,
  },
});
export const setPerAddress2Action = (address) => ({
  type: setPerAddress2,
  payload: {
    PRAddr2: address,
  },
});
export const setPerAddress3Action = (address) => ({
  type: setPerAddress3,
  payload: {
    PRAddr3: address,
  },
});
export const setPerAddressPincodeAction = (pincode) => ({
  type: setPerAddressPincode,
  payload: {
    PRPinCode: pincode,
  },
});
export const setCorAddressPincodeAction = (pincode) => ({
  type: setCorAddressPincode,
  payload: {
    CRPinCode: pincode,
  },
});
export const setCorCityAction = (city) => ({
  type: setCorCity,
  payload: {
    CRCity: city,
  },
});
export const setPerCityAction = (city) => ({
  type: setPerCity,
  payload: {
    PRCity: city,
  },
});
export const setPerStateAction = (state) => ({
  type: setPerState,
  payload: {
    PRState: state,
  },
});
export const setCorStateAction = (state) => ({
  type: setCorState,
  payload: {
    CRState: state,
  },
});
export const setIpvStatusAction = (ipvStatus) => ({
  type: setIpvStatus,
  payload: {
    ipvStatus: ipvStatus,
  },
});
export const setIsKraApprovedAction = (isKraApproved) => ({
  type: setIsKraApproved,
  payload: {
    isKraApproved: isKraApproved,
  },
});
export const gsPersonsalDetails1Action = (payload) => ({
  type: getPersonsalDetails1,
  payload,
});

export const setKRACodeErrorAction = (kraErrorCode) => ({
  type: setKRAErrorCode,
  payload: {
    kraErrorCode: kraErrorCode
  }
});
export const setKRAErrorDescAction = (kraErrorDesc) => ({
  type: setKraErrorDesc,
  payload: {
    kraErrorDesc: kraErrorDesc
  }
});
export const setPerAddressProofRefAction=(payload)=>({
  type : setperAddProofType,
  payload
})

export const setCorAddressProofRefAction=(payload)=>({
  type : setCorAddProofType,
  payload
})

export const setPerAddressProofAction=(payload)=>({
  type : setperAddProof,
  payload
})

export const setCorAddressProofAction=(payload)=>({
  type : setCorAddProof,
  payload
})

export const setUniqueKeyAction=(payload)=>({
  type : setUniqueKey,
  payload
})