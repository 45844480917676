export const startLoader = 'START_LOADER';
export const stopLoader = 'STOP_LOADER';
export const SetGlobalErrorMessage = 'SET_GLOBAL_ERROR_MESSAGE';

export const startLoaderAction = (payload) => ({
	type: startLoader,
	payload
});

export const stopLoaderAction = (payload) => ({
	type: stopLoader,
	payload
});
export const SetGlobalErrorMessageAction = (payload) =>({
	type : SetGlobalErrorMessage,
	payload
})

