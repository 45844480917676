import React from "react";
import { useNavigate } from "react-router-dom";




export default function StepsHeader(props) {
    const navigate = useNavigate();
    const maxCount = 8;
    const stepCount = props.step;

    return (
        <header className="site-header pb-2">
            <div className="site-header-wrap justify-content-evenly">
                    <img src="images/bigul-logo-fav.svg" alt="image" />
                <div>
                    <p>You are on Step {stepCount} of {maxCount}</p>
                    <ul className="progress-list">
                        {Array.from({ length: maxCount }, (_, index) => (
                            <li key={index} className={index + 1 < stepCount ? 'selected' : (index + 1 === stepCount ? 'current' : "")}>
                                <a />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </header>
    );

}