import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { commonPostApi } from '../../../ApiServices/common';
import { useSelector } from 'react-redux';
import { store } from '../../../redux/store';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';

function WelcomeToBigulFamily() {

  useEffect(() => { store.dispatch(stopLoaderAction()); }, [])
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [enableButton, setEnableButton] = useState(false);

  const userLogin = useSelector(state => state?.userLogin || {});
  const [checkboxes, setCheckboxes] = useState({
    automated_trading: false,
    equity_baskets: false,
    chart_analysis: false,
    debt_products: false,
  });

  //resume journey
  const initalStageForWelcomePage = '15';
  //  useResumeJourneyPerStage(initalStageForWelcomePage);
  useCheckRequiredJourneyData(initalStageForWelcomePage);

  const handleCheckboxChange = (event) => {
    setEnableButton(true);
    switch (event?.target?.name) {
      case "automated_trading":
        setCheckboxes((prv) => {
          return {
            ...prv,
            automated_trading: event.target.checked
          }
        });
        break;
      case "equity_baskets":
        setCheckboxes((prv) => {
          return {
            ...prv,
            equity_baskets: event.target.checked
          }
        });
        break;
      case "chart_analysis":
        setCheckboxes((prv) => {
          return {
            ...prv,
            chart_analysis: event.target.checked
          }
        });
        break;
      case "debt_products":
        setCheckboxes((prv) => {
          return {
            ...prv,
            debt_products: event.target.checked
          }
        });
        break;
      default:
    }
  };
  useEffect(() => {
    if (checkboxes?.automated_trading || checkboxes?.equity_baskets || checkboxes?.chart_analysis || checkboxes?.debt_products) {
      setEnableButton(true);
    }
    else{
      setEnableButton(false);
    }
  }, [checkboxes?.automated_trading,checkboxes?.equity_baskets,checkboxes?.chart_analysis, checkboxes?.debt_products])
  
  const onChangeHandlerNext = async (e) => {
    const postData = {
      mobile: userLogin?.mobileInput,
      automated_trading: checkboxes?.automated_trading,
      equity_baskets: checkboxes?.equity_baskets,
      chart_analysis: checkboxes?.chart_analysis,
      debt_products: checkboxes?.debt_products
    }
    try {
      const response = await commonPostApi('/welcome-survey-web', postData);
      if (response?.status == 200) {
        navigate('/thanks-page');
        setSuccessMsg('')
      } else {
        setErrorMsg(response?.msg ? response?.msg : 'something went wrong!!');
      }
    }
    catch (err) {
      setErrorMsg(err);
    }
  }

  return (<>
    <section className="main-wraper kyc-done">
      <header className="site-header">
        <div className="site-header-wrap justify-content-evenly">
          <a>
            <img src="images/bigul-logo-fav.svg" alt="image" />
          </a>
          <p>Yay, you’ve completed all steps!</p>
        </div>
      </header>
      <AsideWallpaper
        color={'blue'}
        descriptionHeader={''}
        backGroundImageUrl={"./images/our-team.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1 mb-2">Welcome to the Bigul Family!</h1>
              <p className="para-1">
                Congratulations, you are one of us now. Help us learn a little more
                about you by choosing your interests below.
              </p>
              <p className="para-1 font-600">I’m most interested about... </p>
              <p className="para-1">Select the cards below </p>
            </div>
            <div className="our-service-box mt-3">
              <div className={`trading-service-item our-service-1 ${checkboxes.automated_trading ? 'active' : ''}`}>
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    name="automated_trading"
                    onChange={handleCheckboxChange}
                    value={checkboxes.automated_trading}
                  />
                  <span className="checkmark" />
                  <img src="./images/automated-trading.svg" alt="image" />
                  <span>Automated Trading</span>
                </label>
              </div>




              <div className={`trading-service-item our-service-2 ${checkboxes.equity_baskets ? 'active' : ''}`}>
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    name="equity_baskets"
                    onChange={handleCheckboxChange}
                    value={checkboxes.equity_baskets}
                  />
                  <span className="checkmark" />
                  <img src="./images/equity-baskets.svg" alt="image" />
                  <span>Equity Baskets</span>
                </label>
              </div>
            </div>
            <div className="our-service-box">
              <div className={`trading-service-item our-service-3 ${checkboxes.chart_analysis ? 'active' : ''}`}>
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    name="chart_analysis"
                    onChange={handleCheckboxChange}
                    value={checkboxes.chart_analysis}
                  />
                  <span className="checkmark" />
                  <img src="./images/chart-analysis.svg" alt="image" />
                  <span>Chart Analysis</span>
                </label>
              </div>
              <div className={`trading-service-item our-service-4 ${checkboxes.debt_products ? 'active' : ''}`}>
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    name="debt_products"
                    onChange={handleCheckboxChange}
                    value={checkboxes.debt_products}
                  />
                  <span className="checkmark" />
                  <img src="./images/dept-products.svg" alt="image" />
                  <span>Debt Products</span>
                </label>
              </div>
            </div>
          </div>
          <div className="btn-action-box">
            <button className={`btn btn-${enableButton ? 'primary' : 'secondary'} btn-full mb-2`} onClick={onChangeHandlerNext} disabled={!enableButton}>
              Let’s begin Trading!
            </button>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default WelcomeToBigulFamily;

