import React from 'react';
import { useNavigate } from "react-router-dom"
import TableComponent from '../../Utils/TableComponent';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useSelector } from 'react-redux';

function ClientAlreadyRegistered() {
  const navigate = useNavigate();
  const userDetails = useSelector(state => state?.userLogin || {});
  const userData = userDetails?.clientUserData;
 
  //resume journey
  return (<>
    <section className="main-wraper">
      <header className="site-header">
        <div className="site-header-wrap justify-content-evenly">
          <a>
          <img src="images/bigul-logo-fav.svg" alt="image" />
          </a>
          <p></p>
        </div>
      </header>
      <AsideWallpaper
        color='blue'
        descriptionHeader=""
        backGroundImageUrl="./images/our-team.svg"
        backGroundImageUrlAltText="some image"
      />
      <div className="body-content-box">
        <div className="login-box pt-120">
          <div className="login-box-wrap">
            <div className="">
              <img
                src="./images/email-verification-sent.svg"
                className="mw-100 mb-4 mt-0"
                alt="img"
              />
              <h1 className="title-1 mb-2 mt-2">
                Wow!Your Super Trading Account is already registered with us with details below
              </h1>
              <TableComponent data={userData} />
              <div>
                <a href='https://bigul.co/sso-login-middleware' className="btn btn-primary btn-full" >Navigate to Login Page </a>
              </div>
              <p className="text-center mb-1">
                <img src="./images/happy-trading.svg" className="mw-100" />
              </p>
              <p className="text-center color-blue mb-0 font-20 font-600">
                Happy Trading!
              </p>
              <p className="text-center font-14">- Team Bigul</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default ClientAlreadyRegistered;