import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { EmailVerifyModalClose, EmailVerifyModalOpen } from '../../../redux/actions/Modal';
import ModalEmailVerificationSuccess from '../../Modals/ModalEmailVerificationSuccess';
import { commonPostApi } from '../../../ApiServices/common';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';
import ResendButton from '../../Utils/ResendButton';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import navigationPerStage from '../../Utils/useNavigationPerStages';
import useNavigationPerStage from '../../Utils/useNavigationPerStages';


function MobileVerification() {
  const dispatch = useDispatch();
  const [otpValue, setOtpValue] = useState('');
  const userLogin = useSelector(state => state?.userLogin || {});
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
       navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
       navigate('/');
    }
  }
  
useEffect(() => {
  if(otpValue?.length < 6){
    setErrorMsg('');
  }
  }, [otpValue])
  const resetOtpHandler = () => {
    setErrorMsg('');
    setOtpValue("");
  }
  const handleVerifyEmail = () => {
    setErrorMsg('');
    try {
      commonPostApi('/verify-email-otp-web',
        {
          "mobile": userLogin?.mobileInput,
          "email": userLogin?.emailInput,
          "otp": otpValue
        },
        null,
        )
        .then((response) => {
          if (response?.status === 200) {
            if (response.msg == 'Success') {
              setErrorMsg('');
              // navigationStage(response?.user_data?.stage);
              dispatch(EmailVerifyModalOpen(true));
              setTimeout(() => {
                dispatch(EmailVerifyModalClose(false));
                navigationStage(response?.user_data?.stage);
              }, 2000);
            }
          }
          else{
           setErrorMsg(response?.msg ?  response?.msg : 'Error While Fetching Email OTP');
           return;
           }
        })
        .catch((error) => {       
          setErrorMsg(error);
        })

    }
    catch (err) {
      store.dispatch(stopLoaderAction());
      setErrorMsg(err);
    }
  }
  return (<>
    <section className="main-wraper">
      <header className="site-header">
        <div className="site-header-wrap">
          <a>
            <img src="images/bigul-logo-fav.svg" alt="image"  />
          </a>
          {/* <p>Email Verification</p> */}
        </div>
      </header>
      <AsideWallpaper
        color={'blue'}
        descriptionHeader={''}
        backGroundImageUrl={"images/email-verify.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap mw-468">
            <div className="">
              <h1 className="title-1 mb-42">Email Verification </h1>
            </div>
            <div className="">
              <p className="para-1">
                Enter the 6 digit OTP sent to <br />
                <strong>{userLogin.emailInput} </strong>
              </p>
              <p className="para-1 pb-3 mb-3">
                <a href="/email-input" className="color-blue">
                  Edit Email
                </a>
              </p>

            </div>
            <div className="input-box enter-otp-box d-flex justify-content-center">
              <OtpInput
                inputType='tel'
                value={otpValue}
                onChange={setOtpValue}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus={true}
                focusStyle={{ }}
              />
            </div>
            <ResendButton
              count={60}
              url={"/resend-otp-web"}
              EmptyOtp={resetOtpHandler}
              payload={{ email: userLogin?.emailInput, type: "email", mobile: userLogin?.mobileInput }}
            />
            {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
            <div className="d-flex pt-4 justify-content-center">
              {/* <p className="text-left w-90px mb-0 font-18-14">00 : {timer} </p> */}
            </div>
          </div>
          <a></a>
          <div className="btn-action-box">
            <button className={`btn btn-${!(otpValue?.length === 6) ? 'secondary' : 'primary'} btn-full mb-2`} onClick={handleVerifyEmail} disabled={!(otpValue?.length === 6)}>
              Verify Email
            </button>

          </div>
        </div>
      </div>
    </section>
    <ModalEmailVerificationSuccess />
  </>)
}
export default MobileVerification;