import React, { useEffect } from 'react';
import { Modal ,Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate } from "react-router-dom";
import {useSelector, useDispatch } from 'react-redux';
import { ModalClose } from '../../redux/actions/Modal';



function ModalMobileNumberAlreadyRegistered() {
  const dispatch = useDispatch();
  const modalStatus = useSelector(  state => state?.modalToggle || {});
  const closeModal =()=>{dispatch(ModalClose(false))}

  useEffect(() => {
    let timer;
    if (modalStatus.isRegistered) {
      timer = setTimeout(() => {
        dispatch(ModalClose(false));
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [modalStatus.isRegistered]);


  return ReactDOM.createPortal(<>
   <Modal show={modalStatus.isRegistered} >
    <div className="" id="Modal-2">
      <div className="modal-dialog mw-445 modal-dialog-centered">
        <div
          className="modal-content p-3"
          data-bs-toggle="modal"
          data-bs-target="#Modal-3"
        >
          <div className="modal-header bb-none">
            <h4 className="modal-title-4">Number already Registered</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal}/>
          </div>
          <div className="modal-body pt-0">
            <img
              src="images/mobile-already-registered.svg"
              className="img-center"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
    </Modal>
  </>,document.getElementById('portal'))
}
export default ModalMobileNumberAlreadyRegistered;