import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BankVerifiedModalClose } from '../../redux/actions/Modal';


function BankAccountVerified() {
  const dispatch = useDispatch();
  const modalStatus = useSelector(state => state?.modalToggle || {});
  const kycStep5 = useSelector(state => state?.ekycStep5);
  const IFSC = kycStep5.ifscCode;
  const bank_name = kycStep5?.bankName;
  const bank_branch = kycStep5?.bankBranchName;
  const bank_account_number = kycStep5?.bankAccountNumber;
  const accountHolderName = kycStep5?.accountHolderName;
  const closeModal = () => { dispatch(BankVerifiedModalClose()) }

  return ReactDOM.createPortal(<>
    <Modal show={modalStatus.bankVerified} >
      <div className="" id="BankAccountVerified">
        <div className="modal-dialog mw-445 modal-dialog-centered">
          <div
            className="modal-content p-3"
            data-bs-toggle="modal"
            data-bs-target="#Modal-6"
          >
            <div className="modal-header bb-none">
              <h4 className="modal-title-4">Bank Account Verified!</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} />
            </div>
            <div className="modal-body pt-0">
              <p className="para-1 text-center mw-250 block-center font-400">
                Hi, {accountHolderName}
              </p>
              <img
                src="./images/bank-account-verified.svg"
                className="img-center mt-3 mb-3"
                alt="image"
              />
              <div className="bank-detail-box">
                <p className="bank-detai-name mb-2">
                  <img src="./images/sbi-logo.svg" alt="sbi" className="mr-4" />
                 {bank_name}
                </p>
                <p className="bank-detail-branch mb-2">Branch : {bank_branch }</p>
                <p className="bank-detail-ifsc mb-2">IFSC : {IFSC } </p>
                <p className="bank-detail-ifsc mb-2">A/c No. : {bank_account_number}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>, document.getElementById('portal'))
}
export default BankAccountVerified;