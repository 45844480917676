import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepsHeader from '../../Utils/StepsHeader';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useNavigate } from 'react-router-dom';



function HelloName() {
  const navigate = useNavigate();
  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const kycStep3 = useSelector(state => state?.ekycStep3 || {});
  const [errorMsg, setErrorMsg] = useState('');
  const userName = kycStep1?.userData?.fullName;
  const isKraApproved = kycStep3?.isKraApproved;
  const onChangeHandlerNext = async () => {
    if(isKraApproved){
      navigate('/personal-details-1');
    }else{
      navigate('/digiLocker-verification');
    }
  };

  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader
        step={2}
      />
      <AsideWallpaper
        color='green'
        descriptionHeader=""
        backGroundImageUrl="images/pan-card-confirm.svg"
        backGroundImageUrlAltText="some image"
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-big">
                Hello,
                <br /> {userName}
              </h1>
            </div>
            <div className="mw-400 block-center">
              <p className="para-1"> Not your name?</p>
              <p className="para-1 mb-0 pb-4 font-400">
                <a href="/pan-verification" className="color-blue">
                  Re-enter PAN Details
                </a>
              </p>
            </div>
          </div>

          {errorMsg && <p className='input-error-msg text-center '>{errorMsg}</p>}

          <div className="btn-action-box">
            <button className="btn btn-primary btn-full mb-2" onClick={onChangeHandlerNext}>
              Confirm it’s you
            </button>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default HelloName;