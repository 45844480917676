import React from 'react';
import { useNavigate } from "react-router-dom"
import ReactDOM from 'react-dom';
import { Modal ,Button } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import { OtpSentModalClose } from '../../redux/actions/Modal';

function ModalMobileNumberVerified() {
  const dispatch = useDispatch();
  const modalStatus = useSelector( state => state?.modalToggle || {});
  const closeModal =()=>{dispatch(OtpSentModalClose(false))}

  return ReactDOM.createPortal(<>
  <Modal show={modalStatus.otpSent} >
    <div className="" id="ModalMobileNumberVerified">
      <div className="modal-dialog mw-445 modal-dialog-centered">
        <div
          className="modal-content p-3"
          data-bs-toggle="modal"
          data-bs-target="#Modal-3"
        >
          <div className="modal-header bb-none">
            <h4 className="modal-title-4">Mobile Number Verified</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal}/>
          </div>
          <div className="modal-body pt-0">
            <img
              src="./images/mobile-number-verified.svg"
              className="img-center"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
    </Modal>
    </>,document.getElementById('portal'))
}
export default ModalMobileNumberVerified;