 import {SetGlobalErrorMessage} from '../actions/app.action';

 const initState = {
    ErrorMessage : ''
 }
 export const ErrorReducer = (state = initState ,action) => {
    switch(action.type)
    {
        case SetGlobalErrorMessage :
           return {...state,
        ErrorMessage : "something went wrong !!"}

        default :
        return state
    }
 }