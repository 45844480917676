import React from 'react';
import { useSelector } from 'react-redux';


const TableComponent = (props) => {
  const userDetails = useSelector(state => state?.userLogin || {});
  const userData = userDetails?.clientUserData;
  const mobile = userDetails?.mobileInput;
  const MaskedMobileNumber = (number) => {
    const maskedNumber = "*".repeat(6) + number.slice(-4);
    return maskedNumber;
  }
  const MaskedPanNumber = (panNumber) => {
    const maskedPan = panNumber.substring(0, 2) + "******" + panNumber.substring(8);
    return maskedPan;
  }

  return (
    <div className="table-container">
      <table className="table table-bordered ">
        <thead>
          <tr className='client-already-registered'>
            <th className='text-white bg-primary font-14 font-500'>Name</th>
            <th className='text-white bg-primary font-14 font-500'>PAN</th>
            <th className='text-white bg-primary font-14 font-500'>Mobile</th>
            <th className='text-white bg-primary font-14 font-500'>Client Code</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((obj, index) => (
            <tr key={index} className='font-14 font-500'>
              <td>{obj.Name}</td>
              <td>{MaskedPanNumber(obj?.Panno.slice(0, 10))}</td>
              <td>{MaskedMobileNumber(obj?.Mobile.slice(0, 10))}</td>
              <td>{obj?.Ldcode}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
