import {
  SetFatherName,
  getPersonsalDetails1,
  setBirthPlace,
  setGender,
  setIpvStatus,
  setIsKraApproved,
  setMaritalStatus,
  setNationality,
  setCurrentAddress1,
  setCurrentAddress2,
  setCurrentAddress3,
  setPerAddress1,
  setPerAddress2,
  setPerAddress3,
  setPerAddressPincode,
  setCorAddressPincode,
  setPerCity,
  setCorCity,
  setPerState,
  setCorState,
  nationality,
  setKRAErrorCode,
  setKraErrorDesc,
  setperAddProofType,
  setperAddProof,
  setCorAddProof,
  setCorAddProofType,
  setAppStatus,
  setAppUpdateStatus,
  setUniqueKey
} from "../../actions/kyc/step3.action";

const initial_state = {
  mobile: "",
  marital: "",
  FamilyType: "",
  father_spouse: "",
  birthplace: "",
  gender: "",
  aadhar: "",
  city: "",
  state: "",
  pincode: "",
  apiType: "",
  CRAddr1: "",
  CRAddr2: "",
  CRAddr3: "",
  CRPinCode: "",
  PRAddr1: "",
  PRAddr2: "",
  PRAddr3: "",
  CRCity: "",
  CRState: "",
  PRCity: "",
  PRState: "",
  PRPinCode: "",
  nationality: true,
  ipvStatus: "",
  isKraApproved: false,
  kraErrorDesc: "",
  kraErrorCode: "",
  perAddressRef : "xxx",
  perAddressProof : "",
  corAddressRef : "",
  corAddressProof : "",
  app_status : "dd",
  app_update_status : "dd"
};

export const kycStep3Reducer = (state = initial_state, action) => {
  switch (action.type) {
    case setNationality:
      const nationality = action.payload.isIndian === "01" ? true : false;
      return {
        ...state,
        nationality: nationality,
      };
    case setGender:
      return {
        ...state,
        gender: action.payload.gender,
      };
    case setMaritalStatus:
      return {
        ...state,
        marital: action.payload.martialStatus,
      };
    case SetFatherName:
      return {
        ...state,
        father_spouse: action.payload.fatherOrSpouceName,
      };
    case setBirthPlace:
      return {
        ...state,
        birthplace: action.payload.birthplace,
      };
    case setCurrentAddress1:
      return {
        ...state,
        CRAddr1: action.payload.CRAddr1,
      };
    case setCurrentAddress2:
      return {
        ...state,
        CRAddr2: action.payload.CRAddr2,
      };
    case setCurrentAddress3:
      return {
        ...state,
        CRAddr3: action.payload.CRAddr3,
      };

    case setPerAddress1:
      return {
        ...state,
        PRAddr1: action.payload.PRAddr1,
      };
    case setPerAddress2:
      return {
        ...state,
        PRAddr2: action.payload.PRAddr2,
      };
    case setPerAddress3:
      return {
        ...state,
        PRAddr3: action.payload.PRAddr3,
      };
    case setPerAddressPincode:
      return {
        ...state,
        PRPinCode: action.payload.PRPinCode,
      };
    case setCorAddressPincode:
      return {
        ...state,
        CRPinCode: action.payload.CRPinCode,
      };
    case setPerCity:
      return {
        ...state,
        PRCity: action.payload.PRCity,
      };
    case setCorCity:
      return {
        ...state,
        CRCity: action.payload.CRCity,
      };
    case setCorState:
      return {
        ...state,
        CRState: action.payload.CRState
      };
    case setPerState:
      return {
        ...state,
        PRState: action.payload.PRState
      }
    case setIpvStatus:
      return {
        ...state,
        ipvStatus: action.payload.ipvStatus,
      };
    case setIsKraApproved:
      return {
        ...state,
        isKraApproved: action.payload.isKraApproved,
      };
    case setKraErrorDesc:
      return {
        ...state,
        kraErrorDesc: action.payload.kraErrorDesc,
      };
    case setKRAErrorCode:
      return {
        ...state,
        kraErrorCode: action.payload.kraErrorCode,
      };
    case getPersonsalDetails1:
      return {
        ...state,
        marital: action.payload.marital,
        gender: action.payload.gender,
        father_spouse: action.payload.father_spouse,
        birthplace: action.payload.birthplace,
      };
      case setCorAddProofType:
         return {
        ...state , 
        corAddressRef: action.payload
      };
      case setperAddProofType:
         return {
        ...state ,
        perAddressRef : action.payload
      };
      case setCorAddProof:
         return {
        ...state ,
        corAddressProof : action.payload
      };
      case setperAddProof:
         return {
        ...state ,
        perAddressProof : action.payload
      };
      case setAppStatus : 
      return{
        ...state,
        app_status : action.payload.appstatus
      };
      case setAppUpdateStatus : 
      return{
        ...state,
        app_update_status : action.payload.appustatus
      };
      case setUniqueKey : 
      return{
        ...state,
        uniqueKey : action.payload
      }
    default:
      return state;
  }
};
