import React, { useState } from 'react'
import { changeStep1PanInputAction } from '../../redux/actions/kyc/step1.action';
import './roadMap.css'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { changeStep1InputAction } from '../../redux/actions/kyc/userlogin.action';
import { dkycApi } from '../../ApiServices/common';

export default function RoadMapLogin({onDataReceived}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
 
  const [errorMsg, setErrorMsg] = useState('');
  const [loginButtonName, setLoginButtonName] = useState("Let's Track!");
  const [mobileInput , setMobileInput] = useState(null);
  // const mobileInput = userLogin.mobileInput || '';
  const pan = kycStep1?.panInput?.value;
  const mobileInputHandler = (e) => {
    setErrorMsg('');
    const value = e.target.value
    const numericValue = value.replace(/\D/g, '').slice(0, 10);
    setMobileInput(numericValue);
  };
  return (<>
    <div className="kyc-journey">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 order-md-last">
            <div className="main-containt">
              <div className="text">
                <a href="#" className="logo">
                  <img src="img/logo.svg" />
                </a>
                <h5>
                  Simplify compliance, amplify <span>confidence.</span>
                </h5>
                <h4>
                  Track your <span>KYC</span> journey hassle-free
                </h4>
                <div className="form-group">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Enter Mobile Number"
                    maxLength={10}
                    onChange={mobileInputHandler}
                    value={mobileInput}
                  />
                  <button className="go-btn" onClick={() => {
                    setErrorMsg('');
                    setLoginButtonName('Please Wait..');
                    const regex = /^(?=[6-9])\d{10}$/;
                    const ValidNo = regex.test(mobileInput);
                    if (ValidNo === false) { setLoginButtonName("Let's Track!"); setErrorMsg('Please enter a valid mobile number'); return; }
                    const payload = {
                      "mobileNumber": mobileInput
                    }
                    dkycApi('/journeyStatus', payload, 'multipart/form-data')
                      .then((response) => {
                        setLoginButtonName("Let's Track!");
                        if (response.data[0]) {
                          onDataReceived(response);
                          navigate('/track-journey-roadmap');
                        } else {
                          setErrorMsg('Sorry,Your Journey Data is not available.')
                        }

                      })
                      .catch(() => {
                        setLoginButtonName("Let's Track!");
                        setErrorMsg('Sorry,Your Journey Data is not available.')
                      })
                  }}>{loginButtonName}</button>
                </div>
                {errorMsg && <div className="input-error-msg">{errorMsg}</div>}
              </div>
            </div>
          </div>
          <div className="col-md-5 left-col">
            <div className="left-part">
              <div className="image">
                <img src="img/begin.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
