import React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { startLoaderAction, stopLoaderAction } from '../../../redux/actions/app.action';
import { setEducationAction, setExperienceAction, setIncomeAction, setIncomeDescription, setIncomeDescriptionAction, setOccupationAction, setOccupationDescriptionAction } from '../../../redux/actions/kyc/step4.action';
import StepsHeader from '../../Utils/StepsHeader';
import { commonPostApi } from '../../../ApiServices/common';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import Dropdown from '../../Utils/Dropdown';
import validator from 'validator';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';



function OccupationDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const kycStep4 = useSelector(state => state?.ekycStep4 || {});
  const [occupationList, setOccupationList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [annualIncomeList, setAnnualIncomeList] = useState([]);
  const [occupation, setOccupation] = useState(kycStep4?.occupation);
  const [occupationDesc, setOccupationDesc] = useState(kycStep4?.occupationDesc);
  const [education, setEducation] = useState(kycStep4?.education);
  const [experience, setExperience] = useState(kycStep4?.experience);
  const [annualIncome, setannualIncome] = useState(kycStep4?.income);
  const [annualIncomeDesc, setAnnulIncomeDesc] = useState(kycStep4?.incomeDesc);
  console.log(kycStep4?.income, kycStep4?.incomeDesc, 'sdfd')
  const [buttonDisable, setButtonDisable] = useState(true);
  const [OthersOccupationName, setOthersOccupationName] = useState('');

  const OthersOccupationHandler = (value) => {
    setOthersOccupationName(value);
  }

  const [occFromKRA, setOccFromKRA] = useState(false);
  const [incomeFromKRA, setIncomeFromKRA] = useState(false);

  const userDetails = useSelector(state => state?.userLogin || {});
  const [errorMsg, setErrorMsg] = useState('');
  const [eduError, setEduError] = useState('');
  const [incError, setIncError] = useState('');
  const [occError, setOccError] = useState('');
  const [expError, setExpError] = useState('');

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }

  //new code on 22.9.23 to close the dropdowns
  const [dropdownStates, setDropdownStates] = useState({
    EDUCATION: false,
    OCCUPATION: false,
    INCOME: false,
    EXPERIENCE: false,
  });

  // Function to toggle the open/close state of a dropdown by its ID
  const toggleDropdown = (dropdownId) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdownId]: !prevState[dropdownId],
    }));
    // Close all other dropdowns when one is opened
    for (const key in dropdownStates) {
      if (key !== dropdownId) {
        setDropdownStates((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    }
  };



  //resume journey
  const initalStageforOccupationDetails = '7';
  useCheckRequiredJourneyData(initalStageforOccupationDetails);
  // const educationId = kycStep4.education;
  // const experienceId = kycStep4.experience;
  // const occ = kycStep4.occupation;
  // const occDesc = kycStep4.occupationDesc;
  // const income = kycStep4.income;
  // const incomeDesc = kycStep4.incomeDesc;


  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoaderAction());
      const apiUrl1 = `${process.env.REACT_APP_BIGUL_BASE_URL}/occupation-list`;
      const apiUrl2 = `${process.env.REACT_APP_BIGUL_BASE_URL}/experience-list-web`;
      const apiUrl3 = `${process.env.REACT_APP_BIGUL_BASE_URL}/education-list`;
      const apiUrl4 = `${process.env.REACT_APP_BIGUL_BASE_URL}/annual-income-list`;
      const [response1, response2, response3, response4] = await axios.all([
        axios.get(apiUrl1),
        axios.get(apiUrl2),
        axios.get(apiUrl3),
        axios.get(apiUrl4),
      ]);

      const data1 = response1.data;
      const data2 = response2.data;
      const data3 = response3.data;
      const data4 = response4.data;

      setOccupationList([...data1.occupationList]);
      setExperienceList([...data2.experienceList]);
      setEducationList([...data3.educationList]);
      setAnnualIncomeList([...data4.annualIncomeList]);

      dispatch(stopLoaderAction())
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (occupation) {
      setOccFromKRA(true)
    }
    if (annualIncome) {
      setIncomeFromKRA(true)
    }
  }, [occupationList, educationList, experienceList, annualIncomeList])

  useEffect(() => {
    if (occupation && education && annualIncome && experience && (occupation == "11" ? OthersOccupationName.trim() !== '' : true)  ) { setButtonDisable(false); }
    else{
      setButtonDisable(true);
    }
  }, [occupation, education, annualIncome, experience,OthersOccupationName])

  const onChangeHandlerNext = async () => {
    setErrorMsg('');

    const data = {
      "mobile": userDetails?.mobileInput,
      "occupation": occupation,
      "occupation_name": occupation == "11" ? OthersOccupationName : occupationDesc,
      "annualincome": annualIncome,
      "annualincome_name": annualIncomeDesc,
      "educationId": education,
      "experience": experience
    }

    if (true) {
      await commonPostApi('/save-occupation-details-web', data, null)
        .then((response) => {
          if (response.status === 200) {
            navigationStage(response?.user_data?.stage);
            // navigate('/find-bank-details');
          }
          else {
            setErrorMsg(data.msg ? data?.msg : 'Something went wrong!!')
          }
        })
        .catch((error) => {
          setErrorMsg(error);
        })
    }
  }

  function saveUserInput(value, dropdownId) {
    if (value == null || value == undefined || value == "") {
      return;
    }
    switch (dropdownId) {
      case "EDUCATION":
        setEducation(value);
        break;
      case "OCCUPATION":
        setOccupation(value);
        setOccupationDesc(getDescriptionWithId(occupationList, value));
        // dispatch(setOccupationDescriptionAction(getDescriptionWithId(occupationList, value)));
        break;
      case "INCOME":
        setIncError('');
        // dispatch(setIncomeAction(value));
        setannualIncome(value);
        setAnnulIncomeDesc(getDescriptionWithId(annualIncomeList, value));
        // dispatch(setIncomeDescriptionAction(getDescriptionWithId(annualIncomeList, value)));
        break;
      case "EXPERIENCE":
        // dispatch(setExperienceAction(value));
        setExperience(value);
        break;
      default:
        break;
    }
  }


  function getDescriptionWithId(arrayList, id) {
    for (let item of arrayList) {
      if (item.id === id) return item.name
    }
    return ""
  }

  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader step={4} />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/personal-details.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1">Fill your Details </h1>
            </div>
            <div className="mw-400 block-center mb-md-5 mb-h740-3 mb-4">
              <p className="para-1">Personal Details - 2/2</p>
            </div>
            <div className="input-box">
              <Dropdown
                optionsArray={educationList}
                dropdownName="Your Education"
                onSelect={saveUserInput}
                dropdownId="EDUCATION"
                error_message={eduError}
                value={education}
                disabled={false}
                isOpen={dropdownStates.EDUCATION}
                toggleDropdown={toggleDropdown}

              />
              <Dropdown
                optionsArray={occupationList}
                dropdownName={kycStep4.occupationDesc || "Occupation"}
                onSelect={saveUserInput}
                dropdownId="OCCUPATION"
                error_message={occError}
                value={occupation}
                // disabled={occFromKRA}
                isOpen={dropdownStates.OCCUPATION}
                toggleDropdown={toggleDropdown}
              />

              {
                occupation == "11" &&
                <input
                  type="text"
                  // maxLength={10}
                  className="input-form text-center mb-3 input-pan-card"
                  placeholder="Enter Your Occupation"
                  onChange={(e) => { OthersOccupationHandler(e.target.value) }}
                  value={OthersOccupationName}
                />
              }

              <Dropdown
                optionsArray={annualIncomeList}
                dropdownName={kycStep4.incomeDesc || "Annual Income"}
                onSelect={saveUserInput}
                dropdownId="INCOME"
                error_message={incError}
                value={annualIncome}
                // disabled={incomeFromKRA}
                isOpen={dropdownStates.INCOME}
                toggleDropdown={toggleDropdown}
              />
              <Dropdown
                optionsArray={experienceList}
                dropdownName="Trading Experience"
                onSelect={saveUserInput}
                dropdownId="EXPERIENCE"
                error_message={expError}
                value={experience}
                disabled={false}
                isOpen={dropdownStates.EXPERIENCE}
                toggleDropdown={toggleDropdown}

              />
            </div>
            {(Number(annualIncome) > 3) && <div className='input-error-msg text-center' >Proof of income is required as your annual income exceeds Rs 10 Lakhs.</div>}
          </div>
          {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          <div className="btn-action-box">
            <button className={`btn btn-${buttonDisable ? 'secondary' : 'primary'} btn-full mb-2`} onClick={onChangeHandlerNext} disabled={buttonDisable}>
              Next, Bank Details
            </button>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default OccupationDetails;